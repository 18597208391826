<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h2 class="page-title">OpenBanking Terms and Conditions</h2>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <h4>1. Introduction</h4>
          <p>1.1. The registration, access and use to/of the Papaya Ltd , (“Financial Institution”) OpenBanking portal (“Developer Portal”) website at developer portal, including the pertinent tools and applications, as made available by the Bank from time to time in its discretion Developer Portal is subject to the following Terms and Conditions (the “Terms and Conditions”).</p>
          <p>1.2. “Financial Institution” in the context of these Terms and Conditions means Papaya Ltd legal address:  31 Sliema Road, Gzira GZR 1637, registration No C55146.</p>
          <p>1.3. The Financial Institution may at any time alter these Terms and Conditions without prior notice by publishing new or amended Terms and Conditions on the Developer Portal.</p>

          <h4>2. Your Registration to the Developer Portal</h4>
          <p>The Developer Portal is a discretionary offer by the Financial Institution. You must register through the website at portal address <a routerLink="/ttp">https://papaya.dev.scnetservices.ru/ttp</a></p>
          <p>2.2. The Financial Institution reserves the right to approve registration demands and may reject your application in its discretion without the need to provide any reasons. You agree that you will disclose to the Financial Institution any actual or potential conflicts of interest with respect to your registration (e.g., if you are already working for, or are affiliated with, a Financial Institution‘s competitor or other financial institution).</p>
          <p>2.3. Any information that you provide in connection with your registration must be correct and complete, and you are responsible for maintaining such information up-to-date and accurate throughout the term of your registration.</p>
          <p>2.4. Upon successful registration, you may be provided with access credential, passwords and other account information (the “Credentials”). The Credentials are, and will remain, the property of the Bank and you are granted a nontransferable license to use the Credentials for the sole purpose of participating in the Developer Portal.</p>
          <p>2.5. You must keep the Credentials confidential and may not sell, transfer, sublicense, or otherwise disclose your Credentials to any other person or for any other purpose.</p>
          <p>2.6. If you become aware or if you suspect that the confidentiality of your Credentials have been compromised, you are obliged to inform the Bank without undue delay. You must not attempt to circumvent or modify any Credentials or other security mechanism used by the Bank in connection with the Developer Portal.</p>

          <h4>3. Rules governing your use of the Developer Portal</h4>
          <p>3.1. You are responsible for all activities that occur under usage of your Credentials, regardless whether undertaken by you or someone else. You are obliged to comply with all legal requirements and refrain from creating content that is unlawful or otherwise objectionable, or any content that may be construed as such.</p>
          <p>3.2. You are prohibited from, and you will not engage, in any of the following activities:</p>
          <ul class="list-unstyled">
            <li>(a) reverse engineer, disassemble, reconstruct, or decompile any object code of the Developer Platform, any the Financial Institution test data, the Financial Institution’s API or any other data provided by the Financial Institution in connection with the Developer Portal (the “DP Content”);</li>
            <li>(b) attempt unauthorised access to, or use of, Financial Institution’s services or systems;</li>
            <li>(c) damage, disrupt, or otherwise negatively affect the operation of Financial Institution’s services or systems;</li>
            <li>(d) engage in any activity that prevents any other developer from using the DP Content;</li>
            <li>(e) use any robot, spider, site search or other application to retrieve or index DP Content,</li>
            <li>(f) collect information about other developers and</li>
            <li>(g) introduce any viruses or other malicious code.</li>
          </ul>

          <h4>4. Interaction with you governing your use of the Developer Portal</h4>
          <p>4.1. The Developer Portal pursues the goal of interacting with you to validate the reasons for certain access procedures and to achieve a steady improvement within the scope of the simulation for the programming or API development.</p>
          <p>4.2. We reserve the right to contact you on the basis of your stated data.</p>

          <h4>5. Intellectual Property Rights</h4>
          <p>5.1. The Developer Portal is protected by applicable intellectual property laws.</p>
          <p>5.2. The Financial Institution grants you, upon successful registration, a limited, non-exclusive, non-transferable, revocable and non-sublicensable right to use the Developer Portal for the term of your registration to the extent necessary and for the sole purpose of participating in the Developer Portal in accordance with, and within the scope of, these Terms and Conditions.</p>
          <p>5.3. You grant to the Financial Institution a limited and non-exclusive right to use the object code and other content that you upload, or that you program, on the Developer Portal to the extent necessary and for the sole purpose for the Financial Institution to operate the Developer Portal.</p>
          <p>5.4. You warrant that you have the intellectual property rights in any content uploaded to, or programmed on, the Developer Portal by you and that such content does not violate or infringe the intellectual property rights of any third party. You remain solely responsible for any such content, and the Financial Institution disclaims any and all liability for such content.</p>
          <p>5.5. You shall indemnify and hold harmless the Financial Institution from any costs or damages suffered by the Financial Institution as a result of a third party bringing or threatening any claim or action against the Financial Institution on the ground that such content or any other material provided or used by you in connection with the Developer Portal infringes the intellectual property rights or other rights of any third party.</p>
          <p>5.6. If the Financial Institution is interested in the content that you have created on the Developer Portal, the Financial Institution may contact you in its discretion and the Financial Institution may suggest additional provisions for further evaluation of your content.</p>

          <h4>6. Information; Confidentiality</h4>
          <p>6.1. The information provided on the Developer Portal or in connection therewith shall not constitute any investment, legal or tax advice. Any opinions are subject to change without notice. Opinions expressed may differ from estimates represented in other documents published by the Financial Institution. Comments are provided for informational purposes only and are given without any obligation, contractual or otherwise.</p>
          <p>6.2. No guarantee is offered as to the accuracy, completeness or appropriateness of the information or estimates relating to the Developer Portal or in connection therewith.</p>
          <p>6.3. If and insofar you gain knowledge of or receive<br>
            confidential information in connection with the Developer Portal, you must treat such information with strict confidentiality and you may not disclose the same to third parties. “Confidential Information” here refers to all Financial Institution’s information labelled confidential or which would be treated as confidential by any reasonable third party.</p>
          <p>6.4. You agree to protect such information from unauthorised access and to treat it with the same care with which you would treat your own confidential commercial information. This duty of confidentiality shall continue to apply for five (5) years as from the moment your registration to the Developer Portal ceases.</p>

          <h4>7. Termination of your Registration</h4>
          <p>7.1. The Financial Institution may terminate your registration and access to the Developer Portal and the DB Content at any time without prior notice.</p>
          <p>7.2. Your registration will automatically terminate if you breach any of these Terms and Conditions.</p>
          <p>7.3. Upon termination, any rights, which are granted to you under these Terms and Conditions, will automatically cease.</p>

          <h4>8. No Warranties; Limitation of Liability</h4>
          <p>8.1. The Developer Portal and the DB Content are provided “as is”, and use of the Developer Portal is entirely on your own risk.</p>
          <p>8.2. The Financial Institution disclaims all warranties and does in particular not provide any warranty in respect to the Developer Portal as being error free, with respect to its availability or accuracy.</p>
          <p>8.3. The Financial Institution shall only be liable to you for intent and gross negligence. Liability for ordinary negligence is excluded. This shall not apply to any injury to life, limb or health or if such exclusion would contravene applicable law.</p>
          <p>8.4. The Financial Institution is not liable for damage caused by third parties. In the case your registration is terminated, the Financial Institution shall not be held liable for any damages incurred by the Participant.</p>

          <h4>9. Miscellaneous</h4>
          <p>9.1. Should any provision of these Terms and Conditions be or become invalid, the validity of the Terms and Conditions as a whole shall remain unaffected.</p>
          <p>9.2. The invalid provision must be replaced by a valid provision within the context of the remaining contractual interpretation, which corresponds to the economic intent and purpose of these rules as a whole. These Terms and Conditions are subject to Maltese law.</p>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
