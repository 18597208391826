import { Component, OnInit } from '@angular/core';
import { CurrencyExchangesService } from '../services/currencyExchanges/currencyExchanges.service';
import { flatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface Currency {
  baseCurCode: string;
  curCode: string;
  instId: string;
  rate: number;
  refCode: string;
  validFrom: string;
  validTo: string;
}

@Component({
  selector: 'app-currency-rates',
  templateUrl: './currency-rates.component.html',
  styleUrls: ['./currency-rates.component.scss']
})
export class CurrencyRatesComponent implements OnInit {

  ecb: Currency[];
  mcc: Currency[];
  pap: Currency[];
  rates = [];
  constructor(
    private currencyExchangeService: CurrencyExchangesService,
  ) {}

  ngOnInit() {
    this.getCurrencies();
  }

  getCurrencies() {
    this.currencyExchangeService.post('ECB').pipe(
      flatMap((currencyECB: Currency[]): Observable<any> => {
          this.ecb = currencyECB;
          return this.currencyExchangeService.post('MCC');
      }),
      flatMap((currencyMCC: Currency[]): Observable<any> => {
        this.mcc = currencyMCC;
        return this.currencyExchangeService.post('PAP');
      }),
    ).subscribe((currencyPAP) => {
      this.pap = currencyPAP;
      this.getRates();
    });
  }

  getRates() {
    this.ecb.forEach((val: any) => {
      this.rates.push({
        code: val.curCode,
        ecb: this.ecb.find(res => res.curCode === val.curCode)?.rate || '-',
        mcc: this.mcc.find(res => res.curCode === val.curCode)?.rate || '-',
        pap: this.pap.find(res => res.curCode === val.curCode)?.rate || '-',
      });
    });
  }
}
