import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistic-q12023',
  templateUrl: './statistic-q12023.component.html',
  styleUrls: ['./statistic-q12023.component.scss']
})
export class StatisticQ12023Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
