<app-header></app-header>
<main class="page-content">
  <section class="section faq">
    <div class="container">
      <div class="section__header">
        <h1 class="page-title">Popular questions</h1>
        <p class="text-large">If you did not find the answer to your question, please <a routerLink="/contacts">contact us</a></p>
      </div>
      <div class="faq__list">
        <div class="faq__list-column">
          <h4 class="faq__list-section-title">Are common</h4>
          <div class="faq-panel">
            <div class="faq-panel__title">
              Exchange Trading 4 lesson
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
          <div class="faq-panel">
            <div class="faq-panel__title">
              Is it safe to use?
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
          <div class="faq-panel">
            <div class="faq-panel__title">
              What is the minimum cryptocurrency deposit/withdraw?
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
        </div>
        <div class="faq__list-column">
          <h4 class="faq__list-section-title">Bargaining</h4>
          <div class="faq-panel">
            <div class="faq-panel__title">
              How to start trading on StockPoint?
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
          <div class="faq-panel">
            <div class="faq-panel__title">
              What is a Simple buy/sell order?
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
          <div class="faq-panel">
            <div class="faq-panel__title">
              What is a buy/sell Order on the market?
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
          <div class="faq-panel">
            <div class="faq-panel__title">
              Where does the money I lost trading on your stock goes?
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
        </div>
        <div class="faq__list-column">
          <h4 class="faq__list-section-title">Settings</h4>
          <div class="faq-panel">
            <div class="faq-panel__title">
              How to register?
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
          <div class="faq-panel">
            <div class="faq-panel__title">
              I can't sign in to my account
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
          <div class="faq-panel">
            <div class="faq-panel__title">
              The security of your account.
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
          <div class="faq-panel">
            <div class="faq-panel__title">
              List of white IP addresses.
            </div>
            <div class="faq-panel__content">
              <p>A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
