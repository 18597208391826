<header class="header" id="header">
  <div class="header__container container">
    <div class="header__row">
      <div class="header__logo">
        <a href="#"><img class="header__logo-icon" src="assets/img/logo_2.svg" alt="#"></a>
      </div>
      <div class="header__center">
        <nav class="main-nav">
          <div class="main-nav__wrapper">
            <ul class="main-nav__list">
              <li class="main-nav__item">
                <a  appDebounceClick [ngClass]="{'is-active': currentActive === 1}" (debounceClick)="closeMenu(['partners', 'titles'])" [debounceTime]="700" class="main-nav__link" data-to-section="#partners">Partners</a>
              </li>
              <li class="main-nav__item">
                <a  appDebounceClick [ngClass]="{'is-active': currentActive === 2}" (debounceClick)="closeMenu(['services', 'titles'])" [debounceTime]="700" class="main-nav__link" data-to-section="#services">Solutions</a>
              </li>
              <li class="main-nav__item">
                <a routerLink="/about" [routerLinkActive]="'is-active'" (click)='closeMenu()' class="main-nav__link">About Us</a>
              </li>
              <li class="main-nav__item">
                <a routerLink="/contacts" [routerLinkActive]="'is-active'" (click)='closeMenu()' class="main-nav__link">Contacts</a>
              </li>
              <li class="main-nav__item">
                <a routerLink="/careers" [routerLinkActive]="'is-active'" (click)='closeMenu()' class="main-nav__link">Careers</a>
              </li>
              <li class="main-nav__item">
                <a routerLink="/ttp" [routerLinkActive]="'is-active'" (click)='closeMenu()' class="main-nav__link">Register for online banking</a>
              </li>
            </ul>
          </div>
        </nav>
        <a (click)="mobileMenu()" class="nav-toggle" role="button">
          <span class="nav-toggle__bar"></span>
          <span class="nav-toggle__bar"></span>
          <span class="nav-toggle__bar"></span>
        </a>
      </div>
      <div class="header__right">
        <div class="form-lang form-lang_sm form-lang_sm-left">
          <div class="form-lang__prepend">
            <img class="form-lang__prepend-icon" src="assets/img/lang/en.png" alt="EN">
          </div>
          <input type="text" class="form-lang__input" value="English" autocomplete="off" readonly>
          <div class="form-lang__arrow">
            <i class="material-icons">keyboard_arrow_down</i>
          </div>
          <div class="form-lang__dropdown">
            <div class="form-lang__dropdown-container">
              <a class="form-lang__dropdown-item" href="#" data-value='EN' data-icon="">
                <div class="form-lang__dropdown-prepend">
                  <img class="form-lang__dropdown-icon" src="assets/img/lang/en.png" alt="EN">
                </div>
                <span class="form-lang__dropdown-text">English</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
