<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">Fraud Disclaimer
        </h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <h4>Fraud Disclaimer</h4>
          <p>
            Papaya Ltd. wishes to draw the attention of our customers and members of the general public to the prevalence of fraudulent activity being perpetuated by fraudsters who aim to deceive and defraud our customers and the general public.
          </p>
          <p>It has come to our attention that the name of Papaya Ltd, our trading name Papaya, its logo and address and the names of some of the people who work for us are being used in attempts to defraud the public through false correspondence and other scams. This type of fraud may be via email, letters, text messages or by using a notice board or even a website purporting to be that of Papaya Ltd. or Papaya. Examples of fake documents and sites have occurred in the Malta, Italy and elsewhere across the world.</p>
          <p>Recipients of such communications are advised never to make any payments of monies. Should this have occurred, however, you should alert your own bank at the earliest opportunity.</p>
          <p>We will not accept any liability for any sort of loss or damage caused due to entities asking for favours or payments for any kind of services which is not related to Papaya Ltd.</p>
          <p><strong class="text-uppercase">DISCLAIMER</strong>: Papaya Ltd shall not be responsible for the communication of any fraudulent information relating to unauthorised activity, nor shall we be liable for any loss or damage incurred by any member of the public as a result of dealing with any entity which illegally solicits payments for fake real estate services or other services which are not related to Papaya Ltd main activity of Issuing Electronic Money or Prepaid payment products.</p>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
