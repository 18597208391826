<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
            <tr>
                <th>Date</th>
                <th>Channel</th>
                <th>Uptime, <br /> %</th>
                <th>Downtime, <br /> %</th>
                <th>AIS <br /> performance, <br /> ms</th>
                <th>PIS <br /> performance, <br /> ms</th>
                <th>CoF <br /> performance, <br /> ms</th>
                <th>Error response <br /> rate, <br /> %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 01</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>81</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>179</td>
                <td>430</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>677</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 02</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>73</td>
                <td>68</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>633</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 03</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>75</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>453</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>642</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 04</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>67</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>171</td>
                <td>454</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>465</td>
                <td>210</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 05</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>68</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>132</td>
                <td>340</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>481</td>
                <td>210</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 06</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>74</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>139</td>
                <td>387</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>700</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 07</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>81</td>
                <td>72</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>174</td>
                <td>535</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>664</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 08</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>79</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>456</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>702</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 09</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>80</td>
                <td>61</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.88%</td>
                <td>0.12%</td>
                <td>174</td>
                <td>470</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>664</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 10</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>64</td>
                <td>87</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>182</td>
                <td>561</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>927</td>
                <td>290</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 11</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>71</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>353</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>489</td>
                <td>210</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 12</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>49</td>
                <td>73</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>157</td>
                <td>361</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>500</td>
                <td>210</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 13</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>63</td>
                <td>86</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>195</td>
                <td>580</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>96.59%</td>
                <td>3.41%</td>
                <td>912</td>
                <td>290</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 14</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>84</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>180</td>
                <td>457</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>758</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 15</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>99.94%</td>
                <td>0.06%</td>
                <td>58</td>
                <td>81</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>530</td>
                <td>830</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>1000</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 16</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>78</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>176</td>
                <td>448</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>731</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 17</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>77</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>431</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>731</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 18</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>91.17%</td>
                <td>8.83%</td>
                <td>50</td>
                <td>82</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>83.67%</td>
                <td>16.33%</td>
                <td>142</td>
                <td>341</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>83.67%</td>
                <td>16.33%</td>
                <td>484</td>
                <td>200</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 19</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>98.09%</td>
                <td>1.91%</td>
                <td>48</td>
                <td>79</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>94.61%</td>
                <td>5.39%</td>
                <td>149</td>
                <td>349</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>484</td>
                <td>200</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 20</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>91</td>
                <td></td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>200</td>
                <td>546</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>886</td>
                <td>290</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 21</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>87</td>
                <td></td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>434</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>742</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 22</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>84</td>
                <td></td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>177</td>
                <td>462</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>682</td>
                <td>250</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 23</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>86</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>93.93%</td>
                <td>6.07%</td>
                <td>163</td>
                <td>435</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>711</td>
                <td>250</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 24</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>92</td>
                <td></td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>175</td>
                <td>454</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.91%</td>
                <td>0.09%</td>
                <td>861</td>
                <td>300</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 25</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>80</td>
                <td></td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>139</td>
                <td>344</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>501</td>
                <td>200</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 26</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>49</td>
                <td>82</td>
                <td></td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>358</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>523</td>
                <td>210</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 27</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>74</td>
                <td>76</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>168</td>
                <td>461</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>822</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 28</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>82</td>
                <td>71</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>159</td>
                <td>419</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>708</td>
                <td>250</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 29</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>82</td>
                <td>59</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>160</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>628</td>
                <td>250</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 30</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>82</td>
                <td>56</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>626</td>
                <td>250</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 01 31</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>84</td>
                <td></td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>169</td>
                <td>410</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>665</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 01</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>81</td>
                <td></td>
                <td>0.036%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>137</td>
                <td>324</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>547</td>
                <td>283</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 02</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>63</td>
                <td>82</td>
                <td></td>
                <td>0.020%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.21%</td>
                <td>0.79%</td>
                <td>154</td>
                <td>358</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>553</td>
                <td>288</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 03</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>80</td>
                <td>87</td>
                <td>49</td>
                <td>0.014%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>180</td>
                <td>506</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>838</td>
                <td>390</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 04</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>74</td>
                <td>82</td>
                <td>56</td>
                <td>0.057%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>169</td>
                <td>436</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>753</td>
                <td>361</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 05</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>78</td>
                <td>78</td>
                <td></td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>174</td>
                <td>452</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>727</td>
                <td>379</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 06</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>79</td>
                <td>82</td>
                <td></td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>165</td>
                <td>436</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>717</td>
                <td>361</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 07</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>81</td>
                <td>79</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>170</td>
                <td>449</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>702</td>
                <td>366</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 08</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>91.67%</td>
                <td>8.33%</td>
                <td>71</td>
                <td>74</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>91.67%</td>
                <td>8.33%</td>
                <td>131</td>
                <td>307</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>91.67%</td>
                <td>8.33%</td>
                <td>496</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 09</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>65</td>
                <td>67</td>
                <td></td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>342</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>536</td>
                <td>295</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 10</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>99.86%</td>
                <td>0.14%</td>
                <td>90</td>
                <td>79</td>
                <td></td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>311</td>
                <td>635</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>869</td>
                <td>446</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 11</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>75</td>
                <td>63</td>
                <td>95</td>
                <td>0.060%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>419</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>746</td>
                <td>360</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 12</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>80</td>
                <td>74</td>
                <td>52</td>
                <td>0.019%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>151</td>
                <td>417</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>764</td>
                <td>362</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 13</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>67</td>
                <td>67</td>
                <td>66</td>
                <td>0.028%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>671</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>704</td>
                <td>334</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 14</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>66</td>
                <td>73</td>
                <td></td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>389</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>675</td>
                <td>333</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 15</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>55</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>389</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>492</td>
                <td>267</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 16</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>56</td>
                <td></td>
                <td>0.027%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>315</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>514</td>
                <td>277</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 17</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>69</td>
                <td>67</td>
                <td>48</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>389</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>767</td>
                <td>356</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 18</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>66</td>
                <td>70</td>
                <td>46</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>394</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>711</td>
                <td>325</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 19</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>65</td>
                <td>68</td>
                <td>47</td>
                <td>0.021%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>397</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>747</td>
                <td>345</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 20</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>99.58%</td>
                <td>0.42%</td>
                <td>62</td>
                <td>64</td>
                <td>50</td>
                <td>0.015%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>380</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>572</td>
                <td>285</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 21</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>65</td>
                <td>69</td>
                <td>52</td>
                <td>0.063%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>391</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>714</td>
                <td>341</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 22</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>57</td>
                <td>45</td>
                <td>0.017%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>124</td>
                <td>311</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>520</td>
                <td>272</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 23</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>56</td>
                <td>47</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>522</td>
                <td>278</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 24</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>70</td>
                <td>69</td>
                <td>52</td>
                <td>0.013%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>475</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>782</td>
                <td>359</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 25</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>64</td>
                <td>68</td>
                <td>53</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>150</td>
                <td>404</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>722</td>
                <td>336</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 26</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>63</td>
                <td>71</td>
                <td>51</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>395</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>736</td>
                <td>343</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 27</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>64</td>
                <td>71</td>
                <td>48</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>153</td>
                <td>465</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>733</td>
                <td>334</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 02 28</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>63</td>
                <td>68</td>
                <td>48</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>150</td>
                <td>447</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>720</td>
                <td>335</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 01</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>79</td>
                <td>56</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>602</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>732</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 02</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>76</td>
                <td>55</td>
                <td>0.010%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>501</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>703</td>
                <td>422</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 03</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>68</td>
                <td>68</td>
                <td>55</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>498</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1164</td>
                <td>465</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 04</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>74</td>
                <td>51</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>135</td>
                <td>424</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.94%</td>
                <td>0.06%</td>
                <td>659</td>
                <td>431</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 05</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>59</td>
                <td>52</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>167</td>
                <td>467</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>370</td>
                <td>293</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 06</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>49</td>
                <td>62</td>
                <td>59</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>176</td>
                <td>412</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>382</td>
                <td>300</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 07</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>60</td>
                <td>92</td>
                <td>69</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>185</td>
                <td>1126</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>741</td>
                <td>474</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 08</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>75</td>
                <td>59</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>193</td>
                <td>2886</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.06%</td>
                <td>0.94%</td>
                <td>675</td>
                <td>440</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 09</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>72</td>
                <td>55</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>180</td>
                <td>602</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>666</td>
                <td>434</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 10</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>78</td>
                <td>59</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>204</td>
                <td>505</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.75%</td>
                <td>0.25%</td>
                <td>698</td>
                <td>468</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 11</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>78</td>
                <td>57</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>179</td>
                <td>485</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>679</td>
                <td>448</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 12</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>72</td>
                <td>50</td>
                <td>0.025%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>126</td>
                <td>317</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>362</td>
                <td>298</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 13</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>46</td>
                <td>68</td>
                <td>47</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>192</td>
                <td>377</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>369</td>
                <td>290</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 14</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>57</td>
                <td>60</td>
                <td>55</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>181</td>
                <td>528</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>751</td>
                <td>467</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 15</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>85</td>
                <td>104</td>
                <td>0.014%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>164</td>
                <td>490</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>701</td>
                <td>452</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 16</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>69</td>
                <td>54</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>168</td>
                <td>424</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>656</td>
                <td>416</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 17</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>74</td>
                <td>50</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>454</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>670</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 18</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>82</td>
                <td>57</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>120</td>
                <td>430</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>650</td>
                <td>421</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 19</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>84.69%</td>
                <td>15.31%</td>
                <td>181</td>
                <td>238</td>
                <td>5441</td>
                <td>0.120%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>83.34%</td>
                <td>16.66%</td>
                <td>155</td>
                <td>285</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>83.34%</td>
                <td>16.66%</td>
                <td>348</td>
                <td>286</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 20</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>95.25%</td>
                <td>4.75%</td>
                <td>67</td>
                <td>86</td>
                <td>51</td>
                <td>0.036%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>184</td>
                <td>353</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>393</td>
                <td>330</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 21</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>63</td>
                <td>61</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>496</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>687</td>
                <td>444</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 22</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>84</td>
                <td>53</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>159</td>
                <td>430</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>642</td>
                <td>413</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 23</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>87</td>
                <td>53</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>157</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>650</td>
                <td>410</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 24</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>82</td>
                <td>55</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>164</td>
                <td>409</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>644</td>
                <td>412</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 25</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>91</td>
                <td>55</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>427</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>643</td>
                <td>430</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 26</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>90</td>
                <td>57</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>282</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>346</td>
                <td>287</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 27</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>88</td>
                <td>53</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>179</td>
                <td>322</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>367</td>
                <td>302</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 28</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>95</td>
                <td>56</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>495</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.92%</td>
                <td>0.08%</td>
                <td>715</td>
                <td>459</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 29</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>96</td>
                <td>55</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>97.70%</td>
                <td>2.30%</td>
                <td>175</td>
                <td>424</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>660</td>
                <td>428</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 30</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>76</td>
                <td>60</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>183</td>
                <td>462</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>710</td>
                <td>451</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 03 31</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>68</td>
                <td>67</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>500</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>579</td>
                <td>314</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

