<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">Security</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <p>The communication between the TPP and the Financial Institution is always secured by using SSL 3.0 / TLS version 1.2 or higher.</p>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
