<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">Availability</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <p>Papaya’s Sandbox is available 24/7/365 to test bank services. If you have some problems, please contact Papaya’s Support at <a href="mailto:IT@papaya.eu">IT@papaya.eu</a> .</p>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
