<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
            <tr>
                <th>Date</th>
                <th>Channel</th>
                <th>Uptime, <br /> %</th>
                <th>Downtime, <br /> %</th>
                <th>AIS <br /> performance, <br /> ms</th>
                <th>PIS <br /> performance, <br /> ms</th>
                <th>CoF <br /> performance, <br /> ms</th>
                <th>Error response <br /> rate, <br /> %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 01</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>76</td>
                <td>88</td>
                <td></td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>176</td>
                <td>509</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>688</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 02</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>72</td>
                <td>86</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>453</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>614</td>
                <td>240</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 03</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>75</td>
                <td>89</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>461</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>592</td>
                <td>240</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 04</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>82</td>
                <td>102</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>476</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>629</td>
                <td>250</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 05</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>78</td>
                <td>97</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>173</td>
                <td>520</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>643</td>
                <td>250</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 06</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>66</td>
                <td>82</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>124</td>
                <td>347</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>478</td>
                <td>200</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 07</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>66</td>
                <td>85</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>364</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>512</td>
                <td>210</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 08</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>80</td>
                <td>98</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>183</td>
                <td>508</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>718</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 09</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>75</td>
                <td>100</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>179</td>
                <td>541</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>649</td>
                <td>250</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 10</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>86</td>
                <td>120</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>598</td>
                <td>790</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>775</td>
                <td>290</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 11</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>70</td>
                <td>107</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>185</td>
                <td>470</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>663</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 12</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>100</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>153</td>
                <td>458</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>674</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 13</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>117</td>
                <td></td>
                <td>0.027%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>79.17%</td>
                <td>20.83%</td>
                <td>147</td>
                <td>376</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>80.65%</td>
                <td>19.35%</td>
                <td>479</td>
                <td>210</td>
                <td>N/A</td>
                <td>N/A</td>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 14</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>65</td>
                <td>138</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>173</td>
                <td>407</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>509</td>
                <td>220</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 15</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>71</td>
                <td>117</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>256</td>
                <td>690</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>853</td>
                <td>320</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 16</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>84</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>184</td>
                <td>477</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>647</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 17</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>86</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>163</td>
                <td>425</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>642</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 18</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>89</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>174</td>
                <td>441</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>649</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 19</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>89</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>452</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.86%</td>
                <td>0.14%</td>
                <td>717</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 20</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>86</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>343</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>480</td>
                <td>200</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 21</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>51</td>
                <td>82</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>332</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>506</td>
                <td>220</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 22</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>177</td>
                <td>209</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>943</td>
                <td>1113</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>586</td>
                <td>240</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 23</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>83</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>139</td>
                <td>370</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>469</td>
                <td>200</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 24</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>46</td>
                <td>78</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>127</td>
                <td>324</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>449</td>
                <td>200</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 25</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>46</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>440</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>652</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 26</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>53</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>407</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>647</td>
                <td>260</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 27</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>74</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>119</td>
                <td>331</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>476</td>
                <td>200</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 28</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>47</td>
                <td>76</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>400</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>490</td>
                <td>210</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 29</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>84</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>187</td>
                <td>567</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>733</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 04 30</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>87</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>585</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>713</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 01</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>71</td>
                <td>103</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>327</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>368</td>
                <td>310</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 02</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>90</td>
                <td>115</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>198</td>
                <td>530</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>530</td>
                <td>430</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 03</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>79</td>
                <td>107</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>164</td>
                <td>429</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>447</td>
                <td>530</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 04</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>91</td>
                <td>111</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>165</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>443</td>
                <td>370</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 05</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>92</td>
                <td>115</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>183</td>
                <td>467</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>485</td>
                <td>410</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 06</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>94</td>
                <td>121</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.63%</td>
                <td>0.37%</td>
                <td>231</td>
                <td>545</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.98%</td>
                <td>0.02%</td>
                <td>545</td>
                <td>460</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 07</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>82</td>
                <td>113</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>164</td>
                <td>356</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>372</td>
                <td>320</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 08</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>80</td>
                <td>117</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>811</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>382</td>
                <td>330</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 09</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>94</td>
                <td>116</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>189</td>
                <td>527</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>495</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 10</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>89</td>
                <td>114</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>559</td>
                <td>470</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 11</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>84</td>
                <td>115</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>182</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>337</td>
                <td>300</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 12</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>82</td>
                <td>108</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>503</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>410</td>
                <td>360</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 13</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>83</td>
                <td>112</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>419</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>405</td>
                <td>520</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 14</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>65</td>
                <td>96</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>336</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>321</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 15</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>66</td>
                <td>98</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>332</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>335</td>
                <td>290</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 16</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>91</td>
                <td>116</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>180</td>
                <td>506</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>444</td>
                <td>390</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 17</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>75</td>
                <td>104</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>379</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>381</td>
                <td>340</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 18</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>76</td>
                <td>105</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>405</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>382</td>
                <td>340</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 19</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>82</td>
                <td>114</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>159</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>406</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 20</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>78</td>
                <td>114</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>172</td>
                <td>387</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>409</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 21</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>70</td>
                <td>203</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>91.62%</td>
                <td>8.38%</td>
                <td>144</td>
                <td>335</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>91.62%</td>
                <td>8.38%</td>
                <td>356</td>
                <td>310</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 22</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>66</td>
                <td>101</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>139</td>
                <td>325</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>333</td>
                <td>300</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 23</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>90</td>
                <td>121</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>461</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>457</td>
                <td>400</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 24</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>76</td>
                <td>110</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>153</td>
                <td>413</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>402</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 25</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>80</td>
                <td>110</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>154</td>
                <td>454</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>412</td>
                <td>370</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 26</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>118</td>
                <td>204</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>163</td>
                <td>455</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>409</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 27</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>80</td>
                <td>117</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>435</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>417</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 28</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>73</td>
                <td>117</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>373</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>351</td>
                <td>300</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 29</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>81</td>
                <td>117</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>462</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>372</td>
                <td>320</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 30</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>79</td>
                <td>114</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>471</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>480</td>
                <td>410</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 05 31</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>73</td>
                <td>103</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>142</td>
                <td>429</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>420</td>
                <td>360</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 01</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>76</td>
                <td>88</td>
                <td></td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>176</td>
                <td>509</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>469</td>
                <td>360</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 02</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>72</td>
                <td>86</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>453</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>418</td>
                <td>330</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 03</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>75</td>
                <td>89</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>461</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>422</td>
                <td>330</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 04</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>82</td>
                <td>102</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>476</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>433</td>
                <td>340</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 05</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>78</td>
                <td>97</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>173</td>
                <td>520</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>441</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 06</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>66</td>
                <td>82</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>124</td>
                <td>347</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>335</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 07</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>66</td>
                <td>85</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>364</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>356</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 08</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>80</td>
                <td>98</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>183</td>
                <td>508</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>477</td>
                <td>370</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 09</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>75</td>
                <td>100</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>179</td>
                <td>541</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>448</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 10</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>86</td>
                <td>120</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>598</td>
                <td>790</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>500</td>
                <td>390</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 11</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>70</td>
                <td>107</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>185</td>
                <td>470</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>449</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 12</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>100</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>153</td>
                <td>458</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>448</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 13</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>117</td>
                <td></td>
                <td>0.027%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>79.17%</td>
                <td>20.83%</td>
                <td>147</td>
                <td>376</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>79.19%</td>
                <td>20.81%</td>
                <td>345</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 14</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>65</td>
                <td>138</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>173</td>
                <td>407</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>366</td>
                <td>290</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 15</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>71</td>
                <td>117</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>256</td>
                <td>690</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>518</td>
                <td>400</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 16</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>84</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>184</td>
                <td>477</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>454</td>
                <td>340</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 17</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>86</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>163</td>
                <td>425</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>458</td>
                <td>340</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 18</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>89</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>174</td>
                <td>441</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>444</td>
                <td>330</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 19</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>89</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>452</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>460</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 20</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>86</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>343</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>338</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 21</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>51</td>
                <td>82</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>332</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>359</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 22</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>177</td>
                <td>209</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>95.90%</td>
                <td>4.10%</td>
                <td>943</td>
                <td>1113</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>96.32%</td>
                <td>3.68%</td>
                <td>531</td>
                <td>380</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 23</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>83</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>139</td>
                <td>370</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>414</td>
                <td>320</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 24</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>46</td>
                <td>78</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>127</td>
                <td>324</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>337</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 25</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>46</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>440</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>456</td>
                <td>350</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 26</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>53</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>407</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>434</td>
                <td>330</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 27</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>74</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>119</td>
                <td>331</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>348</td>
                <td>270</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 28</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>47</td>
                <td>76</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>400</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>363</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 29</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>84</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>187</td>
                <td>567</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>504</td>
                <td>380</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2021 06 30</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>87</td>
                <td></td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>585</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>506</td>
                <td>390</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

