<app-header></app-header>
<main class="page-content about">
  <div class="container">
    <div class="about__row">
      <article class="about__content">
        <div class="section-header about__header">
          <h1 class="page-title">About us</h1>
          <p class="text-large"><b>Papaya</b> was founded in January 2012 and is a fully set up <b>Electronic Money Institution (EMI)</b>, headquartered in Malta. Papaya is regulated by the <b>Malta Financial Services Authority (MFSA)</b>, the single regulator for financial services in Malta – a growing international financial hub.</p>
        </div>
        <div class="about__text">
            <p><b>Papaya</b> is a young and vibrant player in the electronic money domain, with a hard-line philosophy of giving all clients the highest levels of service possible, through our on-going commitment to excellence and integrity. We strive to bring customers innovation and added value through our products, and aim to provide only the best operating and security conditions available.</p>
        </div>
      </article>
      <aside class="about__sidebar about-sidebar">
        <p class="about-sidebar__text">Diam phasellus vestibulum lorem sed risus ultricies tristique nulla.</p>
        <p class="about-sidebar__link">
          <a class="text-underline" href="#"><span>Malesuada fames ac turpis egoists. Viverra justo nec ultrices dui sapien eget mi proin.</span></a>
        </p>
        <p class="about-sidebar__link">
          <a class="text-underline" href="#"><span>Turpis egestas integer eget aliquet nibh praesent tristique.</span></a>
        </p>
        <p class="about-sidebar__link">
          <a class="text-underline" href="#"><span>Magna fermentum iaculis eu non diam phasellus vestibulum lorem sed. Mattis rhoncus urna neque viverra justo.</span></a>
        </p>
      </aside>
    </div>
  </div>
</main>
<app-footer></app-footer>
