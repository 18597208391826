<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
          <tr>
            <th>Date</th>
            <th>Channel</th>
            <th>Uptime, <br/> %</th>
            <th>Downtime, <br/> %</th>
            <th>AIS <br/> performance, <br/> ms</th>
            <th>PIS <br/> performance, <br/> ms</th>
            <th>CoF <br/> performance, <br/> ms</th>
            <th>Error response <br/> rate, <br/> %</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td rowspan="3" class="text-nowrap">4/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>74</td>
            <td>39</td>
            <td>N/A</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>267</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>369</td>
            <td>275</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>78</td>
            <td>43</td>
            <td>66</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>293</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>395</td>
            <td>288</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>51</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>517</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>590</td>
            <td>383</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>51</td>
            <td>74</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>524</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>58</td>
            <td>77</td>
            <td>0.045%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>439</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>521</td>
            <td>364</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>60</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>499</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>381</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>62</td>
            <td>73</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>412</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>383</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>59</td>
            <td>62</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>368</td>
            <td>286</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>63</td>
            <td>62</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>277</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>378</td>
            <td>296</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>68</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>137</td>
            <td>401</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>417</td>
            <td>308</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>79</td>
            <td>80</td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>576</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>348</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>55</td>
            <td>76</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>471</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>519</td>
            <td>344</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>49</td>
            <td>76</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>510</td>
            <td>337</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>50</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>443</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>47</td>
            <td>64</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>359</td>
            <td>278</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>88</td>
            <td>53</td>
            <td>67</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>299</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>390</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>59</td>
            <td>80</td>
            <td>0.021%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>549</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/18/2023</td>
            <td>Open Banking API</td>
            <td>97.98%</td>
            <td>2.02%</td>
            <td>109</td>
            <td>60</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>426</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>345</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>64</td>
            <td>75</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>528</td>
            <td>343</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>67</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>527</td>
            <td>348</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/21/2023</td>
            <td>Open Banking API</td>
            <td>99.92%</td>
            <td>0.08%</td>
            <td>115</td>
            <td>71</td>
            <td>76</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>507</td>
            <td>327</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>68</td>
            <td>34</td>
            <td>51</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>241</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>316</td>
            <td>251</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>73</td>
            <td>39</td>
            <td>54</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>255</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>337</td>
            <td>261</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>46</td>
            <td>66</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>450</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>531</td>
            <td>343</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>48</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>383</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>496</td>
            <td>333</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>51</td>
            <td>62</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>503</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/27/2023</td>
            <td>Open Banking API</td>
            <td>99.95%</td>
            <td>0.05%</td>
            <td>98</td>
            <td>82</td>
            <td>92</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>551</td>
            <td>345</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>57</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>356</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>57</td>
            <td>55</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>281</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>365</td>
            <td>286</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>58</td>
            <td>54</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>289</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>382</td>
            <td>298</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>59</td>
            <td>52</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>286</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>470</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/2/2023</td>
            <td>Open Banking API</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>99</td>
            <td>53</td>
            <td>88</td>
            <td>0.024%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>1417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>736</td>
            <td>517</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>79</td>
            <td>37</td>
            <td>61</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>667</td>
            <td>494</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>39</td>
            <td>60</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>347</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>634</td>
            <td>486</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>81</td>
            <td>44</td>
            <td>60</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>610</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>73</td>
            <td>43</td>
            <td>52</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>265</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>391</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>78</td>
            <td>48</td>
            <td>53</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>402</td>
            <td>362</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>52</td>
            <td>66</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>496</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>745</td>
            <td>535</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>55</td>
            <td>61</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>673</td>
            <td>504</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/10/2023</td>
            <td>Open Banking API</td>
            <td>99.74%</td>
            <td>0.26%</td>
            <td>99</td>
            <td>62</td>
            <td>66</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>657</td>
            <td>506</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>63</td>
            <td>63</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>413</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>657</td>
            <td>503</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>66</td>
            <td>62</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>621</td>
            <td>476</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/13/2023</td>
            <td>Open Banking API</td>
            <td>82.26%</td>
            <td>17.74%</td>
            <td>62</td>
            <td>35</td>
            <td>54</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>83.75%</td>
            <td>16.25%</td>
            <td>92</td>
            <td>287</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>82.50%</td>
            <td>17.50%</td>
            <td>380</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>68</td>
            <td>38</td>
            <td>54</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>262</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>396</td>
            <td>355</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>88</td>
            <td>46</td>
            <td>67</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>750</td>
            <td>535</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>50</td>
            <td>64</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>359</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>688</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>51</td>
            <td>62</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>324</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>683</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>53</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>261</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>672</td>
            <td>496</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>56</td>
            <td>62</td>
            <td>0.022%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>253</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>640</td>
            <td>486</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>55</td>
            <td>53</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>274</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>391</td>
            <td>351</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>59</td>
            <td>54</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>278</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>415</td>
            <td>366</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>66</td>
            <td>65</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>473</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>695</td>
            <td>493</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/23/2023</td>
            <td>Open Banking API</td>
            <td>99.50%</td>
            <td>0.50%</td>
            <td>103</td>
            <td>35</td>
            <td>62</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>346</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>666</td>
            <td>477</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>39</td>
            <td>62</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>352</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>647</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>43</td>
            <td>63</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>668</td>
            <td>493</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>46</td>
            <td>61</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>352</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>647</td>
            <td>484</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>45</td>
            <td>53</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>255</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>398</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>49</td>
            <td>55</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>266</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>409</td>
            <td>355</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>55</td>
            <td>64</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>437</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>725</td>
            <td>509</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>59</td>
            <td>63</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>375</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>680</td>
            <td>497</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>62</td>
            <td>66</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>710</td>
            <td>511</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>64</td>
            <td>64</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>408</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>588</td>
            <td>315</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>76</td>
            <td>37</td>
            <td>64</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>394</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>533</td>
            <td>285</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>68</td>
            <td>36</td>
            <td>55</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>358</td>
            <td>226</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>73</td>
            <td>40</td>
            <td>54</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>252</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>385</td>
            <td>237</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>48</td>
            <td>66</td>
            <td>0.062%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>542</td>
            <td>299</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>49</td>
            <td>66</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>506</td>
            <td>281</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>52</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>521</td>
            <td>291</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/8/2023</td>
            <td>Open Banking API</td>
            <td>98.89%</td>
            <td>1.11%</td>
            <td>93</td>
            <td>56</td>
            <td>66</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>405</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>519</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>58</td>
            <td>65</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>435</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>298</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>84</td>
            <td>54</td>
            <td>54</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>279</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>373</td>
            <td>238</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>59</td>
            <td>55</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>283</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>402</td>
            <td>253</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>67</td>
            <td>70</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>497</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>294</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>77</td>
            <td>38</td>
            <td>64</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>383</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>518</td>
            <td>271</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>84</td>
            <td>41</td>
            <td>63</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>406</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>538</td>
            <td>281</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>45</td>
            <td>64</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>400</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>522</td>
            <td>282</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>47</td>
            <td>62</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/17/2023</td>
            <td>Open Banking API</td>
            <td>81.84%</td>
            <td>18.16%</td>
            <td>79</td>
            <td>47</td>
            <td>54</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>85.65%</td>
            <td>14.35%</td>
            <td>370</td>
            <td>225</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>51</td>
            <td>55</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>273</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>403</td>
            <td>242</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>57</td>
            <td>64</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>303</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>62</td>
            <td>64</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>245</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>536</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>63</td>
            <td>62</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>235</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>292</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>66</td>
            <td>61</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>236</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>483</td>
            <td>259</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>36</td>
            <td>58</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>198</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>385</td>
            <td>225</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>67</td>
            <td>37</td>
            <td>54</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>88</td>
            <td>177</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>351</td>
            <td>212</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>70</td>
            <td>43</td>
            <td>54</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>190</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>383</td>
            <td>230</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>48</td>
            <td>64</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>236</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>554</td>
            <td>292</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>51</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>227</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>534</td>
            <td>283</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/28/2023</td>
            <td>Open Banking API</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>100</td>
            <td>54</td>
            <td>64</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>230</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>529</td>
            <td>287</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>56</td>
            <td>64</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>232</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>538</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>58</td>
            <td>65</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>235</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>543</td>
            <td>304</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

