<section class="intro" id="intro">
  <div class="intro__bg">
    <div class="intro__bg-image">
      <img src="assets/img/intro-bg.jpg" srcset="assets/img/intro-bg@2x.jpg 2x" alt="#">
      <video autoplay loop [muted]="true" playsinline>
        <source src="assets/video/intro.mp4?raw=true" type="video/mp4">
      </video>
    </div>
  </div>
  <div class="intro__top">
    <div class="container">
      <div class="intro__logo">
        <img src="assets/img/logo.svg" alt="#">
      </div>
    </div>
  </div>
  <div class="intro__main">
    <div class="container">
      <h1 class="intro__title">
        <div>The newest Fintech platform&nbsp;</div>
        <div>enabling your company&nbsp;</div>
        <div>to offer financial services&nbsp;</div>
      </h1>
      <p class="intro__desc-text">Banking services, bonus programs, co-branded payment cards, white-label mobile
        and online banking and any other customized solution for your business.</p>
      <nav class="intro__nav">
        <ul class="intro__nav-list">
          <li class="intro__nav-item">
            <a href="home#partners" class="intro__nav-link" data-to-section="#partners"><span>Partners</span></a>
          </li>
          <li class="intro__nav-item">
            <a href="home#services" class="intro__nav-link" data-to-section="#services"><span>Solutions</span></a>
          </li>
          <li class="intro__nav-item">
            <a routerLink="/about" class="intro__nav-link"><span>About Us</span></a>
          </li>
          <li class="intro__nav-item">
            <a routerLink="/contacts" class="intro__nav-link"><span>Contacts</span></a>
          </li>
          <li class="intro__nav-item">
            <a routerLink="/careers" class="intro__nav-link"><span>Careers</span></a>
          </li>
          <li class="intro__nav-item">
            <a routerLink="/ttp" class="intro__nav-link"><span class="text-default">Register for online banking</span></a>
          </li>
        </ul>
        <div class="intro__nav-lang">
          <div class="form-lang form-lang_sm form-lang_sm-left">
            <div class="form-lang__prepend">
              <img class="form-lang__prepend-icon" src="assets/img/lang/en.png" alt="EN">
            </div>
            <input type="text" class="form-lang__input" value="English" autocomplete="off" readonly>
            <div class="form-lang__arrow">
              <i class="material-icons">keyboard_arrow_down</i>
            </div>
            <div class="form-lang__dropdown">
              <div class="form-lang__dropdown-container">
                <a class="form-lang__dropdown-item" href="#" data-value='EN' data-icon="">
                  <div class="form-lang__dropdown-prepend">
                    <img class="form-lang__dropdown-icon" src="assets/img/lang/en.png" alt="EN">
                  </div>
                  <span class="form-lang__dropdown-text">English</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</section>
