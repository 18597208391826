import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyExchangesService {


  constructor(
    private http: HttpClient,
  ) {
  }

  post(type: string) {
    const day = new Date().toISOString().split('T')[0];
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
    const body = {
      day,
      servicetype: type
    };
    return this.http.post(`${environment.ratesApi}/dict/currencies/get-currency-rates`, body, {headers});
  }
}
