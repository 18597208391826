<app-header></app-header>
<main>
  <section class="contacts">
    <div class="container">
      <div class="contacts__row">
        <div class="contacts__left">
          <h1 class="page-title">Contacts</h1>
          <address class="contacts__address">
            <div class="contacts__address-item">
                Papaya Ltd,<br>
                31 Sliema Road, Gzira,<br>
                GZR 1637 – Malta
            </div>
            <div class="contacts__address-item">
                +356 20155500<br>
                <a class="contacts__mail-link" href="mailto:info@papaya.eu">info@papaya.eu</a>
            </div>
          </address>
          <div class="contacts__map-mobile">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3231.734841102593!2d14.490353315616169!3d35.90450932530082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x130e45490a4c61a7%3A0xfe5f37dafae526ab!2sPapaya%20Ltd.!5e0!3m2!1sen!2sen!4v1582112161281!5m2!1sen!2sen" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </div>
          <form [formGroup]='mailForm' class="contacts__form">
            <h2 class="contacts__title h3">General Inquiries</h2>
            <div class="contacts__inputs form-group">
              <div class="contacts__input-group">
                <input formControlName="name" class="input-control" type="text" name="name" placeholder="Your name">
              </div>
              <div class="contacts__input-group">
                <input formControlName="email" class="input-control" type="email" name="email" placeholder="Your email">
              </div>
            </div>
            <div class="contacts__textarea form-group" [ngClass]="{'error' : this.mailForm.get('message').invalid && (this.mailForm.get('message').touched || this.mailForm.get('message').dirty) }">
              <textarea formControlName="message" class="input-control" name="message" rows="4" placeholder="Message"></textarea>
              <span class="label-error" *ngIf="this.mailForm.get('message').invalid && (this.mailForm.get('message').touched || this.mailForm.get('message').dirty)">message required</span>
            </div>
            <div class="contacts__submit" >
              <button class="button button_orange-fill"  (click)="openEmail(mailForm)">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="contacts__map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3231.734841102593!2d14.490353315616169!3d35.90450932530082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x130e45490a4c61a7%3A0xfe5f37dafae526ab!2sPapaya%20Ltd.!5e0!3m2!1sen!2sen!4v1582112161281!5m2!1sen!2sen" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
    </div>
  </section>
</main>
<app-footer></app-footer>
