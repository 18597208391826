<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">Production environment</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <p>Access to production is available only for certified TPPs. Access to produc-tions data is granted automatically after providing eIDAS certificate. Once wehave validated your TPP identity and PSD2 legal permissions, you can create and use the application in a production environment.</p>
          <p>eIDAS certificate in Production Environment</p>
          <p>After having a license granted by a National Competent Authority, the TPP must purchase an eIDAS QSeal and QWAC certificate from one of the QTSPs listed in the EU Trusted List. This certificate will be used when onboarding to Papaya Open banking production. After the successful onboarding, the TPPs must use the same certificate in each API call. All connections in production environment targeted towards PSD2 APIs when a valid eIDAS certificate is not presented will be rejected.</p>
          <p>eIDAS Certificate Validation and Revocation validation</p>
          <p>When the client certificate is used, it will always be validated by Papaya system. The validation contains trust chain validations including expiration check and verification towards revocation on top of the actual signature validation. If any of the validations are not passing the connection will be rejected. Please note that the owner of the certificate must renew the certificate before its expiration. In the case of the new certificate, the enrollment step must be re-initiated.</p>
          <p class="mb-0"><strong>Note:</strong> <br> We reserve the right to revoke TPP access to any of our endpoints if they use this for unintended purposes. Please play by PSD2 rules.</p>
          <p><strong>Note:</strong> <br> When you start working with Bank API in Live, make sure you have theNCA license (PISP, AISP, PIISP). Each country has its own guidelines on obtaining a license, please contact your local NCA.</p>
          <h4>Contact us</h4>
          <p>If you have some unique business case and you want to create a business with Papapya based on non PSD2 APIs please contact us via mail with a description of your business case and with the description of financial services you’d need.</p>
          <p>Required License</p>
          <p>For Account Information</p>
        </section>
        <div class="table-wrapper">
          <table class="table-border table-striped">
            <colgroup>
              <col width="20%">
              <col width="15%">
              <col width="15%">
              <col width="15%">
              <col width="30%">
            </colgroup>
            <tr>
              <th>API</th>
              <th>AISP<br> License</th>
              <th>PISP<br> License</th>
              <th>Banking<br> License</th>
              <th>Payment Instrument Issuing<br> License</th>
            </tr>
            <tr>
              <td><b>Account Transactions</b></td>
              <td>YES</td>
              <td>NO</td>
              <td>YES</td>
              <td>NO</td>
            </tr>
            <tr>
              <td><b>Account Balance</b></td>
              <td>YES</td>
              <td>NO</td>
              <td>YES</td>
              <td>NO</td>
            </tr>
            <tr>
              <td><b>Account Details</b></td>
              <td>YES</td>
              <td>NO</td>
              <td>YES</td>
              <td>NO</td>
            </tr>
            <tr>
              <td><b>Confirmation of Funds</b></td>
              <td>NO</td>
              <td>NO</td>
              <td>YES</td>
              <td>YES</td>
            </tr>
          </table>
          <p class="my-2">For Payment Initiation</p>
          <table class="table-border table-striped">
            <tr>
              <th>API</th>
              <th>AISP License</th>
              <th>PISP License</th>
              <th>Banking License</th>
              <th>Payment Instrument Issuing License</th>
            </tr>
            <tr>
              <td><b>Payment</b></td>
              <td>NO</td>
              <td>YES</td>
              <td>YES</td>
              <td>NO</td>
            </tr>
          </table>
        </div>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
