<app-header></app-header>
<main class="page-content page-content_block">
  <div class="container">
    <article class="section section_page">
      <div class="section__header">
        <h1 class="page-title">Data Protection Policy
        </h1>
      </div>
      <section class="section__row">
        <h4 class="text-uppercase">DATA PROTECTION POLICY</h4>
        <p>
          The principles how Papaya processes Personal Data are described in this Policy.<br>
          The Policy applies if you visit our Website or/and use, have used or have expressed an intention to use or are in other way related to any of the services provided by us.
        </p>
        <p>
          <strong class="text-uppercase">1. DEFINITIONS</strong><br>
          <strong>“Controller”</strong> means a person which determines the purposes and means of the Processing of Personal Data.<br>
          <strong>“Personal Data”</strong> means any information about personal or factual circumstances of a specific or identifiable natural person, such as e.g. name and surname, date of birth, place of birth, identification document (including type of identification document, issue date, ID number, issuing authority), address, telephone number, mobile phone number, e-mail address, IP address, online identifier, location data, images and information on transactions and accounts.<br>
          <strong>“Policy”</strong> means this Data Protection Policy.<br>
          <strong>“Processing”</strong> means any operation carried out with Personal Data (incl. collection, recording, storing, alteration, grant of access to, making enquiries, transfer, etc.).<br>
          <strong>“Processor“</strong> means a natural or legal person, public authority or an agency which processes Personal Data on behalf of the Controller.<br>
          <strong>“Profiling”</strong> means any form of automated processing of Personal Data consisting of the use of Personal Data to evaluate certain personal aspects relating to you, in particular, to analyse or predict aspects concerning that your performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements.<br>
          <strong>“Recipient”</strong>  means a natural or legal person, public authority, agency or another body, to which the Personal Data are disclosed, whether a third party or not. However, public authorities which may receive Personal Data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients.<br>
          <strong>“Third party”</strong> means a natural or legal person, public authority or an agency other than you, we (as a Controller), Processor and persons who, under the direct authority of the Controller or Processor, are authorised to process Personal Data.<br>
          <strong>“we”</strong>, <strong>“us”</strong> or <strong>“our”</strong> or <strong>“Papaya”</strong> means Papaya Ltd., registered with the Registrar of Companies in Malta, with the registered office: 31 Sliema Road, Gzira GZR 1637, Malta, and registration no. C 55146. VAT No.: MT 2075 1731; Papaya’s head office is located at 31 Sliema Road, Gzira GZR 1637; electronic mail address – <a href="mailto:info@papaya.eu">info@papaya.eu</a>. Papaya Ltd is Electronic Money Institution (EMI), headquartered in Malta and regulated by the Malta Financial Services Authority (MFSA).<br>
          <strong>“Website”</strong> means Papaya’s website at <a routerLink="/">www.papaya.eu</a>.<br>
          <strong>“you”</strong> or <strong>“your”</strong> refers to an individual who uses, have used or have expressed an intention to use or are in other way related to any of the services provided by us.<br>
        </p>
        <p>
          <strong class="text-uppercase">2. GENERAL PROVISIONS</strong><br>
          2.1. The purpose of this Policy is to inform you about Processing of your Personal Data.<br>
          2.2. By giving us your Personal Data, you consent to us the processing of Personal Data for the purposes stated in this Policy.<br>
          2.3. We ensure, within the framework of applicable law, the confidentiality of Personal Data and has implemented appropriate technical and organisational measures to safeguard Personal Data from unauthorized access, unlawful Processing or disclosure, accidental loss, modification or destruction.<br>
          2.4. We may use authorised Processors for Processing of Personal Data. In such cases, we take needed steps to ensure that such data processors Process Personal Data under our instructions and in compliance with applicable law and requires adequate security measures.<br>
          2.5. We are not responsible for the processing of Personal Data by any person acting as a data controller, and such processing is not covered by this Policy.
        </p>
        <p>
          <strong class="text-uppercase">3. DATA CONTROLLER</strong><br>
          3.1. The controller of Personal Data that collect, process and use is:<br>
          3.1.1. <strong>Papaya Ltd.</strong>, registered with the Registrar of Companies in Malta, with the registered office: 31 Sliema Road, Gzira GZR 1637, Malta, and registration no. C 55146. VAT No.: MT 2075 1731; Papaya’s head office is located at 31 Sliema Road, Gzira GZR 1637; electronic mail address – info@papaya.eu. Papaya Ltd is Electronic Money Institution (EMI), headquartered in Malta and regulated by the Malta Financial Services Authority (MFSA).<br>
          3.2. Representative: Mr. Marko Dronjaks, Data Protection Officer (<a href="mailto:marko@papaya.eu">marko@papaya.eu</a>).<br>
          3.3. IDT Financial Services Limited (“IDT”) is the issuer of the card associated with your Papaya Prepaid Card. Accordingly, IDT is a joint controller of some of your personal information as it relates to, and is required for, the administration and operation of the card. A copy of IDT’s privacy policy may be found at http://www.idtfinance.com/privacypolicy.pdf.
        </p>
        <p>
          <strong class="text-uppercase">4. CATEGORIES OF PERSONAL DATA</strong><br>
          4.1. Personal Data may be collected exactly from you, from your use of the services, from external sources (public and private registers, open sources) or other third parties. Personal Data categories which we primarily, but not only, collect and processes are:<br>
          4.1.1. <strong>Identification data</strong>: name, and surname, date of birth, place of birth, identification document (including the type of identification document, issue date, ID number, issuing authority), your selfie (photo).<br>
          4.1.2. <strong>Contact data</strong>: address, telephone number, mobile phone number, e-mail address.<br>
          4.1.3. <strong>Data about tax residency</strong>: data about the country of residence, tax identification number, citizenship.<br>
          4.1.4. <strong>Family data</strong>: information about your family and other related person’s.<br>
          4.1.5. <strong>Professional data</strong>: educational or professional career, occupation.<br>
          4.1.6. <strong>Financial data</strong>: accounts and accounts data, payment instruments and payments instrument data, ownership, transactions, credits, income, liabilities, your financial experience and reputation, as well as other related data collected during the provision of services.<br>
          4.1.7. <strong>Data on the origin of assets or wealth</strong>: data regarding your transaction partners and business activities.<br>
          4.1.8. <strong>Data about due diligence</strong>: data about payment behaviour, data that enables us to perform our due diligence measures regarding money laundering and terrorist financing prevention and to ensure the compliance with national and international sanctions.<br>
          4.1.9. <strong>Data processing by performing an obligation arising from the law</strong>: data resulting from enquiries made by investigative bodies, notaries, tax administrator, courts and bailiffs.<br>
          4.1.10. <strong>Communication data</strong>: data collected when you visit us or communicate with us via telephone, visual and/or audio recordings, e-mail, messages and other communication mechanisms, data related to your visit at Website or communicating through other channels (for example, mobile application).<br>
          4.1.11. <strong>Data related to contractual obligations</strong>: the performance or the failure of the agreements, executed transactions, usage of ATMs, submitted applications, requests and complaints, service fees and charges.<br>
          4.1.12. <strong>Data about the relationships with legal entities</strong>: data for the execution of transactions on behalf of the legal entity in question.<br>
          4.1.13. <strong>Location data</strong>, for example, GPS coordinates.<br>
          4.1.14. <strong>Information Technology data</strong>, for example, IP address, online identifier, information about the device.<br>
        </p>
        <p>
          <strong class="text-uppercase">5. PURPOSES OF PROCESSING</strong><br>
          5.1. We collect your Personal Data primarily for the following purposes:<br>
          5.1.1. <strong>the performance of contractual and pre-contractual obligations</strong>: handling (processing) of your application for services prior to entering into an agreement; conclusion and execution of agreements; keeping Personal Data updated and correct by verifying and enriching through external and internal sources based on performance of an agreement or in order to take steps at your application prior to entering into an agreement or compliance with a legal obligation;<br>
          5.1.2. <strong>the protection of your and our interest</strong>: assurance and examine the quality of services provided by us; providing proof of a transaction or of communication based on the performance of an agreement or in order to process your application prior to entering into an agreement or compliance with a legal obligation or your consent or our legitimate interests to prevent, limit and investigate any misuse or unlawful use or disturbance of our services; our legitimate interest to protect you, our employees, visitors and our and your assets;<br>
          5.1.3. <strong>the prevention of abuse of services and provision of adequate services</strong> to ensure the safety of information and our digital services based on the performance of an agreement or in order to process your application prior to entering into an agreement or compliance with a legal obligation or your consent or our legitimate interests to have control over authorizations, access to and functioning of our digital services; to improve technical systems and IT-infrastructure based on our legitimate interests to improve technical systems and IT-infrastructure;<br>
          5.1.4. <strong>the ensuring compliance with applicable laws and regulation to which we are subject</strong>, including laws and regulation related to tax, prevention of money laundering and funding of terrorism, reporting to competent authorities, and compliance with orders from any court or competent authority;<br>
          5.1.5. <strong>the execution of transaction through the payment system</strong> to execute international transactions or/and domestic payments via credit institutions or/and domestic, European and international payment systems and to comply with rules and obligations of European and international standards and certification schemes based on the performance of an agreement or in order to process your application prior to entering into an agreement or compliance with a legal obligation;<br>
          5.1.6. <strong>the promotion and marketing of our products and services</strong>: offering to you our additional services, including personalized offers, based on your consent or our legitimate interest to offer additional services. The performance of market analyses based on our legitimate interest to improve our services and to develop new products or your consent;<br>
          5.1.7. <strong>risk assessment and analysis</strong> to carry out risk assessments in order to determine which services and on what terms can be offered to you, to comply with applicable law relating to risk assessments, capital requirements, internal calculations and analyses based on the performance of an agreement or in order to process your application prior to entering into an agreement or compliance with a legal obligation or our legitimate interests to a sound risk management.<br>
          5.2. Providing your Personal Data to us is optional. However, if you choose not to provide certain Personal Data to us, we may not be able to deliver products or services to you.
        </p>
        <p>
          <strong class="text-uppercase">6. PROFILING AND AUTOMATED DECISION MAKING</strong><br>
          6.1. Profiling is used to make analysis for advice, for automated decision-making, for risk management, for transaction monitoring to counter fraud and is based on our legitimate interest, compliance with a legal obligation, performance of an agreement or consent from you.<br>
          6.2. Unless direct marketing has been restricted to you, we may process Personal Data for the purpose of providing general and personalized offers of our services. Such marketing may be based on services you use and on how you navigate in digital our channels.<br>
          6.3. For personal offering and marketing-based profiling, we ensure that you can make choices and use a convenient tool to manage your privacy settings.
        </p>
        <p>
          <strong class="text-uppercase">7. PROCESSING OF PERSONAL DATA</strong><br>
          7.1. Personal Data will be processed by our employees and officers.<br>
          7.2. Personal Data may also be transferred to authorised Processors who act for or on our behalf, for further processing in accordance with the purpose(s) for which Personal Data were originally collected. These Processors have contracted with us to only use your Personal Data for the agreed upon purpose, and not to sell your personal information to third parties, and not to disclose it to third parties except as may be required by law, as permitted by us or as stated in this Policy.<br>
        </p>
        <p>
          <strong class="text-uppercase">8. RECIPIENTS OF PERSONAL DATA</strong><br>
          8.1. Personal Data may be shared with other Recipients, such as:<br>
          8.1.1. authorities (for example, supervision authorities and financial intelligence units, law enforcement authorities, bailiffs, notary offices, tax authorities);<br>
          8.1.2. credit and financial institutions, intermediaries of financial services, third parties involved in the execution of the transaction, settlement and reporting cycle;<br>
          8.1.3. participants and/or parties related to domestic, European and International payment systems, such as SWIFT;<br>
          8.1.4. third parties that supervise and audit our operations;<br>
          8.1.5. third parties maintaining registers (for example, credit registers, population registers, commercial registers);<br>
          8.1.6. other persons who provide services to us, such as postal services.
        </p>
        <p>
          <strong class="text-uppercase">9. RECIPIENTS OF PERSONAL DATA</strong><br>
          9.1. Your Personal Data will not be stored for a time period exceeding the necessary term to the purpose of their collection, according to applicable law.<br>
        </p>
        <p>
          <strong class="text-uppercase">10. TRANSFER OF PERSONAL DATA TO COUNTRIES OUTSIDE THE EUROPEAN UNION</strong><br>
          10.1. Personal Data may be transferred outside the European Union in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of Personal Data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) and Commission Decision of 20 December 2001 pursuant to Directive 95/46/EC of the European Parliament and of the Council on the adequate protection of Personal Data provided by the Canadian Personal Information Protection and Electronic Documents Act (notified under document number C(2001) 4539).
        </p>
        <p>
          <strong class="text-uppercase">11. YOUR RIGHTS AS A DATA SUBJECT</strong><br>
          11.1. You are entitled to:<br>
          11.1.1. receive information if your Personal Data is being processed by us and if so then to access it;<br>
          11.1.2. receive Personal Data provided by you and processed by us based on consent or in order to perform an agreement in written or commonly used electronic format and were feasible transmit such data to another service provider;<br>
          11.1.3. require the correction your Personal Data where appropriate (if it is inadequate, incomplete or incorrect).<br>
          11.1.4. withdraw your consent for Processing of your Personal Data;<br>
          11.1.5. require the erasure of your Personal Data. Such right does not apply if Processing of Personal Data also based on legal grounds such as agreement or obligations based on applicable law.<br>
          11.1.6. object to Processing of your Personal Data, if the use of Personal Data is based on a legitimate interest, for example, for direct marketing;<br>
          11.1.7. restrict the Processing of your Personal Data under applicable law;<br>
          11.1.8. not to be subject to fully automated decision-making, including profiling, if such decision-making has legal effects or similarly significantly affects you. This right does not apply if the decision-making is necessary in order to enter into or to perform an agreement with you if the decision-making is permitted under applicable law or if you have provided an explicit consent.<br>
          11.2. If you wish to exercise any of your rights as a data subject or if you have any questions, please contact us by sending an e-mail to <a href="mailto:customerservice@papaya.eu">customerservice@papaya.eu</a>, by visiting us or writing to us at Papaya Ltd., 31, Sliema Road, Gzira, GZR 1637, Malta.<br>
          11.3. If you have any concerns about your Personal Data processing by us you have a right to make a complaint to the Information and Data Protection Commissioner of Malta (https://idpc.org.mt).<br>
        </p>
        <p>
          <strong class="text-uppercase">12. USE OF COOKIES AND WEB BEACONS</strong><br>
          12.1. We may use the following technologies to collect such information:<br>
          12.1.1. Cookies. “Cookies” are small text files sent by a Website so that the Website can recall who you are; they serve as a memory, therefore, enabling a website to remember users who have already visited the site. Such cookies are stored on the user’s computer.<br>
          12.1.2. Web beacons. Web beacons are small image files on a web page that are placed on a Website or in an email. Web beacons are similar to cookies and are used to track the online movements of the users. These are invisible and you can render them ineffective by changing the browser’s cookie settings to refuse cookies. Web beacons can collect information such as an IP Address, your computer’s operating system, the content viewed on the website and the time it was viewed, a browser type and the existence of cookies previously set by the server.<br>
          12.2. More detailed information about cookies and similar tracking technologies is given in our Cookie Policy (available on www.papaya.eu).<br>
        </p>
        <p>
          <strong class="text-uppercase">13. LINKS TO OTHER WEBSITES</strong><br>
          13.1. Our Website may contain links to websites operated by third parties. When connecting to such other websites you will no longer be subject to this Policy but to the privacy and data protection policy of the other website. We are not responsible for the privacy and data protection practices of these other websites and we encourage you to read the privacy and data protection statements of each website you visit, which may collect personal information.<br>
        </p>
        <p>
          <strong class="text-uppercase">14. CHANGES TO THIS POLICY</strong><br>
          14.1. This Policy is available to you at our Main Office, on the Website, on the Mobile Application. We may change this Policy at any time by notifying you of any amendments at our Main Office, on the Website, on the Mobile Application no later than one month prior to the amendments entering into force.<br>
        </p>
      </section>
    </article>
  </div>
</main>
<app-footer></app-footer>
