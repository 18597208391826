import {Component, OnInit, AfterViewInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TimelineMax } from 'gsap';
import * as ScrollMagic from 'scrollmagic';
import { AnimationService } from '../animation.service';

const controller = new ScrollMagic.Controller();

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  prev;

  constructor(private router: Router, private animationService: AnimationService) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd && evt.url !== '/home#partners' && evt.url !== '/home#services')) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.componentDidMount();
    this.stickyHeader();
    this.animations();
  }

  stickyHeader() {
    new ScrollMagic.Scene({
      triggerElement: "#partners",
      triggerHook: 'onLeave',
    })
      .setPin('.header')
      .setClassToggle('.header', 'header_sticky')
      .addTo(controller)
  }

  componentDidMount() {
    this.prev = window.scrollY;
    window.addEventListener('scroll', e => this.handleNavigation(e));
  }

  handleNavigation = (e) => {
      const window = e.currentTarget;
      if (this.prev > window.scrollY) {
          // console.log("scrolling up");
      } else if (this.prev < window.scrollY) {
          // console.log("scrolling down");
      }
      this.prev = window.scrollY;
  };

  animations() {
    const contentText = document.querySelectorAll('.js-fade-up');
    const contentItems = document.querySelectorAll('.js-fade-up-block');

    const tweenAnimateContent = element => {
      const tl = new TimelineMax();
      this.animationService.animateFadeUp(tl, element);

      const scene = new ScrollMagic.Scene({
        triggerElement: element,
        triggerHook: 'onEnter'
      })
      .setTween(tl)
      .addTo(controller);
    }

    contentText.forEach(tweenAnimateContent);

    if (window.innerWidth < 768) {
      contentItems.forEach(tweenAnimateContent);
    }
  }
}
