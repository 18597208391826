import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.faqPanels();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
  }

  faqPanels() {
    const panel = document.querySelectorAll('.faq-panel');

    if (!panel) {
      return;
    }

    panel.forEach(element => {
      const title = element.querySelector('.faq-panel__title');

      title.addEventListener('click', event => {
        const target = event.target as HTMLElement;
        const panel = target.closest('.faq-panel');

        if (panel.classList.contains('active')) {
          panel.classList.remove('active');

          return;
        }

        panel.classList.add('active');
      });
    });
  }
}
