import { Component, OnInit } from '@angular/core';
import { TimelineMax, Power1 } from 'gsap';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.intoAnimate();
  }

  intoAnimate() {
    const tl = new TimelineMax();

    tl.from('.intro__top', 0.4, {
      opacity: 0,
      ease: Power1.easeOut,
      delay: 0.3
    })

    .from('.intro__title', 0.3, {
      opacity: 0,
      ease: Power1.easeOut,
    }, '-=0.2')

    .from('.intro__desc-text', 0.3, {
      opacity: 0,
      ease: Power1.easeOut
    }, '-=0.2')

    .from('.intro__nav', 0.3, {
      opacity: 0,
      ease: Power1.easeOut
    }, '-=0.2');
  }
}
