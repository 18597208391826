<app-header></app-header>
<main class="page-content career">
  <div class="container">
    <div class="career__row">
      <article class="career__content">
        <div class="section-header career__header">
          <h1 class="page-title">Careers</h1>
          <p class="career__header-second-title text-light">Papaya Ltd. is committed to providing its staff with a friendly working environment in which they can develop and grow professionally. We are constantly looking for energetic, creative and enthusiastic people to join our dynamic team.</p>
          <p class="career__header-second-title text-light">If you are passionate about what you do and feel you have what it takes to succeed at Papaya, we would like to hear from you.</p>
          <p class="career__header-second-title text-light">Send your application including a detailed CV to <a href="mailto:humanresources@papaya.eu">humanresources@papaya.eu</a>.  All the applications that we receive will be treated with strict confidentiality.</p>
        </div>

        <p class="mt-5 mb-3">As part of its growth, Papaya is seeking to recruit a <b>Corporate Client Relationship Manager</b> as part of its team.</p>

        <div class="career__list">
          <div class="career-panel" [ngClass]="{'active': isTooltipCareerSenior}">
            <div class="career-panel__title" (click)="showTooltip('careerSenior')">
              Responsibilities
            </div>
            <div *ngIf="isTooltipCareerSenior" class="career-panel__content">
              <ul class="list-unstyled">
                <li>Generate business leads and attract new clients to the company</li>
                <li>Providing client support and handling client communications effectively</li>
                <li>Conducting primary check of client legal and business documentation in accordance with the current AML regulations prior to submitting for due diligence</li>
                <li>Liaising with the Compliance department in relation to compliance related matters</li>
                <li>Addressing client concerns promptly and professionally</li>
                <li>Collect feedback from clients regarding the company’s services</li>
                <li>Liaise with team members / superiors to ensure the smooth running of the daily operations</li>
              </ul>
            </div>
          </div>
          <div class="career-panel" [ngClass]="{'active': isTooltipCareerDirector}">
            <div class="career-panel__title" (click)="showTooltip('careerDirector')">
              Requirements
            </div>
            <div *ngIf="isTooltipCareerDirector" class="career-panel__content">
              <ul class="list-unstyled">
                <li>2-3 years of experience working with corporate clients in a similar role, ideally working with financial institutions</li>
                <li>Higher education in Finances, Economics, Banking or similar would be considered  an asset</li>
                <li>Fluent knowledge of English language (oral/written). Knowledge of any other language will be considered as an asset</li>
                <li>Solid knowledge of Microsoft Office</li>
                <li>Excellent communication skills</li>
                <li>Analytical and good time management skills</li>
                <li>Ability to work independently and together with a team</li>
                <li>The candidate must be eligible to work in EU</li>
              </ul>
              <p>Send your application including a detailed CV to <a href="mailto:humanresources@papaya.eu">humanresources@papaya.eu</a></p>
            </div>
          </div>
        </div>

        <p class="mt-4 mb-3">As part of its growth, Papaya is seeking to recruit a <b>Client Relationship Assistant</b> as part of its team.</p>

        <div class="career__list">
          <div class="career-panel" [ngClass]="{'active': isTooltipCareerCoordinator}">
            <div class="career-panel__title" (click)="showTooltip('careerCoordinator')">
              Responsibilities
            </div>
            <div *ngIf="isTooltipCareerCoordinator" class="career-panel__content">
              <ul class="list-unstyled">
                <li>Provide administrative assistance to the corporate team in the organisation of the client relations</li>
                <li>Effectively manage the communication with customers in person / via email / by telephone</li>
                <li>Provide accurate / valid information to customers</li>
                <li>Provide ongoing support to customers in all their needs</li>
                <li>Handling and resolving of complaints</li>
                <li>Liaise with team members / superiors to ensure the smooth running of the daily operations</li>
              </ul>
            </div>
          </div>
          <div class="career-panel" [ngClass]="{'active': isTooltipCareerRequirements}">
            <div class="career-panel__title" (click)="showTooltip('careerRequirements')">
              Requirements
            </div>
            <div *ngIf="isTooltipCareerRequirements" class="career-panel__content">
              <ul class="list-unstyled">
                <li>Completion of secondary education or equivalent</li>
                <li>Previous experience in a similar role will be considered as an asset</li>
                <li>Fluent knowledge of English language (oral / written). Knowledge of any other language will be considered as an asset</li>
                <li>Solid knowledge of Microsoft Office (mainly Excel and Word)</li>
                <li>Excellent communication skills</li>
                <li>High organisation skills</li>
                <li>Strong attention to detail</li>
                <li>Ability to work under pressure</li>
                <li>Ability to work as a part of the team, as well as on own initiative</li>
                <li>The candidate must be eligible to work in EU</li>
              </ul>
            </div>
          </div>
        </div>

        <p class="mt-4">Send your application including a detailed CV to <a href="mailto:humanresources@papaya.eu">humanresources@papaya.eu</a></p>
      </article>
    </div>
  </div>
</main>
<app-footer></app-footer>
