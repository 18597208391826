<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
          <tr>
            <th>Date</th>
            <th>Channel</th>
            <th>Uptime, <br /> %</th>
            <th>Downtime, <br /> %</th>
            <th>AIS <br /> performance, <br /> ms</th>
            <th>PIS <br /> performance, <br /> ms</th>
            <th>CoF <br /> performance, <br /> ms</th>
            <th>Error response <br /> rate, <br /> %</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td rowspan="3" class="text-wrap">01.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>255</td>
            <td>105</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>473</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1010</td>
            <td>728</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">02.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>242</td>
            <td>83</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>293</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>587</td>
            <td>545</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">03.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>251</td>
            <td>85</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>282</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>601</td>
            <td>548</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">04.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>265</td>
            <td>106</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>579</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1005</td>
            <td>727</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">05.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>266</td>
            <td>110</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>508</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>913</td>
            <td>639</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">06.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>257</td>
            <td>102</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>484</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>881</td>
            <td>680</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">07.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>262</td>
            <td>110</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>553</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>885</td>
            <td>688</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">08.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>291</td>
            <td>117</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>270</td>
            <td>568</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>896</td>
            <td>725</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">09.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>247</td>
            <td>98</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>299</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>530</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">10.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>251</td>
            <td>84</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>188</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>550</td>
            <td>513</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">11.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>175</td>
            <td>301</td>
            <td>128</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>302</td>
            <td>648</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1035</td>
            <td>784</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">12.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>289</td>
            <td>123</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>524</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>933</td>
            <td>714</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">13.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>270</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>215</td>
            <td>454</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>898</td>
            <td>677</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">14.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>266</td>
            <td>100</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>443</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>903</td>
            <td>691</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">15.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>262</td>
            <td>115</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>487</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1037</td>
            <td>735</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">16.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>243</td>
            <td>85</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>274</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>508</td>
            <td>423</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">17.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>251</td>
            <td>87</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>293</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>533</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">18.07.2022</td>
            <td>Open Banking API</td>
            <td>92.00%</td>
            <td>8.00%</td>
            <td>159</td>
            <td>299</td>
            <td>107</td>
            <td>1.387%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>92.00%</td>
            <td>8.00%</td>
            <td>260</td>
            <td>1171</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>984</td>
            <td>713</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">19.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>272</td>
            <td>100</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>465</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>902</td>
            <td>658</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">20.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>252</td>
            <td>95</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>482</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>857</td>
            <td>660</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">21.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>253</td>
            <td>112</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>202</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>868</td>
            <td>676</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">22.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>259</td>
            <td>107</td>
            <td>0.110%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>444</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>914</td>
            <td>757</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">23.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>222</td>
            <td>78</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>267</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>515</td>
            <td>458</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">24.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>234</td>
            <td>83</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>521</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">25.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>263</td>
            <td>290</td>
            <td>125</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>544</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1004</td>
            <td>758</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">26.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1171</td>
            <td>2412</td>
            <td>1127</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>194</td>
            <td>414</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>856</td>
            <td>642</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">27.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>254</td>
            <td>94</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>899</td>
            <td>650</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">28.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>252</td>
            <td>94</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>899</td>
            <td>674</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">29.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>270</td>
            <td>96</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>989</td>
            <td>735</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">30.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>247</td>
            <td>77</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>263</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>540</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">31.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>252</td>
            <td>89</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>583</td>
            <td>519</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">01.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>257</td>
            <td>84</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>178</td>
            <td>406</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>648</td>
            <td>555</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">02.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>252</td>
            <td>85</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>168</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>690</td>
            <td>589</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">03.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>244</td>
            <td>92</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>502</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1107</td>
            <td>803</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">04.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>282</td>
            <td>107</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>248</td>
            <td>864</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>930</td>
            <td>695</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">05.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>278</td>
            <td>104</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>840</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>932</td>
            <td>735</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">06.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>244</td>
            <td>80</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>548</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>540</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">07.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>254</td>
            <td>79</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>571</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>570</td>
            <td>522</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">08.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>166</td>
            <td>298</td>
            <td>112</td>
            <td>0.079%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>267</td>
            <td>983</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1039</td>
            <td>818</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">09.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>153</td>
            <td>316</td>
            <td>118</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>932</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>919</td>
            <td>698</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">10.08.2022</td>
            <td>Open Banking API</td>
            <td>93.00%</td>
            <td>7.00%</td>
            <td>452</td>
            <td>1100</td>
            <td>235</td>
            <td>0.036%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>496</td>
            <td>1363</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>947</td>
            <td>745</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">11.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>266</td>
            <td>91</td>
            <td>0.019%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>794</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>903</td>
            <td>689</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">12.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>275</td>
            <td>97</td>
            <td>0.046%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>213</td>
            <td>807</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>918</td>
            <td>718</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">13.08.2022</td>
            <td>Open Banking API</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>117</td>
            <td>261</td>
            <td>78</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>138</td>
            <td>592</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>505</td>
            <td>432</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">14.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>282</td>
            <td>85</td>
            <td>0.063%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>170</td>
            <td>627</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>574</td>
            <td>505</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">15.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>377</td>
            <td>111</td>
            <td>0.260%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>287</td>
            <td>1049</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1089</td>
            <td>805</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">16.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>284</td>
            <td>89</td>
            <td>0.038%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>773</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>927</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">17.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>244</td>
            <td>88</td>
            <td>0.018%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>211</td>
            <td>711</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>918</td>
            <td>680</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">18.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>248</td>
            <td>89</td>
            <td>0.064%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>671</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>911</td>
            <td>702</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">19.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>308</td>
            <td>90</td>
            <td>0.024%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>199</td>
            <td>705</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>878</td>
            <td>700</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">20.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>243</td>
            <td>89</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>137</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>485</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">21.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>254</td>
            <td>84</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>174</td>
            <td>536</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>531</td>
            <td>452</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">22.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>281</td>
            <td>105</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>281</td>
            <td>924</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1192</td>
            <td>776</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">23.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>262</td>
            <td>89</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>234</td>
            <td>736</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>910</td>
            <td>666</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">24.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>264</td>
            <td>88</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>210</td>
            <td>713</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>869</td>
            <td>647</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">25.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>265</td>
            <td>88</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>719</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>903</td>
            <td>677</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">26.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>284</td>
            <td>84</td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>211</td>
            <td>717</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>886</td>
            <td>672</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">27.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>233</td>
            <td>73</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>536</td>
            <td>478</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">28.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>246</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>558</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>625</td>
            <td>560</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">29.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>267</td>
            <td>97</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>247</td>
            <td>849</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1053</td>
            <td>774</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">30.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>253</td>
            <td>101</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>754</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1036</td>
            <td>774</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">31.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>250</td>
            <td>98</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>222</td>
            <td>772</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1059</td>
            <td>761</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">01.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>468</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>209</td>
            <td>725</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>905</td>
            <td>685</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">02.09.2022</td>
            <td>Open Banking API</td>
            <td>97.30%</td>
            <td>2.70%</td>
            <td>127</td>
            <td>337</td>
            <td>90</td>
            <td>0.115%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>243</td>
            <td>732</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>849</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">03.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>222</td>
            <td>72</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>494</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">04.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>247</td>
            <td>85</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>167</td>
            <td>509</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>490</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">05.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>290</td>
            <td>103</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>259</td>
            <td>885</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>988</td>
            <td>789</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">06.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>280</td>
            <td>96</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>222</td>
            <td>761</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>911</td>
            <td>681</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">07.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>278</td>
            <td>98</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>763</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>853</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">08.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>283</td>
            <td>98</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>771</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>861</td>
            <td>686</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">09.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>157</td>
            <td>279</td>
            <td>105</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>256</td>
            <td>839</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>866</td>
            <td>705</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">10.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>238</td>
            <td>76</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>497</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>497</td>
            <td>457</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">11.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>247</td>
            <td>81</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>539</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>549</td>
            <td>511</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">12.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>280</td>
            <td>105</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>269</td>
            <td>898</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1023</td>
            <td>826</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">13.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>337</td>
            <td>484</td>
            <td>356</td>
            <td>0.205%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>315</td>
            <td>1278</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>912</td>
            <td>692</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">14.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>250</td>
            <td>97</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>252</td>
            <td>914</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>911</td>
            <td>709</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">15.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>282</td>
            <td>92</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>259</td>
            <td>817</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>836</td>
            <td>645</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">16.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>330</td>
            <td>94</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>217</td>
            <td>968</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>825</td>
            <td>661</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">17.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>297</td>
            <td>75</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>729</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">18.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>308</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>172</td>
            <td>775</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">19.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>169</td>
            <td>341</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>275</td>
            <td>1158</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>954</td>
            <td>743</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">20.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>316</td>
            <td>91</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>957</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>850</td>
            <td>670</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">21.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>325</td>
            <td>94</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>999</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>882</td>
            <td>692</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">22.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>332</td>
            <td>91</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>994</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>845</td>
            <td>690</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">23.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>291</td>
            <td>85</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>697</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>473</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">24.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>301</td>
            <td>66</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>660</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>439</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">25.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>312</td>
            <td>72</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>716</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>498</td>
            <td>481</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">26.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>340</td>
            <td>98</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>258</td>
            <td>1086</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>987</td>
            <td>769</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">27.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>323</td>
            <td>94</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>954</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>892</td>
            <td>706</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">28.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>328</td>
            <td>94</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>950</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>912</td>
            <td>746</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">29.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>343</td>
            <td>103</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>245</td>
            <td>1041</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>989</td>
            <td>839</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">30.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>320</td>
            <td>93</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>210</td>
            <td>966</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
          <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>913</td>
            <td>784</td>
            <td>N/A</td>
            <td>N/A</td>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
