<main class="login">
  <div class="login__container">
    <a routerLink="/" class="button-back">
      <span class="button-back__arrow"></span>
      Back
    </a>
    <div class="login__logo"><span>Papaya Developer's Portal</span></div>
    <form class="login__form" [formGroup]="registerForm" #regForm="ngForm">
      <div class="form-row">
        <label>TPP Name</label>
        <input
          type="text"
          class="regist-input"
          formControlName="tppName"
          placeholder="Enter name"
          (focus)="resetInputValidation(this.registerForm, 'tppName')"
          [ngClass]="{
            'register-form-error': showError(this.registerForm, 'tppName')
          }"
        />
        <div class="error-msg" *ngIf="showError(this.registerForm, 'tppName')">
          {{ getErrorMessage("tppName") }}
        </div>
      </div>
      <div class="form-row">
        <label>Organization reference number</label>
        <input
          type="text"
          class="regist-input"
          formControlName="orgReferenceNumber"
          placeholder="Enter value"
          (focus)="
            resetInputValidation(this.registerForm, 'orgReferenceNumber')
          "
          [ngClass]="{
            'register-form-error': showError(
              this.registerForm,
              'orgReferenceNumber'
            )
          }"
        />
        <div
          class="error-msg"
          *ngIf="showError(this.registerForm, 'orgReferenceNumber')"
        >
          {{ getErrorMessage("orgReferenceNumber") }}
        </div>
      </div>
      <div class="form-row">
        <label>Contact first name</label>
        <input
          type="text"
          class="regist-input"
          formControlName="firstName"
          placeholder="Enter first name"
          (focus)="resetInputValidation(this.registerForm, 'firstName')"
          [ngClass]="{
            'register-form-error': showError(this.registerForm, 'firstName')
          }"
        />
        <div
          class="error-msg"
          *ngIf="showError(this.registerForm, 'firstName')"
        >
          {{ getErrorMessage("firstName") }}
        </div>
      </div>
      <div class="form-row">
        <label>Contact last Name</label>
        <input
          type="text"
          class="regist-input"
          formControlName="lastName"
          placeholder="Last last name"
          (focus)="resetInputValidation(this.registerForm, 'lastName')"
          [ngClass]="{
            'register-form-error': showError(this.registerForm, 'lastName')
          }"
        />
        <div class="error-msg" *ngIf="showError(this.registerForm, 'lastName')">
          {{ getErrorMessage("lastName") }}
        </div>
      </div>
      <div class="form-row">
        <label>Email</label>
        <input
          type="text"
          class="regist-input"
          formControlName="email"
          placeholder="Enter email"
          (focus)="resetInputValidation(this.registerForm, 'email')"
          [ngClass]="{
            'register-form-error': showError(this.registerForm, 'email')
          }"
        />
        <div class="error-msg" *ngIf="showError(this.registerForm, 'email')">
          {{ getErrorMessage("email") }}
        </div>
      </div>
      <div class="form-row">
        <label>Mobile phone number</label>
        <input
          type="text"
          class="regist-input"
          formControlName="phoneNumber"
          placeholder="Enter number"
          (focus)="resetInputValidation(this.registerForm, 'phoneNumber')"
          [ngClass]="{
            'register-form-error': showError(this.registerForm, 'phoneNumber')
          }"
        />
        <div
          class="error-msg"
          *ngIf="showError(this.registerForm, 'phoneNumber')"
        >
          {{ getErrorMessage("phoneNumber") }}
        </div>
      </div>
      <div class="form-row form-row--group" formArrayName="aRoles">
        <label>Account role</label>

        <div
          *ngFor="
            let role of registerForm.controls.aRoles['controls'];
            let i = index
          "
        >
          <div class="checkbox-wrap">
            <input
              class="regist-checkbox"
              type="checkbox"
              [id]="'role' + i"
              [formControlName]="i"
              [value]="accountRoles[i].value"
            />
            <label [attr.for]="'role' + i">{{ accountRoles[i].name }}</label>
          </div>
        </div>
        <div
          class="error-msg"
          *ngIf="showAccountRoleError() && regForm.submitted"
        >
          {{ getErrorMessage("aRoles") }}
        </div>
      </div>
      <div class="form-row">
        <label>Originator country</label>
        <div class="select">
          <input
            type="text"
            class="regist-input"
            placeholder="Enter country"
            formControlName="originCountry"
            readonly
            (click)="showPopUpp()"
            [ngClass]="{
              'register-form-error': showError(
                this.registerForm,
                'originCountry'
              )
            }"
          />
          <div class="select-popup select-popup--active" *ngIf="showPopUp">
            <div class="select-popup__overlay" data-dismiss></div>
            <div class="select-popup__container">
              <h3 class="select-popup__title">Select country</h3>
              <div class="select-popup__search">
                <span class="icon select-popup__search-icon"></span>
                <input
                  type="text"
                  class="select-popup__search-input"
                  formControlName="searchBox"
                  (input)="onSearchChange($event)"
                  placeholder="Search"
                />
              </div>
              <ul class="select-popup__list">
                <li *ngFor="let country of filteredItems">
                  <a
                    class="select-popup__option"
                    id="{{ country.code }}"
                    [class.select-popup__option--active]="
                      activeCountry === country
                    "
                    (click)="setActiveCountry(country)"
                  >
                    <div class="select-popup__option-left">
                      <span class="select-popup__option-icon"
                        ><img
                          [src]="
                            'assets/img/flags/' +
                            country.code.toLowerCase() +
                            '.svg'
                          "
                          src="assets/img/flags/it.svg"
                          alt="#"
                      /></span>
                      <span class="select-popup__option-text">{{
                        country.name
                      }}</span>
                    </div>
                  </a>
                </li>
              </ul>
              <div class="select-popup__footer">
                <div class="select-popup__footer-buttons">
                  <div class="select-popup__footer-button">
                    <button
                      class="button button--outline button--gray"
                      data-dismiss
                      role="button"
                      (click)="onClickCancel()"
                    >
                      Cancel
                    </button>
                  </div>
                  <div class="select-popup__footer-button">
                    <button
                      class="button button--outline button--green button--uppercase"
                      role="button"
                      (click)="onClickOk()"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="error-msg"
          *ngIf="showError(this.registerForm, 'originCountry')"
        >
          {{ getErrorMessage("originCountry") }}
        </div>
      </div>
      <div class="form-row">
        <label>Username</label>
        <input
          type="text"
          class="regist-input"
          formControlName="username"
          placeholder="Enter username"
          (focus)="resetInputValidation(this.registerForm, 'username')"
          [ngClass]="{
            'register-form-error': showError(this.registerForm, 'username')
          }"
        />
        <div class="error-msg" *ngIf="showError(this.registerForm, 'username')">
          {{ getErrorMessage("username") }}
        </div>
      </div>
      <div class="form-row">
        <label>Password</label>
        <input
          type="password"
          class="regist-input"
          formControlName="password"
          placeholder="Enter password"
          (focus)="resetInputValidation(this.registerForm, 'password')"
          [ngClass]="{
            'register-form-error': showError(this.registerForm, 'password')
          }"
        />
        <div class="error-msg" *ngIf="showError(this.registerForm, 'password')">
          {{ getErrorMessage("password") }}
        </div>
      </div>
      <div class="form-row">
        <label>Confirm password</label>
        <input
          type="password"
          class="regist-input"
          formControlName="confirmPassword"
          placeholder="Confirm password"
          (focus)="resetInputValidation(this.registerForm, 'confirmPassword')"
          [ngClass]="{
            'register-form-error': showError(
              this.registerForm,
              'confirmPassword'
            )
          }"
        />
        <div
          class="error-msg"
          *ngIf="showError(this.registerForm, 'confirmPassword')"
        >
          {{ getErrorMessage("confirmPassword") }}
        </div>
      </div>
      <div class="form-row form-button">
        <button
          class="button button--secondary button--outline button--green"
          (click)="checkForm()"
        >
          <span class="button__icon-left">
            <svg viewBox="0 0 28 28">
              <path
                d="M16.623 18.85c-.183.2-.487.2-.676 0a.528.528 0 0 1 0-.71l3.425-3.634h-11.9c-.263 0-.472-.223-.472-.502 0-.28.21-.51.473-.51h11.9l-3.426-3.627a.537.537 0 0 1 0-.717c.19-.2.494-.2.676 0l4.235 4.495a.515.515 0 0 1 0 .71l-4.235 4.495z"
              ></path>
            </svg>
          </span>
          Register
        </button>
      </div>
    </form>
  </div>
</main>
