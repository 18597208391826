import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistic-q42023',
  templateUrl: './statistic-q42023.component.html',
  styleUrls: ['./statistic-q42023.component.scss']
})
export class StatisticQ42023Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
