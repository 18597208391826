import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "../utils/custom.validators";
import { TtpRegistrationService } from "../services/ttpRegistration.service";
import { CountryService } from "../services/countryService.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-ttp',
  templateUrl: './ttp.component.html',
  styleUrls: ['./ttp.component.scss']
})
export class TtpComponent implements OnInit {

  registerForm: FormGroup;
  mainPattern = /^[a-zA-Z0-9 ]*$/;
  emailPattern = /^([a-z\d]{1})([\w-\.!#$%&'*+\/=?\^`\{\|\}~"\(\),:;<>\[\\\]]){2,}([a-z\d]{1})@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i;
  phonePattern = /^[0-9+]*$/;
  accountRoles: any[] = [];
  countries;
  showPopUp = false
  activeCountry
  filteredItems: any[] = [];

  get originCountry(): AbstractControl {
    return this.registerForm.get('originCountry');
  }

  constructor(
    private countryService: CountryService,
    private formBuilder: FormBuilder,
    private ttpRegistrationService: TtpRegistrationService,
    private router: Router
  ) { }

  get password(): AbstractControl {
    return this.registerForm.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.registerForm.get('confirmPassword');
  }

  ngOnInit(): void {
    this.countries = this.countryService.getCountryList();
    this.filteredItems = [...this.countries];
    this.accountRoles = [
      {name: 'AISP', value: 'AISP', id: 0},
      {name: 'PISP', value: 'PISP', id: 1},
      {name: 'PIISP', value: 'PIISP', id: 2}
    ];
    this.registerForm = this.formBuilder.group({
      tppName: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(this.mainPattern)
        ]),
      orgReferenceNumber: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(this.mainPattern)
        ]
      ),
      firstName: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(this.mainPattern)
        ]
      ),
      lastName: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(this.mainPattern)
        ]
      ),
      email: new FormControl(
        '',
        [
          Validators.required,
          Validators.pattern(this.emailPattern)
        ]
      ),
      phoneNumber: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(16),
          Validators.pattern(this.phonePattern)
        ]
      ),
      aRoles: this.formBuilder.array(
        this.accountRoles.map(() => this.formBuilder.control('')),
        CustomValidators.multipleCheckboxRequireOne
      ),
      originCountry: new FormControl(
        '',
        [
          Validators.required,
        ]
      ),
      username: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(this.mainPattern)
        ]
      ),
      password: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(50),
          Validators.pattern(this.mainPattern),
          (control) => this.validatePasswords(control, 'password')

        ]
      ),
      confirmPassword: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(50),
          Validators.pattern(this.mainPattern),
          (control) => this.validatePasswords(control, 'confirmPassword')
        ]
      ),
      searchBox: new FormControl('')
    });
  }

  validatePasswords(control: AbstractControl, name: string) {
    if (this.registerForm === undefined || this.password.value === '' || this.confirmPassword.value === '') {
      return null;
    } else if (this.password.value === this.confirmPassword.value) {
      if (name === 'password' && this.confirmPassword.hasError('passwordMismatch')) {
        this.password.setErrors(null);
        this.confirmPassword.updateValueAndValidity();
      } else if (name === 'confirmPassword' && this.password.hasError('passwordMismatch')) {
        this.confirmPassword.setErrors(null);
        this.password.updateValueAndValidity();
      }
      return null;
    } else {
      return {'passwordMismatch': {value: 'The provided passwords do not match'}};
    }
  }

  getControls(formGroup: FormGroup) {
    return formGroup.controls;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  showError(formGroup: FormGroup, fieldName) {
    return this.getControls(formGroup)[fieldName].invalid && (this.getControls(formGroup)[fieldName].touched || this.getControls(formGroup)[fieldName].dirty);
  }

  showAccountRoleError() {
    return this.registerForm.controls.aRoles.errors
  }

  resetInputValidation(formGroup: FormGroup, fieldName) {
    const inputControl = this.getControls(formGroup)[fieldName];
    if (inputControl.errors && inputControl.errors.required) {
      inputControl.markAsUntouched();
      inputControl.markAsPristine();
    }
  }

  showPopUpp() {
    this.originCountry.clearValidators();
    this.originCountry.updateValueAndValidity();
    this.showPopUp = true
  }

  setActiveCountry(country) {
    this.activeCountry = country
  }

  onClickCancel() {
    this.originCountry.setValidators([Validators.required])
    this.activeCountry = null;
    this.registerForm.controls['originCountry'].setValue('')
    this.registerForm.controls['searchBox'].setValue('')
    this.registerForm.controls['originCountry'].updateValueAndValidity();
    this.filteredItems = [...this.countries];
    this.showPopUp = false;
  }

  onClickOk() {
    this.originCountry.setValidators([Validators.required])
    this.registerForm.controls['originCountry'].setValue(this.activeCountry.name)
    this.registerForm.controls['searchBox'].setValue('');
    this.filteredItems = [...this.countries];
    this.showPopUp = false;
  }

  onSearchChange(ev: any) {
    if (ev.target.value && ev.target.value.trim() != '') {
      this.filteredItems = this.countries.filter((item) => {
        return (item.alpha_3_code.toLowerCase().includes(ev.target.value.toLowerCase()) ||
          item.name && item.name.toLowerCase().includes(ev.target.value.toLowerCase()) ||
          item.nationality && item.nationality.toLowerCase().includes(ev.target.value.toLowerCase()) ||
          item.code && item.code.toLowerCase().includes(ev.target.value.toLowerCase()) ||
          item.dialCode.toString().toLowerCase().includes(ev.target.value.toLowerCase()));
      });
    } else {
      this.filteredItems = [...this.countries];
    }
  }

  checkForm() {
    if (this.registerForm.valid) {
      const roleArray = this.registerForm.get('aRoles').value;
      roleArray[0] ? roleArray[0] = 'AISP': '';
      roleArray[1] ? roleArray[1] = 'PISP': '';
      roleArray[2] ? roleArray[2] = 'PIISP': '';
      let filteredRoles = roleArray.filter(Boolean)
      this.ttpRegistrationService.register(this.registerForm, filteredRoles).subscribe((res) => {
        this.router.navigate(['/success'])
        console.log(res, 'ressss');
      }, (error => {
        console.log(error, 'errorerror');
      }))
    } else {
      this.validateAllFormFields(this.registerForm);
    }
  }

  getErrorMessage(formField) {
    switch (formField) {
      case 'tppName':
        const tppNameErrors = this.getControls(this.registerForm).tppName.errors;
        if (tppNameErrors.required) {
          return 'TPP Name is required';
        } else if (tppNameErrors.maxlength) {
          return 'Value must be a maximum of 50 characters';
        } else if (tppNameErrors.minlength) {
          return 'Value must be a minimum of 1 characters'
        } else {
          return 'Allowed characters: A-Z a-z 0-9';
        }
      case 'orgReferenceNumber':
        const orgReferenceNumberErrors = this.getControls(this.registerForm).orgReferenceNumber.errors;
        if (orgReferenceNumberErrors.required) {
          return 'Organization reference number is required';
        } else if (orgReferenceNumberErrors.maxlength) {
          return 'Value must be a maximum of 50 characters';
        } else if (orgReferenceNumberErrors.minlength) {
          return 'Value must be a minimum of 1 characters'
        } else {
          return 'Allowed characters: A-Z a-z 0-9';
        }
      case 'firstName':
        const firstNameErrors = this.getControls(this.registerForm).firstName.errors;
        if (firstNameErrors.required) {
          return 'Contact first name is required';
        } else if (firstNameErrors.maxlength) {
          return 'Value must be a maximum of 50 characters';
        } else if (firstNameErrors.minlength) {
          return 'Value must be a minimum of 1 characters'
        } else {
          return 'Allowed characters: A-Z a-z 0-9';
        }
      case 'lastName':
        const lastNameErrors = this.getControls(this.registerForm).lastName.errors;
        if (lastNameErrors.required) {
          return 'Contact last Name is required';
        } else if (lastNameErrors.maxlength) {
          return 'Value must be a maximum of 50 characters';
        } else if (lastNameErrors.minlength) {
          return 'Value must be a minimum of 1 characters'
        } else {
          return 'Allowed characters: A-Z a-z 0-9';
        }
      case 'email':
        const emailErrors = this.getControls(this.registerForm).email.errors;
        if (emailErrors.required) {
          return 'Email is required';
        } else {
          return 'Incorrect format';
        }
      case 'phoneNumber':
        const phoneNumberErrors = this.getControls(this.registerForm).phoneNumber.errors;
        if (phoneNumberErrors.required) {
          return 'Mobile phone number is required';
        } else if (phoneNumberErrors.maxlength) {
          return 'Value must be a maximum of 16 characters';
        } else if (phoneNumberErrors.minlength) {
          return 'Value must be a minimum of 3 characters'
        } else {
          return 'Allowed characters: 0-9'
        }
      case 'aRoles':
        const aRolesErrors = this.getControls(this.registerForm).aRoles.errors;
        if (aRolesErrors.multipleCheckboxRequireOne) {
          return 'Account role is required';
        }
        break;
      case 'originCountry':
        const originCountryErrors = this.getControls(this.registerForm).originCountry.errors;
        if (originCountryErrors.required) {
          return 'Originator country is required';
        }
        break;
      case 'username':
        const usernameErrors = this.getControls(this.registerForm).username.errors;
        if (usernameErrors.required) {
          return 'Username is required';
        } else if (usernameErrors.maxlength) {
          return 'Value must be a maximum of 50 characters';
        } else if (usernameErrors.minlength) {
          return 'Value must be a minimum of 1 characters'
        } else {
          return 'Allowed characters: A-Z a-z 0-9';
        }
      case 'password':
        const passwordErrors = this.getControls(this.registerForm).password.errors;
        if (passwordErrors.required) {
          return 'Password is required';
        } else if (passwordErrors.maxlength) {
          return 'Value must be a maximum of 50 characters';
        } else if (passwordErrors.minlength) {
          return 'Value must be a minimum of 6 characters'
        } else if (passwordErrors.pattern) {
          return 'Allowed characters: A-Z a-z 0-9';
        } else {
          return 'The provided passwords do not match'
        }
      case 'confirmPassword':
        const confirmPasswordErrors = this.getControls(this.registerForm).confirmPassword.errors;
        if (confirmPasswordErrors.required) {
          return 'Confirm password is required';
        } else if (confirmPasswordErrors.maxlength) {
          return 'Value must be a maximum of 50 characters';
        } else if (confirmPasswordErrors.minlength) {
          return 'Value must be a minimum of 6 characters'
        } else if (confirmPasswordErrors.pattern) {
          return 'Allowed characters: A-Z a-z 0-9';
        } else {
          return 'The provided passwords do not match';
        }
    }
  }

}
