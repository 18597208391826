import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, ViewChild} from '@angular/core';
import { AnimationService } from '../animation.service'
import { TimelineMax } from 'gsap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {


  constructor(private animationService: AnimationService) { }
  reenableButton = new EventEmitter<boolean>();
  public currentActive = 0;
  public partnersOffset: Number = null;
  public servicesOffset: Number = null;
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // this.partnersOffset = document.getElementById('partners').offsetTop;
    // this.servicesOffset =  document.getElementById('services').offsetTop;
  }

  mobileMenu() {
    const header = document.querySelector('.header');
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    const buttonOpen = document.querySelector('.nav-toggle');
    const nav = document.querySelector('.main-nav');

    body.classList.toggle('open-menu');
    buttonOpen.classList.toggle('nav-toggle_active');
    nav.classList.toggle('open');

    if (!header.classList.contains('header_sticky')) {
      if (body.classList.contains('open-menu')) {
        html.classList.add('is-noscrolling');
      } else {
        html.classList.remove('is-noscrolling');
      }
    }
  }

  closeMenu(types?) {
    this.reenableButton.emit();
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    const buttonOpen = document.querySelector('.nav-toggle');
    const nav = document.querySelector('.main-nav');
    const tl = new TimelineMax();

    html.classList.remove('is-noscrolling');
    body.classList.remove('open-menu');
    buttonOpen.classList.remove('nav-toggle_active');
    nav.classList.remove('open');

    if (types && types.includes('partners')) {
      window.location.href ='home#partners';
      this.currentActive = 1;
        this.animationService.animatePartners(tl);
    }

    if (types && types.includes('services')) {
      this.currentActive = 2;
      this.animationService.animateServices(false);
      window.location.href ='home#services';
    }

    if (types && types.includes('titles')) {
      const contentText = document.querySelectorAll('.js-fade-up');
      const contentItems = document.querySelectorAll('.js-fade-up-block');

      const tweenAnimateContent = element => {
        const tl = new TimelineMax();
        this.animationService.animateFadeUp(tl, element);
      }

      contentText.forEach(tweenAnimateContent);

      if (window.innerWidth < 768) {
        contentItems.forEach(tweenAnimateContent);
      }
    }
  }
  log() {

  }

  @HostListener('window:scroll', ['$event'])
  checkOffsetTop() {
    // if (window.pageYOffset >= this.partnersOffset && window.pageYOffset < this.servicesOffset) {
    //   this.currentActive = 1;
    //   return;
    // }
    //  console.log(window.pageYOffset, this.servicesOffset,window.pageYOffset, 'window.pageYOffset', this.partnersOffset,  'this.partnersOffse')
    //   if (window.pageYOffset + 1700 >= this.servicesOffset && window.pageYOffset > this.partnersOffset) {
    //   this.currentActive = 2;
    //   return;
    // }
    //   this.currentActive = 0;
  }

  scrollToElement(element) {
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}
