<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">eIDAS certificate</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <p>In the future, registration and further work with TPP will be based on eIDAS specification certificates. These certificates will have been granted to the third party by a QTSP (Quality Trust Service Provider).</p>
          <h4>QWAC</h4>
          <p>Qualified Web Authentication Certificate. This certificate is used when a third party firsts opens an interaction with a financial institution and is used to es-tablish a TLS session with the ASPSP. The QWAC replaces the usual web cer-tificate that would be used to establish a TLS session.<br> The purpose of this certificate then is to identify the third party and to ensurethat messages sent between the two parties are not read or stolen. A certifi-cate used to establish a TLS session sits in the transport layer and is vali-dated by the Web browser.</p>
          <h4>QsealC</h4>
          <p>This Qualified Electronic Seal Certificate is sent together with messages that a third party would send to a ASPSP during one of the consent processes. The purpose of this certificate is to validate the identity of the sender of the message (i.e. the TPP) and to ensure that the contents of the message have not been tampered with while it was in transit.</p>
          <h4>Certificate contains:</h4>
          <p>Authorization number that tells us which NCA (National Competent Authority) register the TPP is one and what unique identification number can use to find the TPP on the register.<br> Each authorization number contains the following elements:</p>
          <p>·Country code (e.g. MT, EE) representing the home EEA (European Eco-nomic Area) country that the TPP is regulated in NCA·Acronym representing the NCA that regulates this TPP<br> ·Reference Number – a number that can be used to find the third party on the specified NCA register</p>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
