<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">Open Banking Documentation
        </h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <h4>Welcome to Papaya API documentation</h4>
          <p>
            <strong>Welcome</strong> to Papaya’s API Sandbox, a place for Third Party Payment Service Providers (TPP) to try and test our PSD2 Open APIs. Here you will find necessary documentation, instructions and code samples for making API calls. The implemented services fall under core services and are not covering all the requirements at this time. Papaya is following the Berlin Group API specification and where it is needed extensions are made to cover the full set of requirements needed for the service. The sandbox offers a safe and simple way to test the Financial Institution’s APIs. Developers can get to know themselves with existing and upcoming functionality.
          </p>
          <p>We implemented NextGenPSD2 API based on the JSON data format for payment initiation requests and account information services. All APIs consumes JSON so requests sent to it should have the request body in JSON format.</p>
        </section>
      </div>
      <div class="section__content-right">
        <ul class="list-styled">
          <li><span class="align-middle">* </span> <a routerLink="/openbanking-terms-and-conditions">Terms and Conditions</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/getting-started-guide">Getting Started Guide</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/eidas-certificate">eIDAS certificate</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/production-environment">Production environment</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/general-information-about-the-sandbox">General Information about the Sandbox</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/availability">Availability</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/sca-strong-customer-authentication-methods">SCA (strong customer authentication)</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/security">Security</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/about-api">About API</a></li>
        </ul>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
