<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
            <tr>
                <th>Date</th>
                <th>Channel</th>
                <th>Uptime, <br /> %</th>
                <th>Downtime, <br /> %</th>
                <th>AIS <br /> performance, <br /> ms</th>
                <th>PIS <br /> performance, <br /> ms</th>
                <th>CoF <br /> performance, <br /> ms</th>
                <th>Error response <br /> rate, <br /> %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 01</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>99.83%</td>
                <td>0.17%</td>
                <td>61</td>
                <td>106</td>
                <td>64</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>172</td>
                <td>484</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>862</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 02</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>101</td>
                <td>58</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>470</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>761</td>
                <td>371</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 03</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>49</td>
                <td>78</td>
                <td>49</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>289</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>496</td>
                <td>278</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 04</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>76</td>
                <td>46</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>221</td>
                <td>309</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>553</td>
                <td>304</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 05</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>63</td>
                <td>97</td>
                <td>64</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>184</td>
                <td>607</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>849</td>
                <td>460</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 06</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>108</td>
                <td>54</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>467</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>769</td>
                <td>399</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 07</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>105</td>
                <td>50</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>227</td>
                <td>354</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>764</td>
                <td>388</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 08</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>104</td>
                <td>59</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1133</td>
                <td>507</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>830</td>
                <td>409</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 09</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>65</td>
                <td>114</td>
                <td>62</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.88%</td>
                <td>0.12%</td>
                <td>144</td>
                <td>1063</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>798</td>
                <td>443</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 10</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>98.62%</td>
                <td>1.38%</td>
                <td>57</td>
                <td>97</td>
                <td>57</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>97.59%</td>
                <td>2.41%</td>
                <td>181</td>
                <td>338</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>98.06%</td>
                <td>1.94%</td>
                <td>576</td>
                <td>335</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 11</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>91</td>
                <td>50</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>210</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>540</td>
                <td>308</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 12</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>62</td>
                <td>86</td>
                <td>60</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>175</td>
                <td>587</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>863</td>
                <td>421</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 13</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>97</td>
                <td>58</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>178</td>
                <td>469</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>96.59%</td>
                <td>3.41%</td>
                <td>795</td>
                <td>387</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 14</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>107</td>
                <td>57</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>273</td>
                <td>478</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>793</td>
                <td>400</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 15</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>64</td>
                <td>83</td>
                <td>63</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>183</td>
                <td>573</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>858</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 16</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>110</td>
                <td>57</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>127</td>
                <td>487</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>797</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 17</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>97</td>
                <td>53</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>303</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>516</td>
                <td>293</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 18</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>102</td>
                <td>53</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>192</td>
                <td>377</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>571</td>
                <td>319</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 19</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>62</td>
                <td>108</td>
                <td>59</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>177</td>
                <td>560</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>863</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 20</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>95</td>
                <td>58</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>232</td>
                <td>499</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.22%</td>
                <td>0.78%</td>
                <td>881</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 21</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>87</td>
                <td>52</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>171</td>
                <td>725</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>799</td>
                <td>391</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 22</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>83</td>
                <td>57</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>170</td>
                <td>442</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>770</td>
                <td>386</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 23</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>81</td>
                <td>52</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>93.93%</td>
                <td>6.07%</td>
                <td>162</td>
                <td>431</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>774</td>
                <td>376</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 24</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>77</td>
                <td>45</td>
                <td>0.013%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>117</td>
                <td>286</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>504</td>
                <td>285</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 25</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>72</td>
                <td>48</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>334</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>519</td>
                <td>291</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 26</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>98.69%</td>
                <td>1.31%</td>
                <td>60</td>
                <td>89</td>
                <td>61</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>98.26%</td>
                <td>1.74%</td>
                <td>162</td>
                <td>511</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>98.34%</td>
                <td>1.66%</td>
                <td>841</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 27</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>97</td>
                <td>53</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>117</td>
                <td>415</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>789</td>
                <td>377</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 28</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>93</td>
                <td>54</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>403</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>789</td>
                <td>372</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 29</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>100</td>
                <td>50</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>181</td>
                <td>397</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>748</td>
                <td>349</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 30</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>70</td>
                <td>51</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>702</td>
                <td>337</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 31</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>92</td>
                <td>46</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>277</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>488</td>
                <td>259</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 01</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>86</td>
                <td>74</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>158</td>
                <td>372</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>401</td>
                <td>297</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 02</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>90</td>
                <td>43</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.21%</td>
                <td>0.79%</td>
                <td>150</td>
                <td>449</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>476</td>
                <td>317</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 03</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>105</td>
                <td>54</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>315</td>
                <td>508</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>776</td>
                <td>435</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 04</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>62</td>
                <td>96</td>
                <td>56</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>410</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>682</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 05</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>98.62%</td>
                <td>1.38%</td>
                <td>56</td>
                <td>102</td>
                <td>56</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>165</td>
                <td>455</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>681</td>
                <td>431</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 06</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>107</td>
                <td>60</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>181</td>
                <td>529</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>662</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 07</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>51</td>
                <td>101</td>
                <td>46</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>286</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>374</td>
                <td>289</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 08</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>106</td>
                <td>50</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>306</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>397</td>
                <td>300</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 09</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>110</td>
                <td>58</td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>187</td>
                <td>582</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>748</td>
                <td>462</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 10</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>98</td>
                <td>55</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>177</td>
                <td>520</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>702</td>
                <td>453</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 11</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>99</td>
                <td>55</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>98.64%</td>
                <td>1.36%</td>
                <td>154</td>
                <td>465</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>694</td>
                <td>427</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 12</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>97</td>
                <td>57</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>428</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>687</td>
                <td>423</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 13</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>103</td>
                <td>52</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>150</td>
                <td>439</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>717</td>
                <td>437</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 14</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>83</td>
                <td>45</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.03%</td>
                <td>0.97%</td>
                <td>108</td>
                <td>290</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>98.15%</td>
                <td>1.85%</td>
                <td>393</td>
                <td>296</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 15</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>77</td>
                <td>48</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>338</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>415</td>
                <td>311</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 16</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>61</td>
                <td>59</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>170</td>
                <td>521</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>776</td>
                <td>466</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 17</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>98</td>
                <td>52</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>385</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>683</td>
                <td>412</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 18</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>98</td>
                <td>49</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>362</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>668</td>
                <td>402</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 19</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>99</td>
                <td>53</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>137</td>
                <td>390</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>686</td>
                <td>411</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 20</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>70</td>
                <td>54</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>411</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>683</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 21</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>83.34%</td>
                <td>16.66%</td>
                <td>49</td>
                <td>97</td>
                <td>42</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>83.34%</td>
                <td>16.66%</td>
                <td>94</td>
                <td>248</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>83.34%</td>
                <td>16.66%</td>
                <td>348</td>
                <td>254</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 22</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>45</td>
                <td>89</td>
                <td>41</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>345</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>363</td>
                <td>265</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 23</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>103</td>
                <td>52</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>455</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>718</td>
                <td>407</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 24</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>98.86%</td>
                <td>1.14%</td>
                <td>51</td>
                <td>104</td>
                <td>50</td>
                <td>0.015%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>125</td>
                <td>379</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>98.68%</td>
                <td>1.32%</td>
                <td>658</td>
                <td>382</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 25</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>102</td>
                <td>48</td>
                <td>0.010%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>397</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>679</td>
                <td>392</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 26</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>51</td>
                <td>103</td>
                <td>49</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>127</td>
                <td>379</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.26%</td>
                <td>0.74%</td>
                <td>642</td>
                <td>375</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 27</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>51</td>
                <td>101</td>
                <td>50</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>394</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>662</td>
                <td>393</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 28</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>82</td>
                <td>42</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>99</td>
                <td>266</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>363</td>
                <td>266</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 29</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>46</td>
                <td>83</td>
                <td>41</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>275</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>381</td>
                <td>271</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 30</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>99</td>
                <td>51</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>519</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>782</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 01</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>111</td>
                <td>49</td>
                <td>0.020%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>142</td>
                <td>423</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>805</td>
                <td>295</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 02</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>101</td>
                <td>50</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>418</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.94%</td>
                <td>0.06%</td>
                <td>775</td>
                <td>285</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 03</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>113</td>
                <td>51</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>132</td>
                <td>399</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>792</td>
                <td>294</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 04</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>103</td>
                <td>40</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>436</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>814</td>
                <td>297</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 05</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>101</td>
                <td>45</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>96</td>
                <td>263</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>604</td>
                <td>225</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 06</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>109</td>
                <td>55</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>289</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>630</td>
                <td>234</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 07</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>111</td>
                <td>52</td>
                <td>0.011%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>166</td>
                <td>513</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>961</td>
                <td>340</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 08</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>113</td>
                <td>54</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>851</td>
                <td>298</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 09</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>117</td>
                <td>56</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>853</td>
                <td>299</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 10</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>118</td>
                <td>55</td>
                <td>0.014%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>480</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>910</td>
                <td>313</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 11</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>106</td>
                <td>45</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>448</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>843</td>
                <td>305</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 12</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>79.17%</td>
                <td>20.83%</td>
                <td>50</td>
                <td>101</td>
                <td>44</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>79.17%</td>
                <td>20.83%</td>
                <td>100</td>
                <td>273</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>79.17%</td>
                <td>20.83%</td>
                <td>650</td>
                <td>239</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 13</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>119</td>
                <td>52</td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>397</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>623</td>
                <td>231</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 14</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>62</td>
                <td>121</td>
                <td>58</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>169</td>
                <td>531</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.72%</td>
                <td>0.28%</td>
                <td>953</td>
                <td>315</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 15</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>109</td>
                <td>51</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>174</td>
                <td>481</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.86%</td>
                <td>0.14%</td>
                <td>961</td>
                <td>328</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 16</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>114</td>
                <td>49</td>
                <td>0.030%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>429</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>837</td>
                <td>297</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 17</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>112</td>
                <td>50</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>98.78%</td>
                <td>1.22%</td>
                <td>138</td>
                <td>416</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.65%</td>
                <td>0.35%</td>
                <td>859</td>
                <td>295</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 18</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>105</td>
                <td>44</td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>435</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>877</td>
                <td>305</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 19</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>108</td>
                <td>45</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>98</td>
                <td>265</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>623</td>
                <td>231</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 20</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>125</td>
                <td>56</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>118</td>
                <td>305</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.94%</td>
                <td>0.06%</td>
                <td>666</td>
                <td>240</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 21</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>128</td>
                <td>53</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>517</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1021</td>
                <td>331</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 22</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>118</td>
                <td>49</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>444</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>970</td>
                <td>328</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 23</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>107</td>
                <td>41</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>437</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>871</td>
                <td>306</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 24</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>100</td>
                <td>41</td>
                <td>0.028%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>91</td>
                <td>253</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>643</td>
                <td>228</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 25</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>100</td>
                <td>43</td>
                <td>0.013%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>90</td>
                <td>243</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>629</td>
                <td>228</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 26</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>108</td>
                <td>48</td>
                <td>0.024%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>93</td>
                <td>247</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>671</td>
                <td>233</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 27</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>121</td>
                <td>55</td>
                <td>0.010%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>709</td>
                <td>253</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 28</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>117</td>
                <td>52</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>178</td>
                <td>547</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>960</td>
                <td>334</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 29</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>119</td>
                <td>53</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>498</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>885</td>
                <td>315</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 30</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>120</td>
                <td>50</td>
                <td>0.011%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>422</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>880</td>
                <td>316</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 31</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>105</td>
                <td>35</td>
                <td>0.019%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>142</td>
                <td>266</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>690</td>
                <td>256</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

