<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
            <tr>
                <th>Date</th>
                <th>Channel</th>
                <th>Uptime, <br /> %</th>
                <th>Downtime, <br /> %</th>
                <th>AIS <br /> performance, <br /> ms</th>
                <th>PIS <br /> performance, <br /> ms</th>
                <th>CoF <br /> performance, <br /> ms</th>
                <th>Error response <br /> rate, <br /> %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td rowspan="3" class="text-nowrap">01.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>287</td>
                <td>69</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>119</td>
                <td>681</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>498</td>
                <td>329</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">02.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>118</td>
                <td>313</td>
                <td>75</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>154</td>
                <td>765</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>563</td>
                <td>373</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">03.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>333</td>
                <td>97</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>230</td>
                <td>1048</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>804</td>
                <td>518</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">04.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>330</td>
                <td>91</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>200</td>
                <td>928</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>711</td>
                <td>473</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">05.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>336</td>
                <td>96</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>216</td>
                <td>992</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>720</td>
                <td>515</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">06.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>134</td>
                <td>319</td>
                <td>91</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>200</td>
                <td>935</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>671</td>
                <td>444</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">07.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>338</td>
                <td>99</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>245</td>
                <td>1091</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>698</td>
                <td>482</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">08.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>298</td>
                <td>73</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>129</td>
                <td>721</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>513</td>
                <td>386</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">09.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>306</td>
                <td>76</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>756</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>565</td>
                <td>436</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>180</td>
                <td>332</td>
                <td>119</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>309</td>
                <td>1156</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>920</td>
                <td>635</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>260</td>
                <td>95</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>213</td>
                <td>746</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>709</td>
                <td>450</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>257</td>
                <td>96</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>224</td>
                <td>777</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>703</td>
                <td>434</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">13.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>264</td>
                <td>95</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>206</td>
                <td>712</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>694</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">14.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>269</td>
                <td>97</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>212</td>
                <td>753</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>699</td>
                <td>444</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">15.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>233</td>
                <td>75</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>135</td>
                <td>524</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>501</td>
                <td>323</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">16.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>115</td>
                <td>244</td>
                <td>74</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>531</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>534</td>
                <td>359</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">17.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>293</td>
                <td>108</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>251</td>
                <td>933</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>799</td>
                <td>500</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">18.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>233</td>
                <td>85</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>200</td>
                <td>709</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>708</td>
                <td>435</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">19.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>232</td>
                <td>85</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>208</td>
                <td>698</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>695</td>
                <td>430</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">20.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>131</td>
                <td>248</td>
                <td>88</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>220</td>
                <td>737</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>703</td>
                <td>448</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">21.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>129</td>
                <td>254</td>
                <td>80</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>204</td>
                <td>706</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>698</td>
                <td>430</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">22.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>110</td>
                <td>226</td>
                <td>71</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>495</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>498</td>
                <td>331</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">23.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>110</td>
                <td>225</td>
                <td>72</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>508</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>532</td>
                <td>367</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">24.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>253</td>
                <td>100</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>251</td>
                <td>868</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>786</td>
                <td>495</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">25.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>219</td>
                <td>89</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>222</td>
                <td>758</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>709</td>
                <td>435</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">26.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>132</td>
                <td>221</td>
                <td>87</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>212</td>
                <td>717</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>702</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">27.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>214</td>
                <td>86</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>207</td>
                <td>700</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>692</td>
                <td>421</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">28.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>129</td>
                <td>228</td>
                <td>91</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>206</td>
                <td>699</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>688</td>
                <td>429</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">29.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>106</td>
                <td>196</td>
                <td>70</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>126</td>
                <td>478</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>499</td>
                <td>332</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">30.04.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>113</td>
                <td>208</td>
                <td>73</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>150</td>
                <td>506</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>531</td>
                <td>365</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">01.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>242</td>
                <td>98</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>265</td>
                <td>905</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>791</td>
                <td>500</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">02.05.2022</td>
                <td>Open Banking API</td>
                <td>94.00%</td>
                <td>6.00%</td>
                <td>161</td>
                <td>254</td>
                <td>91</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>247</td>
                <td>858</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>770</td>
                <td>478</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">03.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>129</td>
                <td>207</td>
                <td>85</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>214</td>
                <td>725</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>707</td>
                <td>450</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">04.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>125</td>
                <td>207</td>
                <td>83</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>235</td>
                <td>785</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>704</td>
                <td>447</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">05.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>226</td>
                <td>90</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>228</td>
                <td>751</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>681</td>
                <td>454</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">06.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>194</td>
                <td>69</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>131</td>
                <td>495</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>498</td>
                <td>344</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">07.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>107</td>
                <td>201</td>
                <td>71</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>506</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>546</td>
                <td>389</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">08.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>242</td>
                <td>96</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>263</td>
                <td>868</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>798</td>
                <td>528</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">09.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>187</td>
                <td>277</td>
                <td>125</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>252</td>
                <td>824</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>727</td>
                <td>462</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>283</td>
                <td>125</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>328</td>
                <td>918</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>712</td>
                <td>454</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>215</td>
                <td>321</td>
                <td>167</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>292</td>
                <td>944</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>730</td>
                <td>476</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>257</td>
                <td>114</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>246</td>
                <td>834</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>716</td>
                <td>467</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">13.05.2022</td>
                <td>Open Banking API</td>
                <td>80.00%</td>
                <td>20.00%</td>
                <td>117</td>
                <td>203</td>
                <td>76</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>80.00%</td>
                <td>20.00%</td>
                <td>138</td>
                <td>553</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>80.00%</td>
                <td>0.00%</td>
                <td>511</td>
                <td>335</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">14.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>200</td>
                <td>73</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>166</td>
                <td>500</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>535</td>
                <td>365</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">15.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>154</td>
                <td>230</td>
                <td>101</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>252</td>
                <td>823</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>775</td>
                <td>498</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">16.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>151</td>
                <td>226</td>
                <td>100</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>251</td>
                <td>820</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>742</td>
                <td>483</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">17.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>124</td>
                <td>209</td>
                <td>81</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>169</td>
                <td>606</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>723</td>
                <td>491</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">18.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>268</td>
                <td>113</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>221</td>
                <td>772</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>749</td>
                <td>500</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">19.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>215</td>
                <td>88</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>220</td>
                <td>755</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>720</td>
                <td>448</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">20.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>197</td>
                <td>71</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>492</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>531</td>
                <td>371</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">21.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>110</td>
                <td>201</td>
                <td>75</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>516</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>585</td>
                <td>421</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">22.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>153</td>
                <td>230</td>
                <td>109</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>280</td>
                <td>886</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>843</td>
                <td>561</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">23.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>200</td>
                <td>87</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>227</td>
                <td>727</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>718</td>
                <td>438</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">24.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>209</td>
                <td>93</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>215</td>
                <td>708</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>684</td>
                <td>427</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">25.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>120</td>
                <td>189</td>
                <td>82</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>204</td>
                <td>648</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>529</td>
                <td>358</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">26.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>131</td>
                <td>210</td>
                <td>95</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>231</td>
                <td>734</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>717</td>
                <td>465</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">27.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>179</td>
                <td>73</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>486</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>526</td>
                <td>389</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">28.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>110</td>
                <td>180</td>
                <td>74</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>501</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>564</td>
                <td>419</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">29.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>150</td>
                <td>216</td>
                <td>109</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>300</td>
                <td>916</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>829</td>
                <td>517</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">30.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>206</td>
                <td>93</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>245</td>
                <td>787</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>775</td>
                <td>483</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">31.05.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>198</td>
                <td>94</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>238</td>
                <td>776</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>696</td>
                <td>405</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">01.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>201</td>
                <td>95</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>263</td>
                <td>836</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>657</td>
                <td>395</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">02.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>225</td>
                <td>135</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>263</td>
                <td>797</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>654</td>
                <td>410</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">03.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>186</td>
                <td>76</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>510</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>462</td>
                <td>295</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">04.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>117</td>
                <td>190</td>
                <td>77</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>181</td>
                <td>549</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>528</td>
                <td>336</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">05.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>190</td>
                <td>262</td>
                <td>135</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>338</td>
                <td>1001</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>752</td>
                <td>468</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">06.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>241</td>
                <td>111</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>262</td>
                <td>810</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>657</td>
                <td>396</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">07.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>163</td>
                <td>242</td>
                <td>112</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>286</td>
                <td>856</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>680</td>
                <td>400</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">08.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>185</td>
                <td>268</td>
                <td>133</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>346</td>
                <td>965</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>700</td>
                <td>426</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">09.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>119</td>
                <td>193</td>
                <td>80</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>181</td>
                <td>624</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>644</td>
                <td>380</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>107</td>
                <td>185</td>
                <td>71</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>132</td>
                <td>496</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>470</td>
                <td>291</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>190</td>
                <td>76</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>173</td>
                <td>524</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>494</td>
                <td>306</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>219</td>
                <td>117</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>297</td>
                <td>918</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>761</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">13.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>192</td>
                <td>124</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>331</td>
                <td>884</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>692</td>
                <td>395</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">14.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>107</td>
                <td>184</td>
                <td>87</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>247</td>
                <td>729</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>667</td>
                <td>382</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">15.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>109</td>
                <td>181</td>
                <td>78</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>235</td>
                <td>676</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>664</td>
                <td>377</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">16.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>106</td>
                <td>183</td>
                <td>79</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>350</td>
                <td>724</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>664</td>
                <td>382</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">17.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>91</td>
                <td>169</td>
                <td>66</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>237</td>
                <td>466</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>479</td>
                <td>296</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">18.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>95</td>
                <td>178</td>
                <td>69</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>258</td>
                <td>520</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>510</td>
                <td>312</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">19.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>113</td>
                <td>185</td>
                <td>83</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>346</td>
                <td>841</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>741</td>
                <td>426</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">20.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>106</td>
                <td>180</td>
                <td>78</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>240</td>
                <td>714</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>688</td>
                <td>391</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">21.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>181</td>
                <td>78</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>228</td>
                <td>693</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>685</td>
                <td>387</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">22.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>179</td>
                <td>77</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>227</td>
                <td>663</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>670</td>
                <td>377</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">23.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>180</td>
                <td>77</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>241</td>
                <td>673</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>661</td>
                <td>372</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">24.06.2022</td>
                <td>Open Banking API</td>
                <td>82.00%</td>
                <td>18.00%</td>
                <td>89</td>
                <td>173</td>
                <td>65</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>82.00%</td>
                <td>18.00%</td>
                <td>208</td>
                <td>735</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>82.00%</td>
                <td>8.00%</td>
                <td>648</td>
                <td>370</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">25.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>93</td>
                <td>176</td>
                <td>68</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>165</td>
                <td>491</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>485</td>
                <td>308</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">26.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>113</td>
                <td>187</td>
                <td>78</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>288</td>
                <td>817</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>712</td>
                <td>417</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">27.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>103</td>
                <td>180</td>
                <td>74</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>234</td>
                <td>678</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>647</td>
                <td>378</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">28.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>178</td>
                <td>75</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>243</td>
                <td>695</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>642</td>
                <td>386</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">29.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>102</td>
                <td>179</td>
                <td>76</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>259</td>
                <td>739</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>671</td>
                <td>399</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">30.06.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>107</td>
                <td>184</td>
                <td>78</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>247</td>
                <td>729</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>667</td>
                <td>382</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

