import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";

export const getUTMParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get("utm_source") || "";
  const medium = urlParams.get("utm_medium") || "";
  const campaign = urlParams.get("utm_campaign") || "";

  return [source, medium, campaign].filter(Boolean).join("&");
};

@Component({
  selector: "app-complaints",
  templateUrl: "./complaints.component.html",
  styleUrls: ["./complaints.component.scss"],
})
export class ComplaintsComponent implements OnInit {
  isSubmitted: boolean = false;
  isSuccess: boolean = false;
  mailForm: FormGroup;
  constructor(private router: Router) {}

  isError(controlName: string) {
    if (!this.isSubmitted) {
      return false;
    }

    return this.mailForm.get(controlName).invalid;
  }

  files: FileList;

  onAttachmentsChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.files = files;
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.mailForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      mobilePhoneNumber: new FormControl(null, [Validators.required]),
      complaintSubject: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      complaintSummary: new FormControl(null, [
        Validators.required,
        Validators.maxLength(200),
      ]),
      relevantDateOfComplaintEvent: new FormControl(null, []),
      attachments: new FormControl(null, []),
      agreement: new FormControl(null, [Validators.requiredTrue]),
    });
  }

  submit(event: Event) {
    this.isSubmitted = true;

    event.preventDefault();

    if (this.mailForm.status !== "VALID") {
      return;
    }

    window.scrollTo(0, 0);

    const payload = {
      channel_name: getUTMParams(),
      date: new Date().toISOString().split("T")[0],
      form_id: 3000,
      form_name: "complaint",
      uuid: "",
      params: {
        name: this.mailForm.get("name").value,
        email: this.mailForm.get("email").value,
        mobilePhoneNumber: this.mailForm.get("mobilePhoneNumber").value,
        complaintSubject: this.mailForm.get("complaintSubject").value,
        complaintSummary: this.mailForm.get("complaintSummary").value,
        relevantDateOfComplaintEvent: this.mailForm.get(
          "relevantDateOfComplaintEvent"
        ).value
          ? new Date(this.mailForm.get("relevantDateOfComplaintEvent").value)
              .toISOString()
              .split("T")[0]
          : null,
      },
    };

    const formData = new FormData();
    formData.append(
      "review",
      new Blob([JSON.stringify(payload)], { type: "application/json" })
    );

    if (this.files) {
      for (let i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i], this.files[i].name);
      }
    }

    fetch("https://marketing.blackcatcard.com/reviews/v2", {
      method: "POST",
      body: formData,
    });

    this.files = undefined;

    Object.values(this.mailForm.controls).forEach((control) => {
      control.setValue("");
      control.setErrors(null);
    });

    this.isSuccess = true;
  }
}
