<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">About API</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <h4>API</h4>
          <p>We aim to provide experiences that exceed expectations. We have built our open API around the Representational State Transfer Service architecture (REST), a standard HTTP and easy-to-read and write JavaScript Object Notation (JSON). We follow the Berlin Group API specifications and the Implementation guide and also extended the associated documentation to meet the regulatory requirements.</p>

          <h4>API HTTP Methods</h4>
          <ul class="list-styled">
            <li>All services are accessed through APIs using REST based HTTPS methods:</li>
            <li>GET: Reads a resource and returns it;</li>
            <li>POST: Creates a new resource;</li>
            <li>PUT: Requests that the entity provided is stored in the URI provided;</li>
            <li>DELETE: Deletes the resource identified by URI.</li>
          </ul>

          <h4>API availability status</h4>
          <p class="my-2">The API availability status is used to inform TPP developers regarding API<br>(feature) lifecycle. The availability status is defined for API operations, parameters, models, and properties</p>

          <div class="table-wrapper">
            <table class="table-border table-striped table-gray">
              <colgroup>
                <col width="10%">
                <col width="90%">
              </colgroup>
              <tr>
                <th>Status</th>
                <th>This status is used for the API (feature) that is implemented but is still subject to change. This API can be used by developers in Sandbox and Production, but Bank`s is not obliged to guarantee that significant changes to this API will not be introduced in future. The features the API in Beta status might also be completely removed in future releases. The Beta API’s changes first appear in the Sandbox environment and later in the Production environment. This way TPP developers will have time to adapt to the upcoming changes in the Production API.</th>
              </tr>
              <tr>
                <td>Beta</td>
                <td>The draft status is used for APIs which are still in design phase.</td>
              </tr>
              <tr>
                <td>Draft</td>
                <td>The APIs in Production status are in use in Production environment and should be used in Production applications. No significant changes will be made to the stable APIs (features).</td>
              </tr>
              <tr>
                <td>Production</td>
                <td>The APIs in Production status are in use in Production environment and should be used in Production applications. No significant changes will be made to the stable APIs (features).</td>
              </tr>
              <tr>
                <td>Sandbox Only</td>
                <td>The APIs with Sandbox only status are only available in the Sandbox environment and will not be available in the Production environment.</td>
              </tr>
              <tr>
                <td>Deprecated</td>
                <td>The APIs with Deprecated status will be removed in the future. Deprecated APIs will continue to be supported by the Papaya’s developer’s portal for six months.</td>
              </tr>
            </table>
          </div>
          <h4 class="my-2">API in Swagger format</h4>
          <p>The Bank API specification is available in the swagger format. More information about it can be found here.  The swagger files for this API can be downloaded from these links:</p>
          <p>Live Papaya XS2A API specification v1.0 – <a class="text-noline" href="https://tpp.papaya.eu/console"><strong>login</strong></a><br> SandBox Papaya XS2A API specification v1.0 – <a class="text-noline" href="https://tpp-sandbox.papaya.eu/console"><strong>login</strong></a></p>
          <h4>API lifecycle</h4>
          <ul class="list-styled mb-0">
            <li>The API version number is always included in the API call:</li>
            <li>https://tpp-sandbox.papaya.eu/banklink/v1/{{ '{' }}service{{ '}' }}  (sandbox)</li>
            <li>https://tpp.papaya.eu/banklink/v1/{{ '{' }}service{{ '}' }} (live)</li>
          </ul>
          <p class="mb-0">The API version number in the URL indicates the major version of the API.</p>
          <p>There can be minor updates to the APIs which do not change the major version number. The major version number only changes when the big number changes which might significantly impact API backwards compatibility is implemented. The new major versions of specification will depend on Berlin Group specification releases and NCA mandates, such changes will be published 3 months prior to the release date. The older versions of APIs will be deprecated but remain available for up to six months after release implementation, and removed after this period. API major version number will be incremented with every new major API release, e.g. next API version will typically have URL in following format:</p>
          <ul class="list-styled">
            <li>https://tpp-sandbox.papaya.eu/banklink/v2/{{ '{' }}service{{ '}' }} (sandbox)</li>
            <li>https://tpp.papaya.eu/banklink/v2/{{ '{' }}service{{ '}' }}  (live)</li>
          </ul>
          <h4>HTTP Response Codes</h4>
          <p class="my-2">The HTTP response code is communicating the success or failure of a TPP request message. The 4XX HTTP response codes only be given if the current<br> request cannot be fulfilled, in example if a payment initiation cannot be posted, or account transactions cannot be retrieved. A request to get the status of an existing payment or a consent returns HTTP response code 200 since the actual request to retrieve the status succeeded, regardless if that payment or consent state is set to failure or not.</p>
          <div class="table-wrapper">
            <table class="table-border table-striped table-gray">
              <colgroup>
                <col width="10%">
                <col width="90%">
              </colgroup>
              <tr>
                <th>Code</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>200 OK</td>
                <td>This return code is permitted if a request was repeated due to a time-out. The response in that might be either a 200 or 201 code. The POST for a Funds request will also return 200 since it does not create a new resource. DELETE Response Code where a payment resource has been cancelled successfully and no further cancellation authorisation is required.</td>
              </tr>
              <tr>
                <td>201 Created</td>
                <td>POST response code where Payment Initiation or Consent Request was correctly performed.</td>
              </tr>
              <tr>
                <td>202 Accepted</td>
                <td>DELETE response code, where a payment resource can be cancelled in general, but a cancellation authorisation is needed in addition.</td>
              </tr>
              <tr>
                <td>204 No Content</td>
                <td>DELETE response code where a consent resource was successfully deleted. The code indicates that the request was performed, but no content was returned.</td>
              </tr>
              <tr>
                <td>400 Bad Request</td>
                <td>Validation error occurred. This code will cover malformed syntax in request or incorrect data in payload.</td>
              </tr>
              <tr>
                <td>401 Unauthorized</td>
                <td>The TPP or the PSU is not correctly authorized to perform the request. Retry the request with correct authentication information.</td>
              </tr>
              <tr>
                <td>403 Forbidden</td>
                <td>Returned if the resource that was referenced in the path exists but cannot be accessed by the TPP or the PSU.</td>
              </tr>
              <tr>
                <td>404 Not found</td>
                <td>Returned if the resource or endpoint that was referenced in the path does not exist or cannot be referenced by the TPP or the PSU.</td>
              </tr>
              <tr>
                <td>405 Method Not Allowed</td>
                <td>This code is only sent when the HTTP method (PUT,POST, DELETE, GET etc.) is not supported on a specific endpoint. It has nothing to do with the consent,payment or account information data model.</td>
              </tr>
              <tr>
                <td>406 Not Acceptable</td>
                <td>The ASPSP cannot generate the content that the TPP specified in the Accept header.</td>
              </tr>
              <tr>
                <td>408 Request Timeout</td>
                <td>The server is still working correctly, but an individual request has timed out.</td>
              </tr>
              <tr>
                <td>415 Unsupported Media Type</td>
                <td>The TPP has supplied a media type which the ASPSP does not support.</td>
              </tr>
              <tr>
                <td>429 Too Many Requests</td>
                <td>The TPP has exceeded the number of requests allowed by the consent or by the RTS.</td>
              </tr>
              <tr>
                <td>500 Internal Server Error</td>
                <td>Internal server error occurred.</td>
              </tr>
              <tr>
                <td>503 Service Unavailable</td>
                <td>The ASPSP server is currently unavailable. Generally, this is a temporary state.</td>
              </tr>
            </table>
          </div>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
