<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">General Information about the Sandbox</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <p class="mb-0">
            Papaya’s portal Sandbox provides TPPs with tools and information necessary to access and test Papaya’s API. To help TPPs prepare correct API calls The Sandbox provides API Documentation where specifications of endpoint calls and code examples can be obtained.By using specification and examples available in the API Documentation, TPP can develop and test real-time calls to the following Papaya’s test APIs:
          </p>
          <ul class="mt-0">
            <li>Access to Account API</li>
            <li>Payment API</li>
            <li>Consent Authorization API</li>
            <li>Funds confirmation API</li>
          </ul>
          <p class="mb-0">The Papaya sandbox consists of mock data for:</p>
          <ul class="mt-0">
            <li>Account Information Services (AIS) as defined by article 67 in the PSD2 Directive.</li>
            <li>Payment Initiation services (PIS) as defined by Article 66 in the PSD2 Directive.</li>
            <li>Payment Instrument Issuing Service (PIIS) as defined by Article 65 in the PSD2 Directive.</li>
            <li>Consent services to create consent and request.</li>
          </ul>
          <h4>Available Services</h4>
          <p class="mb-0">Papaya’s Sandbox provides the following services to be accessed by TPPs:</p>
          <ul>
            <li>
              <h4 class="mb-0">Payment Initiation Service</h4>
              <p class="mb-0">Allow for Payment Initiation Service (PIS) offers initiation of a payment request. PIS API support a single SEPA Credit Transfer and intrabank transfers.</p>
            </li>
            <li>
              <h4 class="mb-0">Account Information Service</h4>
              <p class="mb-0">The Account Information Service (AIS) offers the following services: list of payment accounts, provide information about account balances and transaction statements relative to a granted consent.</p>
            </li>
            <li>
              <h4 class="mb-0">Confirmation of Funds Service</h4>
              <p class="mb-0">Allow for Payment Instrument Issuing Service Provider (PIISP) Makes it <br> possible to verify if there is sufficient funds available in the account.</p>
            </li>
          </ul>
          <div class="payment-tables mb-4">
            <h4>Sandbox Environment Settings</h4>
            <p>This sandbox is your environment to get to know about our APIs. It provides the same APIs as our production environment but with mock data.</p>
            <p class="my-2"><strong>Note:</strong><br> some changes to the APIs and request header structure are subject to change. About all changes you will be informed previously.<br> <strong>Payment Service User (PSU) 1:</strong><br> Username: Luke001<br> Password: Luke001<br> Phone: 2785623975944</p>

            <div class="table-wrapper">
              <table class="table-border table-striped table-gray">
                <colgroup>
                  <col width="20%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="22%">
                </colgroup>
                <tr>
                  <th>IBAN</th>
                  <th>Currency</th>
                  <th>Leavings</th>
                  <th>Available</th>
                  <th>Actions</th>
                </tr>
                <tr>
                  <td>MT53PAPY36836000002676370006881</td>
                  <td>EUR</td>
                  <td>2500.00</td>
                  <td>2500.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT26PAPY36836000002676370006882</td>
                  <td>EUR</td>
                  <td>3000.00</td>
                  <td>3000.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT96PAPY36836000002676370006883</td>
                  <td>EUR</td>
                  <td>3500.00</td>
                  <td>3500.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT69PAPY36836000002676370006884</td>
                  <td>EUR</td>
                  <td>4000.00</td>
                  <td>4000.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT42PAPY36836000002676370006885</td>
                  <td>EUR</td>
                  <td>4500.00</td>
                  <td>4500.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
              </table>
            </div>

            <p class="my-2"><strong>Payment Service User (PSU) 2:</strong> <br> Username: Frodo002<br> Password: Frodo002<br> Phone: 123321879532</p>

            <div class="table-wrapper">
              <table class="table-border table-striped table-gray">
                <colgroup>
                  <col width="20%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="22%">
                </colgroup>
                <tr>
                  <th>IBAN</th>
                  <th>Currency</th>
                  <th>Leavings</th>
                  <th>Available</th>
                  <th>Actions</th>
                </tr>
                <tr>
                  <td>MT91PAPY36836000002676370006876</td>
                  <td>EUR</td>
                  <td>2500.00</td>
                  <td>2500.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT64PAPY36836000002676370006877</td>
                  <td>EUR</td>
                  <td>3000.00</td>
                  <td>3000.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT37PAPY36836000002676370006878</td>
                  <td>EUR</td>
                  <td>3500.00</td>
                  <td>3500.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT10PAPY36836000002676370006879</td>
                  <td>EUR</td>
                  <td>4000.00</td>
                  <td>4000.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT80PAPY36836000002676370006880</td>
                  <td>EUR</td>
                  <td>4500.00</td>
                  <td>4500.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
              </table>
            </div>

            <p class="my-2"><strong>Payment Service User (PSU) 3:</strong> <br> Username: PandoraLTD101<br> Password: PandoraLTD101<br> Phone: 1565615616</p>

            <div class="table-wrapper">
              <table class="table-border table-striped table-gray">
                <colgroup>
                  <col width="20%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="22%">
                </colgroup>
                <tr>
                  <th>IBAN</th>
                  <th>Currency</th>
                  <th>Leavings</th>
                  <th>Available</th>
                  <th>Actions</th>
                </tr>
                <tr>
                  <td>MT49PAPY36836000002656370000552</td>
                  <td>EUR</td>
                  <td>2500.00</td>
                  <td>2500.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT22PAPY36836000002656370000553</td>
                  <td>EUR</td>
                  <td>3000.00</td>
                  <td>3000.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT92PAPY36836000002656370000554</td>
                  <td>EUR</td>
                  <td>3500.00</td>
                  <td>3500.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT65PAPY36836000002656370000555</td>
                  <td>EUR</td>
                  <td>4000.00</td>
                  <td>4000.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
                <tr>
                  <td>MT38PAPY36836000002656370000556</td>
                  <td>EUR</td>
                  <td>4500.00</td>
                  <td>4500.00</td>
                  <td>Access to Account
                    Account balance
                    Confirmation of funds
                    Transaction statements
                    SEPA Payment
                    Intrabank Transfers</td>
                </tr>
              </table>
            </div>
          </div>

          <p class="mb-0"><strong>Note:</strong><br>All APIs are implemented using RESTful architecture style. For all requests and responses only JSON format can be used.</p>
          <p><strong>Note:</strong><br>All account related data (i.e. PSU names, account numbers, account balances, etc.) is fictional and has no relation to real data.</p>
          <h4>Reset your Sandbox environment</h4>
          <p>In any situation TPP can always return their Sandbox environment configuration to default state. To do that you need to click on “My Sandbox Settings” menu and find the option “RESET THE SANDBOX TO INITIAL STATE”.</p>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
