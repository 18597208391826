import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-two',
  templateUrl: './statistics-two.component.html',
  styleUrls: ['./statistics-two.component.scss']
})
export class StatisticsTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
