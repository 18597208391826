<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
          <tr>
            <th>Date</th>
            <th>Channel</th>
            <th>Uptime, <br/> %</th>
            <th>Downtime, <br/> %</th>
            <th>AIS <br/> performance, <br/> ms</th>
            <th>PIS <br/> performance, <br/> ms</th>
            <th>CoF <br/> performance, <br/> ms</th>
            <th>Error response <br/> rate, <br/> %</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td rowspan="3" class="text-nowrap">7/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>197</td>
            <td>96</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>257</td>
            <td>829</td>
            <td>N/A</td>
            <td>0.021%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>710</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>190</td>
            <td>86</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>559</td>
            <td>N/A</td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>514</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>197</td>
            <td>87</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>201</td>
            <td>554</td>
            <td>N/A</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>536</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>201</td>
            <td>98</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>292</td>
            <td>947</td>
            <td>N/A</td>
            <td>0.027%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>753</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>204</td>
            <td>97</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>284</td>
            <td>859</td>
            <td>N/A</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>698</td>
            <td>458</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>193</td>
            <td>84</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>662</td>
            <td>N/A</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>671</td>
            <td>429</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/7/2023</td>
            <td>Open Banking API</td>
            <td>95.80%</td>
            <td>4.20%</td>
            <td>259</td>
            <td>450</td>
            <td>322</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>95.80%</td>
            <td>4.20%</td>
            <td>1347</td>
            <td>2276</td>
            <td>N/A</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>95.80%</td>
            <td>4.20%</td>
            <td>802</td>
            <td>498</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>209</td>
            <td>100</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>2043</td>
            <td>2449</td>
            <td>N/A</td>
            <td>0.035%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>3027</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>183</td>
            <td>85</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>166</td>
            <td>546</td>
            <td>N/A</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>201</td>
            <td>89</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>218</td>
            <td>587</td>
            <td>N/A</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>550</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>211</td>
            <td>113</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>3434</td>
            <td>4197</td>
            <td>N/A</td>
            <td>0.044%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>3950</td>
            <td>563</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>205</td>
            <td>100</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>300</td>
            <td>913</td>
            <td>N/A</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>744</td>
            <td>507</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>203</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>254</td>
            <td>785</td>
            <td>N/A</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>685</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>210</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>807</td>
            <td>N/A</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>661</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>209</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>265</td>
            <td>850</td>
            <td>N/A</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>664</td>
            <td>427</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>198</td>
            <td>85</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>540</td>
            <td>N/A</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>540</td>
            <td>N/A</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>209</td>
            <td>88</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>563</td>
            <td>N/A</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td >100.00%</td>
            <td >0.00%</td>
            <td >536</td>
            <td >365</td>
            <td >N/A</td>
            <td >N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>208</td>
            <td>96</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>272</td>
            <td>914</td>
            <td>N/A</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>729</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>211</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>234</td>
            <td>758</td>
            <td>N/A</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>654</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>205</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>782</td>
            <td>N/A</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>641</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>205</td>
            <td>91</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>735</td>
            <td>N/A</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>629</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>207</td>
            <td>109</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>231</td>
            <td>743</td>
            <td>N/A</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>636</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>198</td>
            <td>85</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>528</td>
            <td>N/A</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>483</td>
            <td>322</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>205</td>
            <td>89</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>560</td>
            <td>N/A</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>529</td>
            <td>355</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>207</td>
            <td>99</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>267</td>
            <td>876</td>
            <td>N/A</td>
            <td>0.036%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>707</td>
            <td>439</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>206</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>230</td>
            <td>744</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>642</td>
            <td>393</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>205</td>
            <td>92</td>
            <td>0.118%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>226</td>
            <td>732</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>633</td>
            <td>394</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/28/2023</td>
            <td>Open Banking API</td>
            <td >100.00%</td>
            <td >0.00%</td>
            <td >116</td>
            <td >205</td>
            <td >96</td>
            <td >0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>729</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>630</td>
            <td>394</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>207</td>
            <td>99</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>258</td>
            <td>784</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>656</td>
            <td>406</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>196</td>
            <td>88</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>541</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>498</td>
            <td>327</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>206</td>
            <td>95</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>203</td>
            <td>591</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>561</td>
            <td>379</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>209</td>
            <td>100</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>300</td>
            <td>969</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>662</td>
            <td>452</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>200</td>
            <td>96</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>250</td>
            <td>826</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>588</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>204</td>
            <td>98</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>242</td>
            <td>789</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>575</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>207</td>
            <td>98</td>
            <td>0.028%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>238</td>
            <td>777</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>560</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>211</td>
            <td>102</td>
            <td>0.018%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>296</td>
            <td>877</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>583</td>
            <td>414</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>200</td>
            <td>89</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>555</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>409</td>
            <td>329</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>207</td>
            <td>93</td>
            <td>0.021%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>579</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>440</td>
            <td>345</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>208</td>
            <td>116</td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>384</td>
            <td>1211</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>712</td>
            <td>474</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>208</td>
            <td>132</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>297</td>
            <td>901</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>583</td>
            <td>416</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>210</td>
            <td>200</td>
            <td>0.027%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>389</td>
            <td>1024</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>595</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>207</td>
            <td>100</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>272</td>
            <td>830</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>579</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>206</td>
            <td>105</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>275</td>
            <td>830</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>589</td>
            <td>412</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>196</td>
            <td>92</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>537</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>428</td>
            <td>333</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>205</td>
            <td>98</td>
            <td>0.035%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>205</td>
            <td>587</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>462</td>
            <td>346</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>200</td>
            <td>100</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>731</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>632</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>208</td>
            <td>99</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>272</td>
            <td>827</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>611</td>
            <td>427</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/17/2023</td>
            <td>Open Banking API</td>
            <td>95.80%</td>
            <td>4.20%</td>
            <td>159</td>
            <td>283</td>
            <td>153</td>
            <td>0.044%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>242</td>
            <td>882</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>591</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>211</td>
            <td>97</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>745</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>582</td>
            <td>394</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>211</td>
            <td>98</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>239</td>
            <td>751</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>568</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>200</td>
            <td>87</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>533</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>419</td>
            <td>324</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>211</td>
            <td>91</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>188</td>
            <td>592</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>483</td>
            <td>359</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>218</td>
            <td>100</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>297</td>
            <td>910</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>670</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>213</td>
            <td>99</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>246</td>
            <td>762</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>600</td>
            <td>405</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/24/2023</td>
            <td>Open Banking API</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>121</td>
            <td>210</td>
            <td>97</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>738</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>606</td>
            <td>401</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>213</td>
            <td>98</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>257</td>
            <td>796</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>604</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>210</td>
            <td>97</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>233</td>
            <td>723</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>593</td>
            <td>402</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>205</td>
            <td>91</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>536</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>452</td>
            <td>326</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>210</td>
            <td>93</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>184</td>
            <td>574</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>472</td>
            <td>349</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>214</td>
            <td>102</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>287</td>
            <td>899</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>664</td>
            <td>439</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/30/2023</td>
            <td>Open Banking API</td>
            <td>98.00%</td>
            <td>2.00%</td>
            <td>124</td>
            <td>214</td>
            <td>104</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>272</td>
            <td>832</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>630</td>
            <td>411</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/31/2023</td>
            <td>Open Banking API</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>121</td>
            <td>212</td>
            <td>102</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>299</td>
            <td>862</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>626</td>
            <td>420</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>211</td>
            <td>101</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>296</td>
            <td>863</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>866</td>
            <td>648</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>216</td>
            <td>107</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>292</td>
            <td>882</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>830</td>
            <td>659</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>202</td>
            <td>95</td>
            <td>0.036%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>598</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>458</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>214</td>
            <td>95</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>194</td>
            <td>603</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>495</td>
            <td>435</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>223</td>
            <td>111</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1061</td>
            <td>1757</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>996</td>
            <td>784</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>214</td>
            <td>99</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>280</td>
            <td>823</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>869</td>
            <td>672</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/7/2023</td>
            <td>Open Banking API</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>115</td>
            <td>212</td>
            <td>96</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>299</td>
            <td>825</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>781</td>
            <td>625</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>217</td>
            <td>107</td>
            <td>0.028%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>332</td>
            <td>881</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>898</td>
            <td>720</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>230</td>
            <td>135</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>98.00%</td>
            <td>2.00%</td>
            <td>1422</td>
            <td>1722</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>993</td>
            <td>818</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/10/2023</td>
            <td>Open Banking API</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>101</td>
            <td>198</td>
            <td>82</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>159</td>
            <td>530</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>406</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>208</td>
            <td>88</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>213</td>
            <td>594</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>469</td>
            <td>437</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>222</td>
            <td>100</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>350</td>
            <td>1071</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>855</td>
            <td>703</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>218</td>
            <td>96</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>246</td>
            <td>788</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>773</td>
            <td>627</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>210</td>
            <td>93</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>766</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>775</td>
            <td>625</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>208</td>
            <td>95</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>258</td>
            <td>816</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>836</td>
            <td>653</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>203</td>
            <td>95</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>759</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>743</td>
            <td>603</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>196</td>
            <td>85</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>536</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>436</td>
            <td>411</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>210</td>
            <td>90</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>585</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>499</td>
            <td>463</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>211</td>
            <td>99</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>269</td>
            <td>887</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>830</td>
            <td>660</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>210</td>
            <td>96</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>239</td>
            <td>773</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>755</td>
            <td>605</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>207</td>
            <td>92</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>236</td>
            <td>757</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>887</td>
            <td>591</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>205</td>
            <td>101</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>238</td>
            <td>733</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>802</td>
            <td>613</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>209</td>
            <td>102</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>244</td>
            <td>752</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>794</td>
            <td>639</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>200</td>
            <td>89</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>529</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>462</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>207</td>
            <td>93</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>597</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>519</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>211</td>
            <td>104</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>279</td>
            <td>887</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>886</td>
            <td>706</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>207</td>
            <td>102</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>241</td>
            <td>754</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>784</td>
            <td>601</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>210</td>
            <td>105</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>774</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>836</td>
            <td>617</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>214</td>
            <td>128</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>278</td>
            <td>775</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>853</td>
            <td>626</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>219</td>
            <td>107</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>293</td>
            <td>896</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>878</td>
            <td>687</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

