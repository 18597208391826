<footer class="footer">
  <div class="container">
    <div class="footer__row">
      <div class="footer__col-left">
        <p class="footer__copy-text">
          Papaya Ltd is Licensed by the Malta Financial Services Authority as an
          Electronic Money Institution (EMI) Registration number C55146.
        </p>
        <p class="footer__copy-text footer__copy-text_muted">
          DISCLAIMER: Papaya Ltd. shall not be responsible for the communication
          of any fraudulent information relating to unauthorised activity, nor
          shall we be liable for any loss or damage incurred by any member of
          the public as a result of dealing with any entity which illegally
          solicits payments for fake real estate services or any other service
          which is not related to Papaya Ltd. main activity of Issuing
          Electronic Money or Prepaid payment products.
        </p>
      </div>
      <div class="footer__col-right">
        <div class="footer__row">
          <div class="footer__col">
            <ul class="footer__links">
              <li class="footer__links-item">
                <a routerLink="/about">About Us</a>
              </li>
              <li class="footer__links-item">
                <a routerLink="/contacts">Contacts</a>
              </li>
              <li class="footer__links-item">
                <a routerLink="/careers">Careers</a>
              </li>
              <li class="footer__links-item">
                <a routerLink="/complaints">Complaints</a>
              </li>
              <li class="footer__links-item">
                <a routerLink="/currency-exchanges">Currency rates</a>
              </li>
            </ul>
          </div>
          <div class="footer__col">
            <ul class="footer__links">
              <li class="footer__links-item">
                <a
                  href="https://blackcatcard.com/en/legal/papaya.cookies-policy.html"
                  target="_blank"
                  >Cookie Policy</a
                >
              </li>
              <li class="footer__links-item">
                <a
                  href="https://blackcatcard.com/en/legal/papaya.data-protection-policy.html"
                  target="_blank"
                  >Data Protection Policy</a
                >
              </li>
              <li class="footer__links-item">
                <a routerLink="/fraud-disclaimer">Fraud Disclaimer</a>
              </li>
              <li class="footer__links-item">
                <a
                  href="https://blackcatcard.com/en/legal/papaya.fair-usage.html"
                  target="_blank"
                  >Customer Security<br />
                  And Fraud Awareness</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
