import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  isTooltipCareerSenior = false;
  isTooltipCareerDirector = false;
  isTooltipCareerCoordinator = false;
  isTooltipCareerRequirements = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
  }

  showTooltip(type: string): void {
    switch (type) {
      case 'careerSenior':
        this.isTooltipCareerSenior = !this.isTooltipCareerSenior;
        break;
      case 'careerDirector':
        this.isTooltipCareerDirector = !this.isTooltipCareerDirector;
        break;
      case 'careerCoordinator':
        this.isTooltipCareerCoordinator = !this.isTooltipCareerCoordinator;
        break;
      case 'careerRequirements':
        this.isTooltipCareerRequirements = !this.isTooltipCareerRequirements;
        break;
    }
  }
}
