<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">Customer Security and Fraud Awareness</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <h4>Our Approach to Security</h4>
          <p>
            When it comes to your financial information, your security is our top priority and when you access your e-money account, it is important that we know it is you.  Here are some of the ways we do that:
          </p>
          <p><strong>Login details</strong> – we provide you online login details unique to you. To protect yourself we recommend you do not share them.</p>
          <p><strong>Security Questions</strong> – If you contact our customer service team, we may ask you to confirm who you are by asking the security questions responses you provided when creating your online e-money account.</p>
          <p><strong>Portal PIN</strong> – 6 digit PIN that we have provided to you after the registration (don’t confuse with the PIN for your card)</p>
          <p><strong>OTP</strong> – One Time Passcode used by Corporate clients – we send these unique one time use codes to your mobile for added security when:</p>
          <ul>
            <li>You make payments from your online e-money account;</li>
            <li>You process top ups</li>
          </ul>
          <p>
            <strong>Providing Information</strong> – we will never ask you for your online password details or PIN number.  We will always firstly send you an email if we are trying to contact you.
          </p>
        </section>
        <section class="section__row">
          <h4>How to Report Fraud</h4>
          <p>If you notice something suspicious and believe it could be fraudulent, you should contact us as soon as you become aware of it using the telephone number or email address below.
          </p>
          <p><strong>Reporting Fraud: +356 20155500, <a href="mailto:customerservice@papaya.eu">customerservice@papaya.eu</a></strong></p>
          <p><strong>Lost or Stolen Cards: +356 20155500, <a href="mailto:LostStolen@papaya.eu">LostStolen@papaya.eu</a></strong></p>
          <p><strong>Suspicious Emails: +356 20155500, <a href="mailto:customerservice@papaya.eu">customerservice@papaya.eu</a></strong></p>
        </section>
        <section class="section__row">
          <h4>How to Protect Yourself from Fraud</h4>
          <p>Help to keep yourself safe from fraudsters by following the tips below.  Remember, if you are ever unsure, don’t act.  A genuine company will never rush you to take action.</p>
          <p>Always make sure your mobile telephone number and email address registered with us is up to date, we will use these to contact you if we notice unusual activity on your e-money account.</p>
          <p><strong>Some Tips for Using Your E-money Account and Prepaid Card Safely</strong></p>
          <p class="mb-3">When accessing your e-money account online:</p>
          <ul>
            <li>Use an antivirus software and firewall.</li>
            <li>Make sure you keep your computer and browser up to date.</li>
            <li>Use secure networks.</li>
            <li>Use strong passwords.</li>
            <li>Don’t share any passwords including one-time passwords sent to you.</li>
          </ul>
          <p class="mb-3">When using a mobile application</p>
          <ul>
            <li>Only install apps from recognised app stores.</li>
            <li>Consider the app ratings and reviews.</li>
            <li>Be aware of what permissions you are granting.</li>
            <li>Treat your phone as your wallet.</li>
          </ul>
          <p class="mb-3">When shopping online or in a store</p>
          <ul class="">
            <li>When using an online retailer for the first time, do some research to make sure that they are genuine</li>
            <li>Do not reply to unsolicited emails from companies you don’t recognise.</li>
            <li>Before entering your prepaid card details, make sure the link is secure. There should be a padlock symbol in the browser frame window which appears when you login or register, if this appears on the page rather than the browser it may indicate a fraudulent website. The web address should begin with https://, the ‘s’ stands for secure.</li>
            <li>Always log out of website after use. Simply closing your browser is not enough to ensure your data is safe</li>
            <li>Keep your PIN safe and do not share it</li>
            <li>Always check your statements.</li>
          </ul>
          <p>Remember, if you decide to donate, resell or recycle an old mobile phone, computer, laptop or tablet, make sure you fully remove all data and apps first as otherwise these may be accessed by whoever your device is passed to.</p>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
