<div class="section services" id="services">
  <div class="container">
    <div class="section__header">
      <h2 class="section__title js-fade-up">Solutions</h2>
      <p class="section__desc js-fade-up">Banking services, bonus programs, co-branded payment cards, white-label mobile and online banking and any other customized solution for your business.</p>
    </div>
    <div class="services__row">
      <div class="services__col">
        <div class="service-card js-fade-up-block">
          <div class="service-card__left">
            <img class="service-card__icon" src="assets/img/services/digital-banking.svg" alt="Digital banking">
          </div>
          <div class="service-card__right">
            <h3 class="service-card__title">Digital banking</h3>
            <p class="service-card__desc">Offer accounts and payments for your digital banking</p>
          </div>
        </div>
        <div class="service-card js-fade-up-block">
          <div class="service-card__left">
            <img class="service-card__icon" src="assets/img/services/payments.svg" alt="Payments">
          </div>
          <div class="service-card__right">
            <h3 class="service-card__title">Payments</h3>
            <p class="service-card__desc">PSD2 compliant solution enabling SEPA and international payments</p>
          </div>
        </div>
        <div class="service-card js-fade-up-block">
          <div class="service-card__left">
            <img class="service-card__icon" src="assets/img/services/KYC-service.svg" alt="KYC service">
          </div>
          <div class="service-card__right">
            <h3 class="service-card__title">KYC service</h3>
            <p class="service-card__desc">Fully automated digital solution for your business</p>
          </div>
        </div>
      </div>
      <div class="services__col">
        <div class="service-card js-fade-up-block">
          <div class="service-card__left">
            <img class="service-card__icon" src="assets/img/services/payment-cards.svg" alt="Payment cards">
          </div>
          <div class="service-card__right">
            <h3 class="service-card__title">Payment cards</h3>
            <p class="service-card__desc">Offer co-branded payment cards with your individual design</p>
          </div>
        </div>
        <div class="service-card js-fade-up-block">
          <div class="service-card__left">
            <img class="service-card__icon" src="assets/img/services/IBAN.svg" alt="IBAN">
          </div>
          <div class="service-card__right">
            <h3 class="service-card__title">IBAN</h3>
            <p class="service-card__desc">Individual SEPA accounts in European Financial Institution</p>
          </div>
        </div>
        <div class="service-card js-fade-up-block">
          <a routerLink="/online-banking" class="service-card__wrapper-link">
            <div class="service-card__left">
              <img class="service-card__icon" src="assets/img/services/PSD2.svg" alt="PSD2">
            </div>
            <div class="service-card__right">
              <h3 class="service-card__title">PSD2</h3>
              <p class="service-card__desc">Compliant API for your Fintech solutions as AISP and PISP</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
