import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistic-q22021',
  templateUrl: './statistic-q22021.component.html',
  styleUrls: ['./statistic-q22021.component.scss']
})
export class StatisticQ22021Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
