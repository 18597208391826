import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-openbanking-terms-and-conditions',
  templateUrl: './openbanking-terms-and-conditions.component.html',
  styleUrls: ['./openbanking-terms-and-conditions.component.scss']
})
export class OpenbankingTermsAndConditionsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
  }
}
