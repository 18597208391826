<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">SCA (strong customer authentication) Methods</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <h4>Redirect SCA approach</h4>
          <p>The Redirect SCA approach authorization flow enables the TPP to get access to the Financial Institution Resource Owner’s payment accounts, subject to the Resource Owner’s authorization. The TPP calls authorization API endpoint to initiate this flow, from which the customer is directed to authenticate themselves and grant access through Papaya SCA, thereby authorizing the client application`s access. The TPP can then acquire the access token to use in the AIS and PIS APIs.</p>
          <p><strong>Please note:</strong><br> prior to initiating this flow, it is assumed the TPP will have consent with the resource owner (PSU).</p>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
