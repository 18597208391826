import { Injectable } from '@angular/core';
import { TimelineMax, TweenMax, Power1 } from 'gsap';
import * as ScrollMagic from 'scrollmagic';
import { Subject, BehaviorSubject } from 'rxjs';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

const controller = new ScrollMagic.Controller();
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

@Injectable({
  providedIn: 'root'
})
export class AnimationService {
  constructor() {
  }
  animateFadeUp(tl, element) {
    tl.from(element, 0.4, {
      y: 60,
      opacity: 0,
    });
  }
  animatePartners(tl) {
    if (window.innerWidth > 767) {
      tl.from('.partners__item:nth-child(1)', 0.8, {
        y: 40,
        opacity: 0,
      });
      tl.from('.partners__item:nth-child(2)', 0.8, {
        y: 60,
        opacity: 0,
      }, '-=0.6');
      tl.from('.partners__item:nth-child(3)', 0.8, {
        y: 120,
        opacity: 0,
      }, '-=0.6');
    }
  }
  animateServices(scroll) {
    const serviceCardsLeft = document.querySelectorAll('.services__col:nth-child(1) .service-card');
    const serviceCardsRight = document.querySelectorAll('.services__col:nth-child(2) .service-card');

    if (window.innerWidth > 767) {
      serviceCardsLeft.forEach(element =>this.tweenServices(element, 0.1, scroll));
      serviceCardsRight.forEach(element => this.tweenServices(element, 0.3, scroll));
    }
  }
  tweenServices(element, timeDelay, scroll?) {
    const tl = new TimelineMax();

    tl.from(element, 0.4, {
      y: 60,
      opacity: 0,
      delay: timeDelay,
    });

    if (scroll) {
      new ScrollMagic.Scene({
        triggerElement: element,
        triggerHook: 'onEnter',
        reverse: true
      })
        .setTween(tl)
        .addTo(controller);
    }
  }
}
