import { Injectable } from '@angular/core';

@Injectable()
export class CountryService {

  constructor() {
  }

  getCountryList() {
    return [
      {
        "name": "Afghanistan",
        "dialCode": "93",
        "code": "AF",
        "nationality": "Afghan",
        "alpha_3_code": "AFG"
      },
      {
        "name": "Aland Islands",
        "dialCode": "358",
        "code": "AX",
        "nationality": "Aland Island",
        "alpha_3_code": "ALA"
      },
      {
        "name": "Albania",
        "dialCode": "355",
        "code": "AL",
        "nationality": "Albanian",
        "alpha_3_code": "ALB"
      },
      {
        "name": "Algeria",
        "dialCode": "213",
        "code": "DZ",
        "nationality": "Algerian",
        "alpha_3_code": "DZA"
      },
      {
        "name": "American Samoa",
        "dialCode": "1684",
        "code": "AS",
        "nationality": "American Samoan",
        "alpha_3_code": "ASM"
      },
      {
        "name": "Andorra",
        "dialCode": "376",
        "code": "AD",
        "nationality": "Andorran",
        "alpha_3_code": "AND"
      },
      {
        "name": "Angola",
        "dialCode": "244",
        "code": "AO",
        "nationality": "Angolan",
        "alpha_3_code": "AGO"
      },
      {
        "name": "Anguilla",
        "dialCode": "1264",
        "code": "AI",
        "nationality": "Anguillan",
        "alpha_3_code": "AIA"
      },
      {
        "name": "Antarctica",
        "dialCode": "672",
        "code": "AQ",
        "nationality": "Antarctic",
        "alpha_3_code": "ATA"
      },
      {
        "name": "Antigua and Barbuda",
        "dialCode": "1268",
        "code": "AG",
        "nationality": "Antiguan or Barbudan",
        "alpha_3_code": "ATG"
      },
      {
        "name": "Argentina",
        "dialCode": "54",
        "code": "AR",
        "nationality": "Argentine",
        "alpha_3_code": "ARG"
      },
      {
        "name": "Armenia",
        "dialCode": "374",
        "code": "AM",
        "nationality": "Armenian",
        "alpha_3_code": "ARM"
      },
      {
        "name": "Aruba",
        "dialCode": "297",
        "code": "AW",
        "nationality": "Aruban",
        "alpha_3_code": "ABW"
      },
      {
        "name": "Australia",
        "dialCode": "61",
        "code": "AU",
        "nationality": "Australian",
        "alpha_3_code": "AUS"
      },
      {
        "name": "Austria",
        "dialCode": "43",
        "code": "AT",
        "nationality": "Austrian",
        "alpha_3_code": "AUT"
      },
      {
        "name": "Azerbaijan",
        "dialCode": "994",
        "code": "AZ",
        "nationality": "Azerbaijani, Azeri",
        "alpha_3_code": "AZE"
      },
      {
        "name": "Bahamas",
        "dialCode": "1242",
        "code": "BS",
        "nationality": "Bahamian",
        "alpha_3_code": "BHS"
      },
      {
        "name": "Bahrain",
        "dialCode": "973",
        "code": "BH",
        "nationality": "Bahraini",
        "alpha_3_code": "BHR"
      },
      {
        "name": "Bangladesh",
        "dialCode": "880",
        "code": "BD",
        "nationality": "Bangladeshi",
        "alpha_3_code": "BGD"
      },
      {
        "name": "Barbados",
        "dialCode": "1246",
        "code": "BB",
        "nationality": "Barbadian",
        "alpha_3_code": "BRB"
      },
      {
        "name": "Belarus",
        "dialCode": "375",
        "code": "BY",
        "nationality": "Belarusian",
        "alpha_3_code": "BLR"
      },
      {
        "name": "Belgium",
        "dialCode": "32",
        "code": "BE",
        "nationality": "Belgian",
        "alpha_3_code": "BEL"
      },
      {
        "name": "Belize",
        "dialCode": "501",
        "code": "BZ",
        "nationality": "Belizean",
        "alpha_3_code": "BLZ"
      },
      {
        "name": "Benin",
        "dialCode": "229",
        "code": "BJ",
        "nationality": "Beninese, Beninois",
        "alpha_3_code": "BEN"
      },
      {
        "name": "Bermuda",
        "dialCode": "1441",
        "code": "BM",
        "nationality": "Bermudian, Bermudan",
        "alpha_3_code": "BMU"
      },
      {
        "name": "Bhutan",
        "dialCode": "975",
        "code": "BT",
        "nationality": "Bhutanese",
        "alpha_3_code": "BTN"
      },
      {
        "name": "Bolivia, Plurinational State of",
        "dialCode": "591",
        "code": "BO",
        "nationality": "Bolivian",
        "alpha_3_code": "BOL"
      },
      {
        "name": "Bonaire, Sint Eustatius and Saba",
        "dialCode": "599",
        "code": "BQ",
        "nationality": "Bonaire",
        "alpha_3_code": "BES"
      },
      {
        "name": "Bosnia and Herzegovina",
        "dialCode": "387",
        "code": "BA",
        "nationality": "Bosnian or Herzegovinian",
        "alpha_3_code": "BIH"
      },
      {
        "name": "Botswana",
        "dialCode": "267",
        "code": "BW",
        "nationality": "Motswana, Botswanan",
        "alpha_3_code": "BWA"
      },
      {
        "name": "Bouvet Island",
        "dialCode": "55",
        "code": "BV",
        "nationality": "Bouvet Island",
        "alpha_3_code": "BVT"
      },
      {
        "name": "Brazil",
        "dialCode": "55",
        "code": "BR",
        "nationality": "Brazilian",
        "alpha_3_code": "BRA"
      },
      {
        "name": "British Indian Ocean Territory",
        "dialCode": "246",
        "code": "IO",
        "nationality": "BIOT",
        "alpha_3_code": "IOT"
      },
      {
        "name": "Brunei Darussalam",
        "dialCode": "673",
        "code": "BN",
        "nationality": "Bruneian",
        "alpha_3_code": "BRN"
      },
      {
        "name": "Bulgaria",
        "dialCode": "359",
        "code": "BG",
        "nationality": "Bulgarian",
        "alpha_3_code": "BGR"
      },
      {
        "name": "Burkina Faso",
        "dialCode": "226",
        "code": "BF",
        "nationality": "Burkinabé",
        "alpha_3_code": "BFA"
      },
      {
        "name": "Burundi",
        "dialCode": "257",
        "code": "BI",
        "nationality": "Burundian",
        "alpha_3_code": "BDI"
      },
      {
        "name": "Cambodia",
        "dialCode": "855",
        "code": "KH",
        "nationality": "Cambodian",
        "alpha_3_code": "KHM"
      },
      {
        "name": "Cameroon",
        "dialCode": "237",
        "code": "CM",
        "nationality": "Cameroonian",
        "alpha_3_code": "CMR"
      },
      {
        "name": "Canada",
        "dialCode": "1",
        "code": "CA",
        "nationality": "Canadian",
        "alpha_3_code": "CAN"
      },
      {
        "name": "Cabo Verde",
        "dialCode": "238",
        "code": "CV",
        "nationality": "Cabo Verdean",
        "alpha_3_code": "CPV"
      },
      {
        "name": "Cayman Islands",
        "dialCode": "345",
        "code": "KY",
        "nationality": "Caymanian",
        "alpha_3_code": "CYM"
      },
      {
        "name": "Central African Republic",
        "dialCode": "236",
        "code": "CF",
        "nationality": "Central African",
        "alpha_3_code": "CAF"
      },
      {
        "name": "Chad",
        "dialCode": "235",
        "code": "TD",
        "nationality": "Chadian",
        "alpha_3_code": "TCD"
      },
      {
        "name": "Chile",
        "dialCode": "56",
        "code": "CL",
        "nationality": "Chilean",
        "alpha_3_code": "CHL"
      },
      {
        "name": "China",
        "dialCode": "86",
        "code": "CN",
        "nationality": "Chinese",
        "alpha_3_code": "CHN"
      },
      {
        "name": "Christmas Island",
        "dialCode": "61",
        "code": "CX",
        "nationality": "Christmas Island",
        "alpha_3_code": "CXR"
      },
      {
        "name": "Cocos (Keeling) Islands",
        "dialCode": "61",
        "code": "CC",
        "nationality": "Cocos Island",
        "alpha_3_code": "CCK"
      },
      {
        "name": "Colombia",
        "dialCode": "57",
        "code": "CO",
        "nationality": "Colombian",
        "alpha_3_code": "COL"
      },
      {
        "name": "Comoros",
        "dialCode": "269",
        "code": "KM",
        "nationality": "Comoran, Comorian",
        "alpha_3_code": "COM"
      },
      {
        "name": "Congo",
        "dialCode": "242",
        "code": "CG",
        "nationality": "Congolese",
        "alpha_3_code": "COG"
      },
      {
        "name": "Congo, The Democratic Republic of the Congo",
        "dialCode": "243",
        "code": "CD",
        "nationality": "Congolese",
        "alpha_3_code": "COD"
      },
      {
        "name": "Cook Islands",
        "dialCode": "682",
        "code": "CK",
        "nationality": "Cook Island",
        "alpha_3_code": "COK"
      },
      {
        "name": "Costa Rica",
        "dialCode": "506",
        "code": "CR",
        "nationality": "Costa Rican",
        "alpha_3_code": "CRI"
      },
      {
        "name": "Cote d'Ivoire",
        "dialCode": "225",
        "code": "CI",
        "nationality": "Ivorian",
        "alpha_3_code": "CIV"
      },
      {
        "name": "Croatia",
        "dialCode": "385",
        "code": "HR",
        "nationality": "Croatian",
        "alpha_3_code": "HRV"
      },
      {
        "name": "Cuba",
        "dialCode": "53",
        "code": "CU",
        "nationality": "Cuban",
        "alpha_3_code": "CUB"
      },
      {
        "name": "Cyprus",
        "dialCode": "357",
        "code": "CY",
        "nationality": "Cypriot",
        "alpha_3_code": "CYP"
      },
      {
        "name": "Curaçao",
        "dialCode": "599",
        "code": "CW",
        "nationality": "Curaçaoan",
        "alpha_3_code": "CUW"
      },
      {
        "name": "Czech Republic",
        "dialCode": "420",
        "code": "CZ",
        "nationality": "Czech",
        "alpha_3_code": "CZE"
      },
      {
        "name": "Denmark",
        "dialCode": "45",
        "code": "DK",
        "nationality": "Danish",
        "alpha_3_code": "DNK"
      },
      {
        "name": "Djibouti",
        "dialCode": "253",
        "code": "DJ",
        "nationality": "Djiboutian",
        "alpha_3_code": "DJI"
      },
      {
        "name": "Dominica",
        "dialCode": "1767",
        "code": "DM",
        "nationality": "Dominican",
        "alpha_3_code": "DMA"
      },
      {
        "name": "Dominican Republic",
        "dialCode": "1849",
        "code": "DO",
        "nationality": "Dominican",
        "alpha_3_code": "DOM"
      },
      {
        "name": "Ecuador",
        "dialCode": "593",
        "code": "EC",
        "nationality": "Ecuadorian",
        "alpha_3_code": "ECU"
      },
      {
        "name": "Egypt",
        "dialCode": "20",
        "code": "EG",
        "nationality": "Egyptian",
        "alpha_3_code": "EGY"
      },
      {
        "name": "El Salvador",
        "dialCode": "503",
        "code": "SV",
        "nationality": "Salvadoran",
        "alpha_3_code": "SLV"
      },
      {
        "name": "Equatorial Guinea",
        "dialCode": "240",
        "code": "GQ",
        "nationality": "Equatorial Guinean, Equatoguinean",
        "alpha_3_code": "GNQ"
      },
      {
        "name": "Eritrea",
        "dialCode": "291",
        "code": "ER",
        "nationality": "Eritrean",
        "alpha_3_code": "ERI"
      },
      {
        "name": "Estonia",
        "dialCode": "372",
        "code": "EE",
        "nationality": "Estonian",
        "alpha_3_code": "EST"
      },
      {
        "name": "Eswatini",
        "dialCode": "268",
        "code": "SZ",
        "nationality": "Swazi",
        "alpha_3_code": "SWZ"
      },
      {
        "name": "Ethiopia",
        "dialCode": "251",
        "code": "ET",
        "nationality": "Ethiopian",
        "alpha_3_code": "ETH"
      },
      {
        "name": "Falkland Islands (Malvinas)",
        "dialCode": "500",
        "code": "FK",
        "nationality": "Falkland Island",
        "alpha_3_code": "FLK"
      },
      {
        "name": "Faroe Islands",
        "dialCode": "298",
        "code": "FO",
        "nationality": "Faroese",
        "alpha_3_code": "FRO"
      },
      {
        "name": "Fiji",
        "dialCode": "679",
        "code": "FJ",
        "nationality": "Fijian",
        "alpha_3_code": "FJI"
      },
      {
        "name": "Finland",
        "dialCode": "358",
        "code": "FI",
        "nationality": "Finnish",
        "alpha_3_code": "FIN"
      },
      {
        "name": "France",
        "dialCode": "33",
        "code": "FR",
        "nationality": "French",
        "alpha_3_code": "FRA"
      },
      {
        "name": "French Guiana",
        "dialCode": "594",
        "code": "GF",
        "nationality": "French Guianese",
        "alpha_3_code": "GUF"
      },
      {
        "name": "French Polynesia",
        "dialCode": "689",
        "code": "PF",
        "nationality": "French Polynesian",
        "alpha_3_code": "PYF"
      },
      {
        "name": "Gabon",
        "dialCode": "241",
        "code": "GA",
        "nationality": "Gabonese",
        "alpha_3_code": "GAB"
      },
      {
        "name": "Gambia",
        "dialCode": "220",
        "code": "GM",
        "nationality": "Gambian",
        "alpha_3_code": "GMB"
      },
      {
        "name": "Georgia",
        "dialCode": "995",
        "code": "GE",
        "nationality": "Georgian",
        "alpha_3_code": "GEO"
      },
      {
        "name": "Germany",
        "dialCode": "49",
        "code": "DE",
        "nationality": "German",
        "alpha_3_code": "DEU"
      },
      {
        "name": "Ghana",
        "dialCode": "233",
        "code": "GH",
        "nationality": "Ghanaian",
        "alpha_3_code": "GHA"
      },
      {
        "name": "Gibraltar",
        "dialCode": "350",
        "code": "GI",
        "nationality": "Gibraltar",
        "alpha_3_code": "GIB"
      },
      {
        "name": "Greece",
        "dialCode": "30",
        "code": "GR",
        "nationality": "Greek, Hellenic",
        "alpha_3_code": "GRC"
      },
      {
        "name": "Greenland",
        "dialCode": "299",
        "code": "GL",
        "nationality": "Greenlandic",
        "alpha_3_code": "GRL"
      },
      {
        "name": "Grenada",
        "dialCode": "1473",
        "code": "GD",
        "nationality": "Grenadian",
        "alpha_3_code": "GRD"
      },
      {
        "name": "Guadeloupe",
        "dialCode": "590",
        "code": "GP",
        "nationality": "Guadeloupe",
        "alpha_3_code": "GLP"
      },
      {
        "name": "Guam",
        "dialCode": "1671",
        "code": "GU",
        "nationality": "Guamanian, Guambat",
        "alpha_3_code": "GUM"
      },
      {
        "name": "Guatemala",
        "dialCode": "502",
        "code": "GT",
        "nationality": "Guatemalan",
        "alpha_3_code": "GTM"
      },
      {
        "name": "Guernsey",
        "dialCode": "44",
        "code": "GG",
        "nationality": "Channel Island",
        "alpha_3_code": "GGY"
      },
      {
        "name": "Guinea",
        "dialCode": "224",
        "code": "GN",
        "nationality": "Guinean",
        "alpha_3_code": "GIN"
      },
      {
        "name": "Guinea-Bissau",
        "dialCode": "245",
        "code": "GW",
        "nationality": "Bissau-Guinean",
        "alpha_3_code": "GNB"
      },
      {
        "name": "Guyana",
        "dialCode": "595",
        "code": "GY",
        "nationality": "Guyanese",
        "alpha_3_code": "GUY"
      },
      {
        "name": "Haiti",
        "dialCode": "509",
        "code": "HT",
        "nationality": "Haitian",
        "alpha_3_code": "HTI"
      },
      {
        "name": "Heard Island and McDonald Islands",
        "dialCode": "0",
        "code": "HM",
        "nationality": "Heard Island or McDonald Islands",
        "alpha_3_code": "HMD"
      },
      {
        "name": "Holy See (Vatican City State)",
        "dialCode": "379",
        "code": "VA",
        "nationality": "Vatican",
        "alpha_3_code": "VAT"
      },
      {
        "name": "Honduras",
        "dialCode": "504",
        "code": "HN",
        "nationality": "Honduran",
        "alpha_3_code": "HND"
      },
      {
        "name": "Hong Kong",
        "dialCode": "852",
        "code": "HK",
        "nationality": "Hong Kong, Hong Kongese",
        "alpha_3_code": "HKG"
      },
      {
        "name": "Hungary",
        "dialCode": "36",
        "code": "HU",
        "nationality": "Hungarian, Magyar",
        "alpha_3_code": "HUN"
      },
      {
        "name": "Iceland",
        "dialCode": "354",
        "code": "IS",
        "nationality": "Icelandic",
        "alpha_3_code": "ISL"
      },
      {
        "name": "India",
        "dialCode": "91",
        "code": "IN",
        "nationality": "Indian",
        "alpha_3_code": "IND"
      },
      {
        "name": "Indonesia",
        "dialCode": "62",
        "code": "ID",
        "nationality": "Indonesian",
        "alpha_3_code": "IDN"
      },
      {
        "name": "Iran, Islamic Republic of Persian Gulf",
        "dialCode": "98",
        "code": "IR",
        "nationality": "Iranian, Persian",
        "alpha_3_code": "IRN"
      },
      {
        "name": "Iraq",
        "dialCode": "964",
        "code": "IQ",
        "nationality": "Iraqi",
        "alpha_3_code": "IRQ"
      },
      {
        "name": "Ireland",
        "dialCode": "353",
        "code": "IE",
        "nationality": "Irish",
        "alpha_3_code": "IRL"
      },
      {
        "name": "Isle of Man",
        "dialCode": "44",
        "code": "IM",
        "nationality": "Manx",
        "alpha_3_code": "IMN"
      },
      {
        "name": "Israel",
        "dialCode": "972",
        "code": "IL",
        "nationality": "Israeli",
        "alpha_3_code": "ISR"
      },
      {
        "name": "Italy",
        "dialCode": "39",
        "code": "IT",
        "nationality": "Italian",
        "alpha_3_code": "ITA"
      },
      {
        "name": "Jamaica",
        "dialCode": "1876",
        "code": "JM",
        "nationality": "Jamaican",
        "alpha_3_code": "JAM"
      },
      {
        "name": "Japan",
        "dialCode": "81",
        "code": "JP",
        "nationality": "Japanese",
        "alpha_3_code": "JPN"
      },
      {
        "name": "Jersey",
        "dialCode": "44",
        "code": "JE",
        "nationality": "Channel Island",
        "alpha_3_code": "JEY"
      },
      {
        "name": "Jordan",
        "dialCode": "962",
        "code": "JO",
        "nationality": "Jordanian",
        "alpha_3_code": "JOR"
      },
      {
        "name": "Kazakhstan",
        "dialCode": "7",
        "code": "KZ",
        "nationality": "Kazakhstani, Kazakh",
        "alpha_3_code": "KAZ"
      },
      {
        "name": "Kenya",
        "dialCode": "254",
        "code": "KE",
        "nationality": "Kenyan",
        "alpha_3_code": "KEN"
      },
      {
        "name": "Kiribati",
        "dialCode": "686",
        "code": "KI",
        "nationality": "I-Kiribati",
        "alpha_3_code": "KIR"
      },
      {
        "name": "Korea, Democratic People's Republic of Korea",
        "dialCode": "850",
        "code": "KP",
        "nationality": "North Korean",
        "alpha_3_code": "PRK"
      },
      {
        "name": "Korea, Republic of South Korea",
        "dialCode": "82",
        "code": "KR",
        "nationality": "South Korean",
        "alpha_3_code": "KOR"
      },
      {
        "name": "Kuwait",
        "dialCode": "965",
        "code": "KW",
        "nationality": "Kuwaiti",
        "alpha_3_code": "KWT"
      },
      {
        "name": "Kyrgyzstan",
        "dialCode": "996",
        "code": "KG",
        "nationality": "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
        "alpha_3_code": "KGZ"
      },
      {
        "name": "Laos",
        "dialCode": "856",
        "code": "LA",
        "nationality": "Lao, Laotian",
        "alpha_3_code": "LAO"
      },
      {
        "name": "Latvia",
        "dialCode": "371",
        "code": "LV",
        "nationality": "Latvian",
        "alpha_3_code": "LVA"
      },
      {
        "name": "Lebanon",
        "dialCode": "961",
        "code": "LB",
        "nationality": "Lebanese",
        "alpha_3_code": "LBN"
      },
      {
        "name": "Lesotho",
        "dialCode": "266",
        "code": "LS",
        "nationality": "Basotho",
        "alpha_3_code": "LSO"
      },
      {
        "name": "Liberia",
        "dialCode": "231",
        "code": "LR",
        "nationality": "Liberian",
        "alpha_3_code": "LBR"
      },
      {
        "name": "Libyan Arab Jamahiriya",
        "dialCode": "218",
        "code": "LY",
        "nationality": "Libyan",
        "alpha_3_code": "LBY"
      },
      {
        "name": "Liechtenstein",
        "dialCode": "423",
        "code": "LI",
        "nationality": "Liechtenstein",
        "alpha_3_code": "LIE"
      },
      {
        "name": "Lithuania",
        "dialCode": "370",
        "code": "LT",
        "nationality": "Lithuanian",
        "alpha_3_code": "LTU"
      },
      {
        "name": "Luxembourg",
        "dialCode": "352",
        "code": "LU",
        "nationality": "Luxembourg, Luxembourgish",
        "alpha_3_code": "LUX"
      },
      {
        "name": "Macao",
        "dialCode": "853",
        "code": "MO",
        "nationality": "Macanese, Chinese",
        "alpha_3_code": "MAC"
      },
      {
        "name": "Madagascar",
        "dialCode": "261",
        "code": "MG",
        "nationality": "Malagasy",
        "alpha_3_code": "MDG"
      },
      {
        "name": "Malawi",
        "dialCode": "265",
        "code": "MW",
        "nationality": "Malawian",
        "alpha_3_code": "MWI"
      },
      {
        "name": "Malaysia",
        "dialCode": "60",
        "code": "MY",
        "nationality": "Malaysian",
        "alpha_3_code": "MYS"
      },
      {
        "name": "Maldives",
        "dialCode": "960",
        "code": "MV",
        "nationality": "Maldivian",
        "alpha_3_code": "MDV"
      },
      {
        "name": "Mali",
        "dialCode": "223",
        "code": "ML",
        "nationality": "Malian, Malinese",
        "alpha_3_code": "MLI"
      },
      {
        "name": "Malta",
        "dialCode": "356",
        "code": "MT",
        "nationality": "Maltese",
        "alpha_3_code": "MLT"
      },
      {
        "name": "Marshall Islands",
        "dialCode": "692",
        "code": "MH",
        "nationality": "Marshallese",
        "alpha_3_code": "MHL"
      },
      {
        "name": "Martinique",
        "dialCode": "596",
        "code": "MQ",
        "nationality": "Martiniquais, Martinican",
        "alpha_3_code": "MTQ"
      },
      {
        "name": "Mauritania",
        "dialCode": "222",
        "code": "MR",
        "nationality": "Mauritanian",
        "alpha_3_code": "MRT"
      },
      {
        "name": "Mauritius",
        "dialCode": "230",
        "code": "MU",
        "nationality": "Mauritian",
        "alpha_3_code": "MUS"
      },
      {
        "name": "Mayotte",
        "dialCode": "262",
        "code": "YT",
        "nationality": "Mahoran",
        "alpha_3_code": "MYT"
      },
      {
        "name": "Mexico",
        "dialCode": "52",
        "code": "MX",
        "nationality": "Mexican",
        "alpha_3_code": "MEX"
      },
      {
        "name": "Micronesia, Federated States of Micronesia",
        "dialCode": "691",
        "code": "FM",
        "nationality": "Micronesian",
        "alpha_3_code": "FSM"
      },
      {
        "name": "Moldova",
        "dialCode": "373",
        "code": "MD",
        "nationality": "Moldovan",
        "alpha_3_code": "MDA"
      },
      {
        "name": "Monaco",
        "dialCode": "377",
        "code": "MC",
        "nationality": "Monacan",
        "alpha_3_code": "MCO"
      },
      {
        "name": "Mongolia",
        "dialCode": "976",
        "code": "MN",
        "nationality": "Mongolian",
        "alpha_3_code": "MNG"
      },
      {
        "name": "Montenegro",
        "dialCode": "382",
        "code": "ME",
        "nationality": "Montenegrin",
        "alpha_3_code": "MNE"
      },
      {
        "name": "Montserrat",
        "dialCode": "1664",
        "code": "MS",
        "nationality": "Montserratian",
        "alpha_3_code": "MSR"
      },
      {
        "name": "Morocco",
        "dialCode": "212",
        "code": "MA",
        "nationality": "Moroccan",
        "alpha_3_code": "MAR"
      },
      {
        "name": "Mozambique",
        "dialCode": "258",
        "code": "MZ",
        "nationality": "Mozambican",
        "alpha_3_code": "MOZ"
      },
      {
        "name": "Myanmar",
        "dialCode": "95",
        "code": "MM",
        "nationality": "Burmese",
        "alpha_3_code": "MMR"
      },
      {
        "name": "Namibia",
        "dialCode": "264",
        "code": "NA",
        "nationality": "Namibian",
        "alpha_3_code": "NAM"
      },
      {
        "name": "Nauru",
        "dialCode": "674",
        "code": "NR",
        "nationality": "Nauruan",
        "alpha_3_code": "NRU"
      },
      {
        "name": "Nepal",
        "dialCode": "977",
        "code": "NP",
        "nationality": "Nepali, Nepalese",
        "alpha_3_code": "NPL"
      },
      {
        "name": "Netherlands",
        "dialCode": "31",
        "code": "NL",
        "nationality": "Dutch, Netherlandic",
        "alpha_3_code": "NLD"
      },
      {
        "name": "New Caledonia",
        "dialCode": "687",
        "code": "NC",
        "nationality": "New Caledonian",
        "alpha_3_code": "NCL"
      },
      {
        "name": "New Zealand",
        "dialCode": "64",
        "code": "NZ",
        "nationality": "New Zealand, NZ",
        "alpha_3_code": "NZL"
      },
      {
        "name": "Nicaragua",
        "dialCode": "505",
        "code": "NI",
        "nationality": "Nicaraguan",
        "alpha_3_code": "NIC"
      },
      {
        "name": "Niger",
        "dialCode": "227",
        "code": "NE",
        "nationality": "Nigerien",
        "alpha_3_code": "NER"
      },
      {
        "name": "Nigeria",
        "dialCode": "234",
        "code": "NG",
        "nationality": "Nigerian",
        "alpha_3_code": "NGA"
      },
      {
        "name": "Niue",
        "dialCode": "683",
        "code": "NU",
        "nationality": "Niuean",
        "alpha_3_code": "NIU"
      },
      {
        "name": "Norfolk Island",
        "dialCode": "672",
        "code": "NF",
        "nationality": "Norfolk Island",
        "alpha_3_code": "NFK"
      },
      {
        "name": "North Macedonia",
        "dialCode": "389",
        "code": "MK",
        "nationality": "Macedonian",
        "alpha_3_code": "MKD"
      },
      {
        "name": "Northern Mariana Islands",
        "dialCode": "1670",
        "code": "MP",
        "nationality": "Northern Marianan",
        "alpha_3_code": "MNP"
      },
      {
        "name": "Norway",
        "dialCode": "47",
        "code": "NO",
        "nationality": "Norwegian",
        "alpha_3_code": "NOR"
      },
      {
        "name": "Oman",
        "dialCode": "968",
        "code": "OM",
        "nationality": "Omani",
        "alpha_3_code": "OMN"
      },
      {
        "name": "Pakistan",
        "dialCode": "92",
        "code": "PK",
        "nationality": "Pakistani",
        "alpha_3_code": "PAK"
      },
      {
        "name": "Palau",
        "dialCode": "680",
        "code": "PW",
        "nationality": "Palauan",
        "alpha_3_code": "PLW"
      },
      {
        "name": "Palestinian Territory, Occupied",
        "dialCode": "970",
        "code": "PS",
        "nationality": "Palestinian",
        "alpha_3_code": "PSE"
      },
      {
        "name": "Panama",
        "dialCode": "507",
        "code": "PA",
        "nationality": "Panamanian",
        "alpha_3_code": "PAN"
      },
      {
        "name": "Papua New Guinea",
        "dialCode": "675",
        "code": "PG",
        "nationality": "Papua New Guinean, Papuan",
        "alpha_3_code": "PNG"
      },
      {
        "name": "Paraguay",
        "dialCode": "595",
        "code": "PY",
        "nationality": "Paraguayan",
        "alpha_3_code": "PRY"
      },
      {
        "name": "Peru",
        "dialCode": "51",
        "code": "PE",
        "nationality": "Peruvian",
        "alpha_3_code": "PER"
      },
      {
        "name": "Philippines",
        "dialCode": "63",
        "code": "PH",
        "nationality": "Philippine, Filipino",
        "alpha_3_code": "PHL"
      },
      {
        "name": "Pitcairn",
        "dialCode": "872",
        "code": "PN",
        "nationality": "Pitcairn Island",
        "alpha_3_code": "PCN"
      },
      {
        "name": "Poland",
        "dialCode": "48",
        "code": "PL",
        "nationality": "Polish",
        "alpha_3_code": "POL"
      },
      {
        "name": "Portugal",
        "dialCode": "351",
        "code": "PT",
        "nationality": "Portuguese",
        "alpha_3_code": "PRT"
      },
      {
        "name": "Puerto Rico",
        "dialCode": "1939",
        "code": "PR",
        "nationality": "Puerto Rican",
        "alpha_3_code": "PRI"
      },
      {
        "name": "Qatar",
        "dialCode": "974",
        "code": "QA",
        "nationality": "Qatari",
        "alpha_3_code": "QAT"
      },
      {
        "name": "Romania",
        "dialCode": "40",
        "code": "RO",
        "nationality": "Romanian",
        "alpha_3_code": "ROU"
      },
      {
        "name": "Russian Federation",
        "dialCode": "7",
        "code": "RU",
        "nationality": "Russian",
        "alpha_3_code": "RUS"
      },
      {
        "name": "Rwanda",
        "dialCode": "250",
        "code": "RW",
        "nationality": "Rwandan",
        "alpha_3_code": "RWA"
      },
      {
        "name": "Reunion",
        "dialCode": "262",
        "code": "RE",
        "nationality": "Reunionese, Reunionnais",
        "alpha_3_code": "REU"
      },
      {
        "name": "Saint Barthelemy",
        "dialCode": "590",
        "code": "BL",
        "nationality": "Barthelemois",
        "alpha_3_code": "BLM"
      },
      {
        "name": "Saint Helena, Ascension and Tristan Da Cunha",
        "dialCode": "290",
        "code": "SH",
        "nationality": "Saint Helenian",
        "alpha_3_code": "SHN"
      },
      {
        "name": "Saint Kitts and Nevis",
        "dialCode": "1869",
        "code": "KN",
        "nationality": "Kittitian or Nevisian",
        "alpha_3_code": "KNA"
      },
      {
        "name": "Saint Lucia",
        "dialCode": "1758",
        "code": "LC",
        "nationality": "Saint Lucian",
        "alpha_3_code": "LCA"
      },
      {
        "name": "Saint Martin",
        "dialCode": "590",
        "code": "MF",
        "nationality": "Saint-Martinoise",
        "alpha_3_code": "MAF"
      },
      {
        "name": "Saint Pierre and Miquelon",
        "dialCode": "508",
        "code": "PM",
        "nationality": "Saint-Pierrais or Miquelonnais",
        "alpha_3_code": "SPM"
      },
      {
        "name": "Saint Vincent and the Grenadines",
        "dialCode": "1784",
        "code": "VC",
        "nationality": "Saint Vincentian, Vincentian",
        "alpha_3_code": "VCT"
      },
      {
        "name": "Samoa",
        "dialCode": "685",
        "code": "WS",
        "nationality": "Samoan",
        "alpha_3_code": "WSM"
      },
      {
        "name": "San Marino",
        "dialCode": "378",
        "code": "SM",
        "nationality": "Sammarinese",
        "alpha_3_code": "SMR"
      },
      {
        "name": "Sao Tome and Principe",
        "dialCode": "239",
        "code": "ST",
        "nationality": "Sao Tome and Principan",
        "alpha_3_code": "STP"
      },
      {
        "name": "Saudi Arabia",
        "dialCode": "966",
        "code": "SA",
        "nationality": "Saudi, Saudi Arabian",
        "alpha_3_code": "SAU"
      },
      {
        "name": "Senegal",
        "dialCode": "221",
        "code": "SN",
        "nationality": "Senegalese",
        "alpha_3_code": "SEN"
      },
      {
        "name": "Serbia",
        "dialCode": "381",
        "code": "RS",
        "nationality": "Serbian",
        "alpha_3_code": "SRB"
      },
      {
        "name": "Seychelles",
        "dialCode": "248",
        "code": "SC",
        "nationality": "Seychellois",
        "alpha_3_code": "SYC"
      },
      {
        "name": "Sierra Leone",
        "dialCode": "232",
        "code": "SL",
        "nationality": "Sierra Leonean",
        "alpha_3_code": "SLE"
      },
      {
        "name": "Singapore",
        "dialCode": "65",
        "code": "SG",
        "nationality": "Singaporean",
        "alpha_3_code": "SGP"
      },
      {
        "name": "Sint Maarten (Dutch part)",
        "dialCode": "1",
        "code": "SX",
        "nationality": "Sint Maarten",
        "alpha_3_code": "SXM"
      },
      {
        "name": "Slovakia",
        "dialCode": "421",
        "code": "SK",
        "nationality": "Slovak",
        "alpha_3_code": "SVK"
      },
      {
        "name": "Slovenia",
        "dialCode": "386",
        "code": "SI",
        "nationality": "Slovenian, Slovene",
        "alpha_3_code": "SVN"
      },
      {
        "name": "Solomon Islands",
        "dialCode": "677",
        "code": "SB",
        "nationality": "Solomon Island",
        "alpha_3_code": "SLB"
      },
      {
        "name": "Somalia",
        "dialCode": "252",
        "code": "SO",
        "nationality": "Somali, Somalian",
        "alpha_3_code": "SOM"
      },
      {
        "name": "South Africa",
        "dialCode": "27",
        "code": "ZA",
        "nationality": "South African",
        "alpha_3_code": "ZAF"
      },
      {
        "name": "South Sudan",
        "dialCode": "211",
        "code": "SS",
        "nationality": "South Sudanese",
        "alpha_3_code": "SSD"
      },
      {
        "name": "South Georgia and the South Sandwich Islands",
        "dialCode": "500",
        "code": "GS",
        "nationality": "South Georgia or South Sandwich Islands",
        "alpha_3_code": "SGS"
      },
      {
        "name": "Spain",
        "dialCode": "34",
        "code": "ES",
        "nationality": "Spanish",
        "alpha_3_code": "ESP"
      },
      {
        "name": "Sri Lanka",
        "dialCode": "94",
        "code": "LK",
        "nationality": "Sri Lankan",
        "alpha_3_code": "LKA"
      },
      {
        "name": "Sudan",
        "dialCode": "249",
        "code": "SD",
        "nationality": "Sudanese",
        "alpha_3_code": "SDN"
      },
      {
        "name": "Suriname",
        "dialCode": "597",
        "code": "SR",
        "nationality": "Surinamese",
        "alpha_3_code": "SUR"
      },
      {
        "name": "Svalbard and Jan Mayen",
        "dialCode": "47",
        "code": "SJ",
        "nationality": "Svalbard",
        "alpha_3_code": "SJM"
      },
      {
        "name": "Sweden",
        "dialCode": "46",
        "code": "SE",
        "nationality": "Swedish",
        "alpha_3_code": "SWE"
      },
      {
        "name": "Switzerland",
        "dialCode": "41",
        "code": "CH",
        "nationality": "Swiss",
        "alpha_3_code": "CHE"
      },
      {
        "name": "Syrian Arab Republic",
        "dialCode": "963",
        "code": "SY",
        "nationality": "Syrian",
        "alpha_3_code": "SYR"
      },
      {
        "name": "Taiwan",
        "dialCode": "886",
        "code": "TW",
        "nationality": "Chinese, Taiwanese",
        "alpha_3_code": "TWN"
      },
      {
        "name": "Tajikistan",
        "dialCode": "992",
        "code": "TJ",
        "nationality": "Tajikistani",
        "alpha_3_code": "TJK"
      },
      {
        "name": "Tanzania, United Republic of Tanzania",
        "dialCode": "255",
        "code": "TZ",
        "nationality": "Tanzanian",
        "alpha_3_code": "TZA"
      },
      {
        "name": "Thailand",
        "dialCode": "66",
        "code": "TH",
        "nationality": "Thai",
        "alpha_3_code": "THA"
      },
      {
        "name": "Timor-Leste",
        "dialCode": "670",
        "code": "TL",
        "nationality": "Timorese",
        "alpha_3_code": "TLS"
      },
      {
        "name": "Togo",
        "dialCode": "228",
        "code": "TG",
        "nationality": "Togolese",
        "alpha_3_code": "TGO"
      },
      {
        "name": "Tokelau",
        "dialCode": "690",
        "code": "TK",
        "nationality": "Tokelauan",
        "alpha_3_code": "TKL"
      },
      {
        "name": "Tonga",
        "dialCode": "676",
        "code": "TO",
        "nationality": "Tongan",
        "alpha_3_code": "TON"
      },
      {
        "name": "Trinidad and Tobago",
        "dialCode": "1868",
        "code": "TT",
        "nationality": "Trinidadian or Tobagonian",
        "alpha_3_code": "TTO"
      },
      {
        "name": "Tunisia",
        "dialCode": "216",
        "code": "TN",
        "nationality": "Tunisian",
        "alpha_3_code": "TUN"
      },
      {
        "name": "Turkey",
        "dialCode": "90",
        "code": "TR",
        "nationality": "Turkish",
        "alpha_3_code": "TUR"
      },
      {
        "name": "Turkmenistan",
        "dialCode": "993",
        "code": "TM",
        "nationality": "Turkmen",
        "alpha_3_code": "TKM"
      },
      {
        "name": "Turks and Caicos Islands",
        "dialCode": "1649",
        "code": "TC",
        "nationality": "Turks and Caicos Island",
        "alpha_3_code": "TCA"
      },
      {
        "name": "Tuvalu",
        "dialCode": "688",
        "code": "TV",
        "nationality": "Tuvaluan",
        "alpha_3_code": "TUV"
      },
      {
        "name": "Uganda",
        "dialCode": "256",
        "code": "UG",
        "nationality": "Ugandan",
        "alpha_3_code": "UGA"
      },
      {
        "name": "Ukraine",
        "dialCode": "380",
        "code": "UA",
        "nationality": "Ukrainian",
        "alpha_3_code": "UKR"
      },
      {
        "name": "United Arab Emirates",
        "dialCode": "971",
        "code": "AE",
        "nationality": "Emirati, Emirian, Emiri",
        "alpha_3_code": "ARE"
      },
      {
        "name": "United Kingdom",
        "dialCode": "44",
        "code": "GB",
        "nationality": "British, UK",
        "alpha_3_code": "GBR"
      },
      {
        "name": "United States",
        "dialCode": "1",
        "code": "US",
        "nationality": "American",
        "alpha_3_code": "USA"
      },
      {
        "name": "United States Minor Outlying Islands",
        "dialCode": "246",
        "code": "UM",
        "nationality": "American",
        "alpha_3_code": "UMI"
      },
      {
        "name": "Uruguay",
        "dialCode": "598",
        "code": "UY",
        "nationality": "Uruguayan",
        "alpha_3_code": "URY"
      },
      {
        "name": "Uzbekistan",
        "dialCode": "998",
        "code": "UZ",
        "nationality": "Uzbekistani, Uzbek",
        "alpha_3_code": "UZB"
      },
      {
        "name": "Vanuatu",
        "dialCode": "678",
        "code": "VU",
        "nationality": "Ni-Vanuatu, Vanuatuan",
        "alpha_3_code": "VUT"
      },
      {
        "name": "Venezuela, Bolivarian Republic of Venezuela",
        "dialCode": "58",
        "code": "VE",
        "nationality": "Venezuelan",
        "alpha_3_code": "VEN"
      },
      {
        "name": "Vietnam",
        "dialCode": "84",
        "code": "VN",
        "nationality": "Vietnamese",
        "alpha_3_code": "VNM"
      },
      {
        "name": "Virgin Islands, British",
        "dialCode": "1284",
        "code": "VG",
        "nationality": "British Virgin Island",
        "alpha_3_code": "VGB"
      },
      {
        "name": "Virgin Islands, U.S.",
        "dialCode": "1340",
        "code": "VI",
        "nationality": "U.S. Virgin Island",
        "alpha_3_code": "VIR"
      },
      {
        "name": "Wallis and Futuna",
        "dialCode": "681",
        "code": "WF",
        "nationality": "Wallis and Futuna, Wallisian or Futunan",
        "alpha_3_code": "WLF"
      },
      {
        "name": "Yemen",
        "dialCode": "967",
        "code": "YE",
        "nationality": "Yemeni",
        "alpha_3_code": "YEM"
      },
      {
        "name": "Zambia",
        "dialCode": "260",
        "code": "ZM",
        "nationality": "Zambian",
        "alpha_3_code": "ZMB"
      },
      {
        "name": "Zimbabwe",
        "dialCode": "263",
        "code": "ZW",
        "nationality": "Zimbabwean",
        "alpha_3_code": "ZWE"
      },
      {
        "name": "French Southern Territories",
        "dialCode": "262",
        "code": "TF",
        "nationality": "French Southern Territories",
        "alpha_3_code": "ATF"
      },
      {
        "code": "EH",
        "name": "Western Sahara",
        "dialCode": "212",
        "nationality": "Sahrawi, Sahrawian, Sahraouian",
        "alpha_3_code": "ESH"
      }
    ]

  }

}
