<app-header></app-header>
<main class="page-content page-content_block">
  <div class="container">
    <article class="section section_page">
      <div class="section__header">
        <h1 class="page-title">Cookie Policy
        </h1>
      </div>
      <section class="section__row">
        <h4>What are cookies?
        </h4>
        <p>
          Cookies are small text files that are sent and downloaded to your computer, smartphone or other device for accessing the internet, whenever you visit a website.
        </p>
        <p>They allow a website to recognise a user’s device and they are widely used and serve to allow access to websites or to improve the usability of websites. Cookies collect information about how visitors use the website. The information collected includes the number of site visitors, where visitors come to the website from and the pages they visited.</p>
        <p>The cookies we place on your computer or other device cannot be used to retrieve any other data from your hard drive, to pass viruses to your computer or other device, or to capture your e-mail address. Each cookie is unique to your web browser and device.</p>
        <p>There are two main types of cookies: session cookies and persistent cookies. A “session cookie” is a cookie that is automatically deleted when you closes your browser, while a “persistent cookie” is a cookie that remains stored in your device until it reaches a defined expiration date.</p>
        <p>Some of the functions that cookies perform can also be achieved using similar technologies. This policy refers to ‘cookies’ throughout, however it also covers these similar technologies which store or access information in your computer, smartphone or other device.
        </p>
      </section>
      <section class="section__row">
        <h4>Why does Papaya use cookies?
        </h4>
        <p>Cookies help us track how visitors use our website. At Papaya, we use cookies for different reasons, including to enhance your visit to our website, determine your preferences, let users navigate between pages, to store your login, password and codes securely, to safeguard your privacy when you are browsing our website and to identify which parts of a site you have visited.</p>
      </section>
      <section class="section__row">
        <h4>Papaya’s website may use the following types of cookies:
        </h4>
        <p>– Required / essential cookies – vital to the basic performance of the Papaya website, including cookies that identify you when log in to your Papaya account. They enable critical authentication and validation, allow you to move freely through our website and use our services as you wish. These cookies are activated when you visit our website and remain active for the duration of your visit.</p>
        <p>– Performance cookies – these track which pages you visit frequently and how you use the website (including error messages), its links and help us provide you with a better service. These cookies are not used to collect your personal information for advertising purposes.</p>
        <p>– Functionality cookies – provide you with additional functionality (personalised service) which record changes to the settings of our website which you have previously made, such as the region you are visiting the website from, language preference, font size.</p>
        <p>– Third-party cookies – when you visit a web page within our website it is possible that they will place cookies on your device. We have no control over these cookies.</p>
        <p>The table below explains the cookies we use and why.
        </p>
        <div class="cookie-table table-wrapper">
          <table>
            <colgroup>
              <col width="280">
              <col width="320">
              <col width="230">
            </colgroup>
            <tr>
              <th><b>Cookie Name</b></th>
              <th><b>Purpose</b></th>
              <th><b>Duration</b></th>
            </tr>
            <tr>
              <td>Google Analytics1)  __utmc2)  __utmb3)  __utmt4)  __utmz5)  __utma</td>
              <td>
                <p>These cookies are used to collect information about how visitors use our site. We use the information to compile reports and to help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from and the pages they visited.</p>
                <p>Click here for an overview of privacy at Google<br> <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></p>
              </td>
              <td>
                <p>1) End of browser session</p>
                <p>2) 30 mins from set/update</p>
                <p>3) 10 minutes</p>
                <p>4) 6 months from set/update</p>
                <p>5) 2 years from set/update</p>
              </td>
            </tr>
            <tr>
              <td>_icl_current_language_icl_visitor_lang_js</td>
              <td><p>These cookies let us keep track of the navigation language so, once a language is selected or changed, the rest of the navigation experience will be in that language.</p></td>
              <td class="align-middle"><p>During the session</p></td>
            </tr>
            <tr>
              <td>viewed_cookie_policyeuCookie</td>
              <td><p>Necessary for the visualisation of this Cookie Policy.</p></td>
              <td class="align-middle"><p>During the session</p></td>
            </tr>
          </table>
        </div>
      </section>
      <section class="section__row">
        <h4>Managing your cookies – how to delete or block Cookies?</h4>
        <p>By using our website, you agree to cookies being set by our website.</p>
        <p>You can delete cookies in the settings of your browser. You can also restrict or block cookies in the settings of your browser (only allowing certain cookies or no cookies at all to be used) or request your browser to let you know each time that a website tries to set a cookie. However, deleting or refusing cookies may mean some sections of the site will not work properly.</p>
      </section>
      <section class="section__row">
        <h4>More about Cookies and privacy</h4>
        <p>More information on the uses of cookies: <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a></p>
        <p>To find out more about our privacy policy please check our Privacy and <a routerLink="/privacy-policy">Data Protection Policy</a> and see our <a routerLink="/terms-conditions">Terms and Conditions</a>.</p>
      </section>
    </article>
  </div>
</main>
<app-footer></app-footer>
