<app-header></app-header>
<main>
  <section class="complaints">
    <div class="container">
      <h1 class="page-title">Complaints</h1>
      <strong *ngIf="this.isSuccess">
        Thank you for submitting your complaint to Papaya Ltd. We have
        successfully received your submission and are currently reviewing the
        details.
      </strong>
      <br />
      <form [formGroup]="mailForm" class="complaints__form">
        <h2 class="complaints__title h3">Client information</h2>
        <div class="complaints__inputs form-group">
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('name')"
          >
            <input
              formControlName="name"
              class="input-control"
              type="text"
              name="name"
              placeholder="Your name"
            />
            <span class="label-error" *ngIf="this.isError('name')"
              >Required</span
            >
          </div>
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('email')"
          >
            <input
              formControlName="email"
              class="input-control"
              type="email"
              name="email"
              placeholder="Your email"
            />
            <span class="label-error" *ngIf="this.isError('email')"
              >Required</span
            >
          </div>
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('email')"
          >
            <input
              formControlName="mobilePhoneNumber"
              class="input-control"
              type="text"
              name="mobilePhoneNumber"
              placeholder="Mobile phone number"
            />
            <span class="label-error" *ngIf="this.isError('mobilePhoneNumber')"
              >Required</span
            >
          </div>
        </div>
        <h2 class="complaints__title h3">Details</h2>
        <div class="complaints__inputs form-group">
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('complaintSubject')"
          >
            <input
              formControlName="complaintSubject"
              class="input-control"
              type="text"
              name="complaintSubject"
              placeholder="Complaint subject"
            />
            <span class="label-error" *ngIf="this.isError('complaintSubject')"
              >Required, max length 100</span
            >
          </div>

          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('complaintSummary')"
          >
            <textarea
              formControlName="complaintSummary"
              class="input-control"
              name="complaintSummary"
              rows="4"
              placeholder="Complaint summary"
            ></textarea>
            <span class="label-error" *ngIf="this.isError('complaintSummary')"
              >Required, max length 200</span
            >
          </div>
          <div class="complaints__control-wrapper">
            <input
              formControlName="relevantDateOfComplaintEvent"
              class="input-control"
              type="date"
              name="relevantDateOfComplaintEvent"
              placeholder="Relevant date of complaint event"
            />
          </div>
          <div class="complaints__control-wrapper">
            <input
              formControlName="attachments"
              class="input-control"
              type="file"
              multiple
              accept=".pdf,.jpg,.png"
              name="attachments"
              placeholder="attachments"
              (change)="onAttachmentsChange($event)"
            />
          </div>
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('agreement')"
          >
            <div class="complaints__checkbox-wrap">
              <input
                class="complaints__checkbox"
                type="checkbox"
                id="agreement"
                formControlName="agreement"
              />
              <label for="agreement"
                >I agree with the terms of the privacy policy of Papaya Ltd. and
                consent to the processing of my personal data for the purpose of
                considering the complaint.</label
              >
            </div>
            <span class="label-error" *ngIf="this.isError('agreement')"
              >Required</span
            >
          </div>
        </div>

        <div class="complaints__submit">
          <button
            class="button button_orange-fill"
            type="submit"
            (click)="submit($event)"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  </section>
</main>
<app-footer></app-footer>
