<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">Getting Started Guide</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <h4>Environments</h4>
          <p>These are the URLs for the endpoints to get access to environments.</p>
          <p>Sandbox:<br> <strong>https://tpp-sandbox.papaya.eu/banklink/{{ '{' }}service{{ '}' }}</strong></p>
          <p>Production:<br> <strong>https://tpp.papaya.eu/banklink/{{ '{' }}service{{ '}' }}</strong></p>
          <p>Sandbox is isolated from the production environment, so operations that you perform in the Financial Organisation sandbox don’t affect the production environment. The production version of the API provides access to the real customer data, i.e. you will be able to initiate real payments with PSU permission. Another API KEY will be generated for using in your application in production environment.</p>
          <h4>Registration in Portal</h4>
          <p>To use Developer Portal, you need to REGISTER for an account. Papaya Ltd collects certain in formation for your Developer Portal Profile in order to provide access to the portal and its services. In your “profile settings”, you can change your profile information, password and add eIDAS certificate public key. When you have an account, you will be able to create applications and test the integration of our APIs. Apps provide an API Key that is used to verify the subscriptions of your app.</p>
          <p>Read and accept the <a routerLink="/openbanking-terms-and-conditions">Terms and Conditions</a> and the <a routerLink="/privacy-policy">Data Privacy Notice</a>.</p>
          <p>After submitting your first registration form you will receive an e-mail with an activation link.</p>
          <p>Click on the link “ACTIVATE YOUR ACCOUNT” in the received e-mail. This action will complete your registration process, activate your account and transfer you to the “Sign In” page.</p>
          <p><strong>Note:</strong><br>What would you fully start working with Financial Institution API in production, make sure you have the NCA license and eIDAS certificates QSEAL and QWAC. Each country has their own guidelines on obtaining a license, please contact with your local NCA.</p>
          <h4>Sign In</h4>
          <p>After successful registration and activation TPP can sign in to the Financial Institution portal.</p>
          <p class="mb-0">Sign In page:</p>
          <ul class="list-unstyled mb-0">
            <li><a href="https://tpp-sandbox.papaya.eu/console">https://tpp-sandbox.papaya.eu/console</a> – (sandbox)</li>
            <li><a href="https://tpp.papaya.eu/console">https://tpp.papaya.eu/console</a> – (live)</li>
          </ul>
          <p>Type in a username or e-mail and password and click SIGN IN button. By signing in TPP gets access to their unique API key, documentation, API explorer and code examples.</p>
          <h4>Create an Application</h4>
          <p>The next step is to configure your application (My Apps). Apps keep track of your credentials for using our APIs. For each app you will receive an API key.</p>
          <h4>Subscribe to an API</h4>
          <p>In order to test APIs in the Portal Sandbox your application must be subscribed to an API. You can subscribe to APIs from the available API Sets when creating an application directly. You can view and manage your subscription including API versions in each application.</p>
          <h4>API Catalog</h4>
          <p>Without registration you can see only API Catalog where we provide list of supported API’s and short description. If you need more information about API’s you need to register in to this portal.</p>
          <h4>API Reference</h4>
          <p>The Documentation enables you to quickly understand and see raw API calls and the data returned by these calls. In order to read and use it, you will need registration credentials.</p>
          <h4>API Key</h4>
          <p>To start using Papaya’s Open APIs, TPP must obtain their unique API key. Copy your unique API key from My Apps section in the Sandbox or production part. (/console/applications).<br> API key must be included in every API call as a HTTP header with the name: “X-Api-Key”. For example: {{ '{' }}“X-API-Key”: “bf40bc62-5327-4966-bcb5eb5468e92d57”{{ '}' }}</p>
          <p>
            <strong>note:</strong><br> API request without a valid API Key will lead to “Error 404” message.
          </p>
          <p>
            <strong>note:</strong><br> API Key is specific to TPP and its applications. TPP must not disclose their API Key to any other person. <strong>Test using Postman tool</strong>
          </p>
          <p>Postman is great tool to test and integrate APIs. If you don’t have Postman, download it <a href="https://www.getpostman.com/products">here</a>. See Postman’s documentation and tutorials for any questions about using Postman.</p>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
