import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistic-q22022',
  templateUrl: './statistic-q22022.component.html',
  styleUrls: ['./statistic-q22022.component.scss']
})
export class StatisticQ22022Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
