<div class="section partners" id="partners">
  <div class="container">
    <div class="section__header">
      <h2 class="section__title js-fade-up">Partners</h2>
      <p class="section__desc js-fade-up">Banking services, bonus programs, co-branded payment cards, white-label mobile
          and online banking and any other customized solution for your business.</p>
    </div>
    <div class="partners__row">
      <div class="partners__item js-fade-up-block">
        <div class="partner">
          <div class="partner__top">
            <h3 class="partner__title">
              <img src="assets/img/partners/blackcatcard.png" srcset="assets/img/partners/blackcatcard@2x.png 2x" alt="blackcatcard">
              <span class="partner__title-hidden">blackcatcard</span>
            </h3>
          </div>
          <div class="partner__body">
            <p class="partner__text">The most advanced mobile banking with the flexible payment card options. Opening an account online<br>
                in 5 minutes. Multiple cards linked to one account, cashback programs makes this product unique on the market.</p>
          </div>
          <div class="partner__footer">
            <a href="https://blackcatcard.com" target="_blank" class="button button_orange">blackcatcard.com</a>
          </div>
        </div>
      </div>
      <!---<div class="partners__item js-fade-up-block">
        <div class="partner">
          <div class="partner__top">
            <h3 class="partner__title">
                <img src="assets/img/partners/one-touch.png" srcset="assets/img/partners/one-touch@2x.png 2x" alt="one-touch">
                <span class="partner__title-hidden">One Touch Private Club</span>
            </h3>
          </div>
          <div class="partner__body">
            <p class="partner__text">A private concierge service ONETOUCH provides its customers with a premium assistance in their business or private activities. Service has its own premium payment cards which helps assistants to proceed with client's payments instantly. *</p>
          </div>
          <div class="partner__footer">
            <a href="https://1touch.club" target="_blank" class="button button_orange">1touch.club</a>
          </div>
        </div>
        <div class="partners__item-note-text">* Product is under testing</div>
      </div> -->
      <!-- <div class="partners__item js-fade-up-block">
        <div class="partner">
          <div class="partner__top">
              <h3 class="partner__title">
                  <img src="assets/img/partners/gekkard.png" srcset="assets/img/partners/gekkard@2x.png 2x" alt="gekkard">
                  <span class="partner__title-hidden">Gekkard</span>
              </h3>
          </div>
          <div class="partner__body">
            <p class="partner__text">A co-branded mobile App and a payment card. Gives Gekkoin crypto investors an opportunity to have a quick access to their coins and convert them into fiat money. Money will be available instantly.</p>
          </div>
          <div class="partner__footer">
            <a href="https://gekkoin.com" target="_blank" class="button button_orange">gekkoin.com</a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
