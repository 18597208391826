<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12 section_d-block">
      <div class="section__header">
        <h1 class="page-title">Currency conversions for non Euro purchases and withdrawals</h1>
      </div>
      <p>When you use your card while travelling in a non-euro country, you may be able to choose to pay in the local currency of the country you are in or in euro.</p>
      <p>
        If you choose to pay in the local currency, Papaya LTD  will convert the amount of the transaction using the daily card exchange rate provided by the relevant Card Scheme of Mastercard. If you choose to pay in euro, currency conversion will be done on the spot by the merchant in exchange for a fee. When you are offered currency conversion services at either POS / ATM, the provider should disclose total charges for dynamic currency conversion, including any fixed fees they will apply, as a percentage mark-up over the latest available Foreign Exchange Rate(s) issued by the European Central Bank. The table below shows the % difference between the daily European Central Bank Rate, and the total cost you would be charged for using your card abroad providing you with full information about the costs of using your debit or credit card outside the euro zone.<br />
        You can see our current mark-ups for each EEA currency below. The tables below are updated regularly using recently published ECB reference exchange rates.
      </p>
      <div class="table-wrapper mb-4">
        <table class="table-border table-bordered">
          <thead>
          <tr>
            <th>&nbsp;</th>
            <th>ECB</th>
            <th>MasterCard</th>
            <th>Papaya</th>
            <th>Difference</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let rate of rates">
            <td>{{rate.code}}</td>
            <td>{{rate.ecb | number : '.2-2'}}</td>
            <td>{{rate.mcc | number : '.2-2'}}</td>
            <td>{{rate.pap | number : '.2-2'}}</td>
            <td>{{rate.ecb != '-' ?
              (((rate.pap - rate.ecb) / rate.ecb * 100) > 0 ?
                '+'+(((rate.pap - rate.ecb) / rate.ecb * 100) | number : '.2-2')
              : ((rate.pap - rate.ecb) / rate.ecb * 100) | number : '.2-2') + '%'
              : '-' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <p>*However, please be aware that the mark-ups displayed below may differ from the actual currency conversion charge applied to your payment, because ECB and Mastercard rates may change between updates to the tables.</p>
    </article>
  </div>
</main>
<app-footer></app-footer>
