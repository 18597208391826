<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
            <tr>
                <th>Date</th>
                <th>Channel</th>
                <th>Uptime, <br /> %</th>
                <th>Downtime, <br /> %</th>
                <th>AIS <br /> performance, <br /> ms</th>
                <th>PIS <br /> performance, <br /> ms</th>
                <th>CoF <br /> performance, <br /> ms</th>
                <th>Error response <br /> rate, <br /> %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td rowspan="3" class="text-nowrap">01.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>287</td>
                <td>72</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>119</td>
                <td>681</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>460</td>
                <td>414</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">02.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>118</td>
                <td>313</td>
                <td>75</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>154</td>
                <td>765</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>526</td>
                <td>462</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">03.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>333</td>
                <td>98</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>230</td>
                <td>1048</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>996</td>
                <td>715</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">04.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>330</td>
                <td>91</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>200</td>
                <td>928</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>825</td>
                <td>644</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">05.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>336</td>
                <td>96</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>216</td>
                <td>992</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>824</td>
                <td>664</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">06.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>134</td>
                <td>319</td>
                <td>91</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>200</td>
                <td>935</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>808</td>
                <td>628</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">07.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>338</td>
                <td>99</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>245</td>
                <td>1091</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>790</td>
                <td>647</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">08.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>298</td>
                <td>73</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>129</td>
                <td>721</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>466</td>
                <td>451</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">09.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>306</td>
                <td>82</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>756</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>495</td>
                <td>490</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>180</td>
                <td>332</td>
                <td>119</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>309</td>
                <td>1156</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1028</td>
                <td>839</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>260</td>
                <td>95</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>213</td>
                <td>746</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>823</td>
                <td>663</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>257</td>
                <td>96</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>224</td>
                <td>777</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>859</td>
                <td>663</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">13.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>264</td>
                <td>112</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>206</td>
                <td>712</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>819</td>
                <td>652</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">14.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>269</td>
                <td>97</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>212</td>
                <td>753</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>810</td>
                <td>684</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">15.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>233</td>
                <td>75</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>135</td>
                <td>524</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>456</td>
                <td>411</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">16.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>115</td>
                <td>244</td>
                <td>74</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>531</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>497</td>
                <td>441</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">17.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>293</td>
                <td>108</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>251</td>
                <td>933</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>942</td>
                <td>723</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">18.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>233</td>
                <td>85</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>200</td>
                <td>709</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>818</td>
                <td>631</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">19.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>232</td>
                <td>85</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>208</td>
                <td>698</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>800</td>
                <td>616</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">20.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>131</td>
                <td>248</td>
                <td>92</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>220</td>
                <td>737</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>799</td>
                <td>635</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">21.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>129</td>
                <td>254</td>
                <td>80</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>204</td>
                <td>706</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>792</td>
                <td>632</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">22.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>110</td>
                <td>226</td>
                <td>71</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>495</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>484</td>
                <td>453</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">23.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>110</td>
                <td>225</td>
                <td>72</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>508</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>521</td>
                <td>505</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">24.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>253</td>
                <td>100</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>251</td>
                <td>868</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>946</td>
                <td>750</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">25.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>219</td>
                <td>82</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>222</td>
                <td>758</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>867</td>
                <td>680</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">26.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>132</td>
                <td>221</td>
                <td>87</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>212</td>
                <td>717</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>821</td>
                <td>633</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">27.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>214</td>
                <td>86</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>207</td>
                <td>700</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>815</td>
                <td>623</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">28.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>129</td>
                <td>228</td>
                <td>91</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>206</td>
                <td>699</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>814</td>
                <td>642</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">29.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>106</td>
                <td>196</td>
                <td>68</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>126</td>
                <td>478</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>464</td>
                <td>427</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">30.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>113</td>
                <td>208</td>
                <td>73</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>150</td>
                <td>506</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>543</td>
                <td>519</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">31.01.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>242</td>
                <td>98</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>265</td>
                <td>905</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1008</td>
                <td>775</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">01.02.2022</td>
                <td>Open Banking API</td>
                <td>94.00%</td>
                <td>6.00%</td>
                <td>161</td>
                <td>254</td>
                <td>91</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>247</td>
                <td>858</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>563</td>
                <td>442</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">02.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>129</td>
                <td>207</td>
                <td>85</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>214</td>
                <td>725</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>548</td>
                <td>422</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">03.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>125</td>
                <td>207</td>
                <td>83</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>235</td>
                <td>785</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>549</td>
                <td>419</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">04.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>226</td>
                <td>93</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>228</td>
                <td>751</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>581</td>
                <td>425</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">05.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>194</td>
                <td>69</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>131</td>
                <td>495</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>406</td>
                <td>326</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">06.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>107</td>
                <td>201</td>
                <td>71</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>506</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>428</td>
                <td>346</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">07.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>242</td>
                <td>96</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>263</td>
                <td>868</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>636</td>
                <td>463</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">08.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>187</td>
                <td>277</td>
                <td>125</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>252</td>
                <td>824</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>590</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">09.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>283</td>
                <td>125</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>328</td>
                <td>918</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>590</td>
                <td>425</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>215</td>
                <td>321</td>
                <td>167</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>292</td>
                <td>944</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>612</td>
                <td>441</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>257</td>
                <td>114</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>246</td>
                <td>834</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>618</td>
                <td>440</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12.02.2022</td>
                <td>Open Banking API</td>
                <td>80.00%</td>
                <td>20.00%</td>
                <td>117</td>
                <td>203</td>
                <td>89</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>80.00%</td>
                <td>20.00%</td>
                <td>138</td>
                <td>553</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>80.00%</td>
                <td>20.00%</td>
                <td>408</td>
                <td>321</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">13.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>200</td>
                <td>73</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>166</td>
                <td>500</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>428</td>
                <td>339</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">14.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>154</td>
                <td>230</td>
                <td>101</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>252</td>
                <td>823</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>623</td>
                <td>461</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">15.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>151</td>
                <td>226</td>
                <td>100</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>251</td>
                <td>820</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>603</td>
                <td>446</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">16.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>124</td>
                <td>209</td>
                <td>81</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>169</td>
                <td>606</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>572</td>
                <td>443</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">17.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>268</td>
                <td>113</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>221</td>
                <td>772</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>598</td>
                <td>451</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">18.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>215</td>
                <td>92</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>220</td>
                <td>755</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>610</td>
                <td>435</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">19.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>197</td>
                <td>71</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>492</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>427</td>
                <td>351</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">20.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>110</td>
                <td>201</td>
                <td>75</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>516</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>468</td>
                <td>388</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">21.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>153</td>
                <td>230</td>
                <td>109</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>280</td>
                <td>886</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>662</td>
                <td>515</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">22.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>200</td>
                <td>87</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>227</td>
                <td>727</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>565</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">23.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>209</td>
                <td>93</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>215</td>
                <td>708</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>538</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">24.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>120</td>
                <td>189</td>
                <td>82</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>204</td>
                <td>648</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>539</td>
                <td>417</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">25.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>131</td>
                <td>210</td>
                <td>95</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>231</td>
                <td>734</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>592</td>
                <td>458</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">26.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>179</td>
                <td>69</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>486</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>414</td>
                <td>377</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">27.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>110</td>
                <td>180</td>
                <td>74</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>501</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>452</td>
                <td>400</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">28.02.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>150</td>
                <td>216</td>
                <td>109</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>300</td>
                <td>916</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>628</td>
                <td>468</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">01.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>206</td>
                <td>93</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>245</td>
                <td>787</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>578</td>
                <td>472</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">02.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>198</td>
                <td>94</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>238</td>
                <td>776</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>554</td>
                <td>404</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">03.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>201</td>
                <td>95</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>263</td>
                <td>836</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>514</td>
                <td>401</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">04.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>225</td>
                <td>135</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>263</td>
                <td>797</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>540</td>
                <td>401</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">05.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>186</td>
                <td>82</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>510</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>385</td>
                <td>308</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">06.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>117</td>
                <td>190</td>
                <td>77</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>181</td>
                <td>549</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>418</td>
                <td>333</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">07.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>190</td>
                <td>262</td>
                <td>135</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>338</td>
                <td>1001</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>592</td>
                <td>431</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">08.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>241</td>
                <td>111</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>262</td>
                <td>810</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>538</td>
                <td>388</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">09.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>163</td>
                <td>242</td>
                <td>112</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>286</td>
                <td>856</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>561</td>
                <td>392</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>185</td>
                <td>268</td>
                <td>133</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>346</td>
                <td>965</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>579</td>
                <td>407</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>119</td>
                <td>193</td>
                <td>80</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>181</td>
                <td>624</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>571</td>
                <td>380</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>107</td>
                <td>185</td>
                <td>83</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>132</td>
                <td>496</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>372</td>
                <td>289</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">13.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>190</td>
                <td>76</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>173</td>
                <td>524</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>398</td>
                <td>302</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">14.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>219</td>
                <td>117</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>297</td>
                <td>918</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>596</td>
                <td>411</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">15.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>192</td>
                <td>124</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>331</td>
                <td>884</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>578</td>
                <td>392</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">16.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>107</td>
                <td>184</td>
                <td>87</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>247</td>
                <td>729</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>548</td>
                <td>377</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">17.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>109</td>
                <td>181</td>
                <td>78</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>235</td>
                <td>676</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>549</td>
                <td>373</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">18.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>106</td>
                <td>183</td>
                <td>79</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>350</td>
                <td>724</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>570</td>
                <td>383</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">19.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>91</td>
                <td>169</td>
                <td>66</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>237</td>
                <td>466</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>389</td>
                <td>295</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">20.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>95</td>
                <td>178</td>
                <td>69</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>258</td>
                <td>520</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>406</td>
                <td>305</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">21.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>113</td>
                <td>185</td>
                <td>72</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>346</td>
                <td>841</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>598</td>
                <td>409</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">22.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>106</td>
                <td>180</td>
                <td>78</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>240</td>
                <td>714</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>557</td>
                <td>395</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">23.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>181</td>
                <td>78</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>228</td>
                <td>693</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>537</td>
                <td>390</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">24.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>179</td>
                <td>77</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>227</td>
                <td>663</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>528</td>
                <td>387</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">25.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>180</td>
                <td>77</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>241</td>
                <td>673</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>540</td>
                <td>389</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">26.03.2022</td>
                <td>Open Banking API</td>
                <td>82.00%</td>
                <td>18.00%</td>
                <td>89</td>
                <td>173</td>
                <td>65</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>82.00%</td>
                <td>18.00%</td>
                <td>208</td>
                <td>735</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>82.00%</td>
                <td>18.00%</td>
                <td>375</td>
                <td>298</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">27.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>93</td>
                <td>176</td>
                <td>68</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>165</td>
                <td>491</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>388</td>
                <td>316</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">28.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>113</td>
                <td>187</td>
                <td>78</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>288</td>
                <td>817</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>555</td>
                <td>415</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">29.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>103</td>
                <td>180</td>
                <td>74</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>234</td>
                <td>678</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>508</td>
                <td>392</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">30.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>104</td>
                <td>178</td>
                <td>75</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>243</td>
                <td>695</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>516</td>
                <td>384</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">31.03.2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>102</td>
                <td>179</td>
                <td>83</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>259</td>
                <td>739</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>523</td>
                <td>398</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

