<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
            <tr>
                <th>Date</th>
                <th>Channel</th>
                <th>Uptime, <br /> %</th>
                <th>Downtime, <br /> %</th>
                <th>AIS <br /> performance, <br /> ms</th>
                <th>PIS <br /> performance, <br /> ms</th>
                <th>CoF <br /> performance, <br /> ms</th>
                <th>Error response <br /> rate, <br /> %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td rowspan="3" class="text-nowrap">10/1/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>135</td>
                <td>253</td>
                <td>104</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>189</td>
                <td>471</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>708</td>
                <td>467</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/2/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>119</td>
                <td>240</td>
                <td>83</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>224</td>
                <td>291</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>492</td>
                <td>368</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/3/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>116</td>
                <td>249</td>
                <td>83</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>487</td>
                <td>382</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/4/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>263</td>
                <td>106</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>120</td>
                <td>577</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>746</td>
                <td>484</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/5/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>264</td>
                <td>109</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>216</td>
                <td>506</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>706</td>
                <td>448</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/6/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>255</td>
                <td>102</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>206</td>
                <td>482</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>680</td>
                <td>450</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/7/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>139</td>
                <td>260</td>
                <td>109</td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>190</td>
                <td>551</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>681</td>
                <td>460</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/8/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>151</td>
                <td>289</td>
                <td>117</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>262</td>
                <td>566</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>701</td>
                <td>480</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/9/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>126</td>
                <td>245</td>
                <td>94</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>139</td>
                <td>297</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>472</td>
                <td>358</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/10/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>249</td>
                <td>95</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>180</td>
                <td>369</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>494</td>
                <td>373</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/11/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>167</td>
                <td>299</td>
                <td>128</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>294</td>
                <td>646</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>786</td>
                <td>496</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/12/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>151</td>
                <td>287</td>
                <td>123</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>232</td>
                <td>522</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>680</td>
                <td>451</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/13/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>268</td>
                <td>105</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>207</td>
                <td>452</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>670</td>
                <td>442</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/14/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>134</td>
                <td>264</td>
                <td>100</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>196</td>
                <td>441</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>675</td>
                <td>439</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/15/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>260</td>
                <td>105</td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>208</td>
                <td>485</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>686</td>
                <td>466</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/16/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>241</td>
                <td>85</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>272</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>453</td>
                <td>332</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/17/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>119</td>
                <td>249</td>
                <td>85</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>134</td>
                <td>291</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>472</td>
                <td>345</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/18/2022</td>
                <td>Open Banking API</td>
                <td>92.00%</td>
                <td>8.00%</td>
                <td>151</td>
                <td>297</td>
                <td>107</td>
                <td>1.387%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>92.00%</td>
                <td>8.00%</td>
                <td>252</td>
                <td>1169</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>706</td>
                <td>463</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/19/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>270</td>
                <td>100</td>
                <td>0.014%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>206</td>
                <td>463</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>660</td>
                <td>426</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/20/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>124</td>
                <td>250</td>
                <td>95</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>198</td>
                <td>480</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>660</td>
                <td>429</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/21/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>132</td>
                <td>251</td>
                <td>102</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>194</td>
                <td>440</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>648</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/22/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>257</td>
                <td>107</td>
                <td>0.110%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>196</td>
                <td>442</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>671</td>
                <td>460</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/23/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>103</td>
                <td>220</td>
                <td>78</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>118</td>
                <td>265</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>454</td>
                <td>339</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/24/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>115</td>
                <td>232</td>
                <td>83</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>278</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>461</td>
                <td>338</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/25/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>255</td>
                <td>288</td>
                <td>135</td>
                <td>0.016%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>229</td>
                <td>542</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>704</td>
                <td>464</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/26/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1163</td>
                <td>2410</td>
                <td>1127</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>186</td>
                <td>412</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>648</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/27/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>117</td>
                <td>252</td>
                <td>94</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>189</td>
                <td>431</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>658</td>
                <td>425</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/28/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>125</td>
                <td>250</td>
                <td>94</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>190</td>
                <td>428</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>659</td>
                <td>436</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/29/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>268</td>
                <td>96</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>204</td>
                <td>460</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>678</td>
                <td>442</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/30/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>245</td>
                <td>77</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>120</td>
                <td>261</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>469</td>
                <td>345</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">10/31/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>250</td>
                <td>79</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>295</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>499</td>
                <td>360</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/1/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>117</td>
                <td>255</td>
                <td>84</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>170</td>
                <td>404</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>640</td>
                <td>553</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/2/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>115</td>
                <td>250</td>
                <td>85</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>160</td>
                <td>371</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>682</td>
                <td>587</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/3/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>124</td>
                <td>242</td>
                <td>92</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>219</td>
                <td>500</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1099</td>
                <td>801</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/4/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>139</td>
                <td>280</td>
                <td>107</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>240</td>
                <td>862</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>922</td>
                <td>693</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/5/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>134</td>
                <td>276</td>
                <td>105</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>224</td>
                <td>838</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>924</td>
                <td>733</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/6/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>242</td>
                <td>80</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>125</td>
                <td>546</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>532</td>
                <td>499</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/7/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>107</td>
                <td>252</td>
                <td>79</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>569</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>562</td>
                <td>520</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/8/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>158</td>
                <td>296</td>
                <td>112</td>
                <td>0.079%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>259</td>
                <td>981</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1031</td>
                <td>816</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/9/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>314</td>
                <td>118</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>232</td>
                <td>930</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>911</td>
                <td>696</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/10/2022</td>
                <td>Open Banking API</td>
                <td>93.00%</td>
                <td>7.00%</td>
                <td>444</td>
                <td>1098</td>
                <td>323</td>
                <td>0.036%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>488</td>
                <td>1361</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>939</td>
                <td>743</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/11/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>264</td>
                <td>91</td>
                <td>0.019%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>208</td>
                <td>792</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>895</td>
                <td>687</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/12/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>132</td>
                <td>273</td>
                <td>97</td>
                <td>0.046%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>205</td>
                <td>805</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>910</td>
                <td>716</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/13/2022</td>
                <td>Open Banking API</td>
                <td>85.00%</td>
                <td>15.00%</td>
                <td>109</td>
                <td>259</td>
                <td>78</td>
                <td>0.014%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>85.00%</td>
                <td>15.00%</td>
                <td>130</td>
                <td>590</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>85.00%</td>
                <td>15.00%</td>
                <td>497</td>
                <td>430</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/14/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>135</td>
                <td>280</td>
                <td>80</td>
                <td>0.063%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>625</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>566</td>
                <td>503</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/15/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>206</td>
                <td>375</td>
                <td>111</td>
                <td>0.260%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>279</td>
                <td>1047</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1081</td>
                <td>803</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/16/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>282</td>
                <td>89</td>
                <td>0.038%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>208</td>
                <td>771</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>919</td>
                <td>676</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/17/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>242</td>
                <td>88</td>
                <td>0.018%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>203</td>
                <td>709</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>910</td>
                <td>678</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/18/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>127</td>
                <td>246</td>
                <td>89</td>
                <td>0.064%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>184</td>
                <td>669</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>903</td>
                <td>700</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/19/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>306</td>
                <td>90</td>
                <td>0.024%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>191</td>
                <td>703</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>870</td>
                <td>698</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/20/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>241</td>
                <td>75</td>
                <td>0.010%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>129</td>
                <td>489</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>512</td>
                <td>483</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/21/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>123</td>
                <td>252</td>
                <td>84</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>166</td>
                <td>534</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>523</td>
                <td>450</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/22/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>279</td>
                <td>105</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>273</td>
                <td>922</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1184</td>
                <td>774</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/23/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>135</td>
                <td>260</td>
                <td>91</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>226</td>
                <td>734</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>902</td>
                <td>664</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/24/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>131</td>
                <td>262</td>
                <td>88</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>202</td>
                <td>711</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>861</td>
                <td>645</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/25/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>131</td>
                <td>263</td>
                <td>88</td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>206</td>
                <td>717</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>895</td>
                <td>675</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/26/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>135</td>
                <td>282</td>
                <td>99</td>
                <td>0.020%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>203</td>
                <td>715</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>878</td>
                <td>670</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/27/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>107</td>
                <td>231</td>
                <td>73</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>499</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>528</td>
                <td>476</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/28/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>117</td>
                <td>244</td>
                <td>78</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>171</td>
                <td>556</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>617</td>
                <td>558</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/29/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>265</td>
                <td>97</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>239</td>
                <td>847</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1045</td>
                <td>772</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">11/30/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>251</td>
                <td>101</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>216</td>
                <td>752</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1028</td>
                <td>772</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/1/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>248</td>
                <td>93</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>214</td>
                <td>770</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>896</td>
                <td>554</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/2/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>466</td>
                <td>105</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>201</td>
                <td>723</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>789</td>
                <td>495</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/3/2022</td>
                <td>Open Banking API</td>
                <td>97.29%</td>
                <td>2.71%</td>
                <td>119</td>
                <td>335</td>
                <td>90</td>
                <td>0.115%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>235</td>
                <td>730</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>726</td>
                <td>514</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/4/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>101</td>
                <td>220</td>
                <td>72</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>460</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>522</td>
                <td>400</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/5/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>245</td>
                <td>75</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>159</td>
                <td>507</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>589</td>
                <td>455</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/6/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>288</td>
                <td>103</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>251</td>
                <td>883</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>876</td>
                <td>616</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/7/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>278</td>
                <td>96</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>214</td>
                <td>759</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>773</td>
                <td>501</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/8/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>276</td>
                <td>98</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>219</td>
                <td>761</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>732</td>
                <td>499</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/9/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>281</td>
                <td>100</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>224</td>
                <td>769</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>700</td>
                <td>453</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/10/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>277</td>
                <td>105</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>248</td>
                <td>837</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>734</td>
                <td>487</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/11/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>236</td>
                <td>76</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>495</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>517</td>
                <td>389</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/12/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>245</td>
                <td>81</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>537</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>565</td>
                <td>422</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/13/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>278</td>
                <td>105</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>261</td>
                <td>896</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>846</td>
                <td>565</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/14/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>329</td>
                <td>482</td>
                <td>408</td>
                <td>0.205%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>307</td>
                <td>1276</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>762</td>
                <td>459</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/15/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>141</td>
                <td>248</td>
                <td>97</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>244</td>
                <td>912</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>715</td>
                <td>460</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/16/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>280</td>
                <td>92</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>251</td>
                <td>815</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>697</td>
                <td>439</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/17/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>142</td>
                <td>328</td>
                <td>94</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>209</td>
                <td>966</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>687</td>
                <td>436</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/18/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>115</td>
                <td>295</td>
                <td>75</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>727</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>499</td>
                <td>338</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/19/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>120</td>
                <td>306</td>
                <td>78</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>164</td>
                <td>773</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>543</td>
                <td>384</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/20/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>339</td>
                <td>105</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>267</td>
                <td>1156</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>791</td>
                <td>518</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/21/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>139</td>
                <td>314</td>
                <td>91</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>212</td>
                <td>955</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>709</td>
                <td>457</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/22/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>323</td>
                <td>94</td>
                <td>0.016%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>224</td>
                <td>997</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>715</td>
                <td>471</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/23/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>330</td>
                <td>91</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>216</td>
                <td>992</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>662</td>
                <td>436</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/24/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>289</td>
                <td>72</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>120</td>
                <td>695</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>473</td>
                <td>352</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/25/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>106</td>
                <td>299</td>
                <td>66</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>658</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>481</td>
                <td>351</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/26/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>310</td>
                <td>72</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>142</td>
                <td>714</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>520</td>
                <td>388</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/27/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>150</td>
                <td>338</td>
                <td>98</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>250</td>
                <td>1084</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>789</td>
                <td>520</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/28/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>321</td>
                <td>91</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>212</td>
                <td>952</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>709</td>
                <td>451</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/29/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>326</td>
                <td>94</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>212</td>
                <td>948</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>724</td>
                <td>491</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/30/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>341</td>
                <td>103</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>237</td>
                <td>1039</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>755</td>
                <td>518</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">12/31/2022</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>318</td>
                <td>93</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>202</td>
                <td>964</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>674</td>
                <td>448</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

