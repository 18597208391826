import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {HelperService} from "../utils/helperService";

@Injectable()
export class TtpRegistrationService {

constructor(
  private http: HttpClient,
  private helperService: HelperService) {
}
  post(path: string, options): Observable<any> {
    return this.http.post(
      `${path}`,
       options, { responseType: 'text' }
    );
  }

  register(form, aRoles) {
    const formData = new FormData();
    formData.append('tppName', form.get('tppName').value);
    formData.append('orgReferenceNumber', form.get('orgReferenceNumber').value);
    formData.append('firstName', form.get('firstName').value);
    formData.append('lastName', form.get('lastName').value);
    formData.append('email', form.get('email').value);
    formData.append('phoneNumber', form.get('phoneNumber').value);
    formData.append('accountRoles[]', JSON.stringify(aRoles));
    formData.append('originCountry', this.helperService.getCountryCode(form.get('originCountry').value));
    formData.append('username', form.get('username').value);
    formData.append('password', form.get('password').value);
    return this.post('https://business.blackcatcard.com/tpp/index.php', formData )
  }
}
