<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">Terms & Conditions</h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <ul class="group-list">
            <li class="group-list__item">
              <p><a href="#">Terms & Conditions – Papaya eWallet</a></p>
            </li>
            <li class="group-list__item">
              <p><a href="#">Terms & Conditions – Papaya Prepaid Card</a></p>
            </li>
            <li class="group-list__item">
              <p><a href="#">Terms & Conditions – Papaya Corporate Solution</a></p>
            </li>
          </ul>
        </section>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
