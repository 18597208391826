import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  message : any[];
  mailText:string = "";
  mailForm: FormGroup;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    this.mailForm = new FormGroup({
      name: new FormControl(
        null,[]
      ),
      email: new FormControl(
        null,[]
      ),
      message: new FormControl(
        null,[
          Validators.required
        ]
      )
    });
  }

  openEmail({ value, valid }) {
    Object.keys(this.mailForm.controls).forEach(field => {
      const control = this.mailForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (!valid) {
      return;
    }
    this.message = [this.mailForm.get('name').value, this.mailForm.get('email').value, this.mailForm.get('message').value];
    this.mailText = "mailto:info@papaya.eu?&body="+this.message.join(" %0D%0A");
    window.location.href = this.mailText;
  }

}
