import { Component, OnInit } from '@angular/core';
import { TimelineMax, TweenMax } from 'gsap';
import * as ScrollMagic from 'scrollmagic';
import { AnimationService } from '../animation.service'

const controller = new ScrollMagic.Controller();

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  prev;

  constructor(private animationService: AnimationService) { }

  ngOnInit(): void {

    if (window.innerWidth > 767) {
      this.tweenPartners();
    }
  }

  tweenPartners() {
    const tl = new TimelineMax();
    this.animationService.animatePartners(tl);

    new ScrollMagic.Scene({
      triggerElement: '.partners__row',
      triggerHook: 'onEnter',
    })
      .setTween(tl)
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: '.partners__row',
      triggerHook: 'onEnter',
    })
      .setTween(tl)
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: '.partners__row',
      triggerHook:'onEnter',
    })
      .setTween(tl)
      .addTo(controller);
  }
}
