<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
          <tr>
            <th>Date</th>
            <th>Channel</th>
            <th>Uptime, <br/> %</th>
            <th>Downtime, <br/> %</th>
            <th>AIS <br/> performance, <br/> ms</th>
            <th>PIS <br/> performance, <br/> ms</th>
            <th>CoF <br/> performance, <br/> ms</th>
            <th>Error response <br/> rate, <br/> %</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td rowspan="3" class="text-nowrap">1/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>75</td>
            <td>70</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>353</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>415</td>
            <td>328</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>72</td>
            <td>78</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>210</td>
            <td>610</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>616</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>69</td>
            <td>72</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>529</td>
            <td>362</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>75</td>
            <td>73</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>176</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>632</td>
            <td>366</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>78</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>537</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>519</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>81</td>
            <td>74</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>505</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>377</td>
            <td>383</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>108</td>
            <td>64</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>347</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>186</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>77</td>
            <td>69</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>413</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>80</td>
            <td>78</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>637</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>565</td>
            <td>397</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>80</td>
            <td>80</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>223</td>
            <td>571</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>544</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>83</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>490</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>496</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>53</td>
            <td>75</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>176</td>
            <td>478</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>492</td>
            <td>343</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>54</td>
            <td>76</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>186</td>
            <td>515</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>505</td>
            <td>357</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>52</td>
            <td>65</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>339</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>352</td>
            <td>284</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>61</td>
            <td>70</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>399</td>
            <td>322</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>70</td>
            <td>81</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>246</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>610</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>70</td>
            <td>76</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>516</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>524</td>
            <td>355</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>72</td>
            <td>74</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>519</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>518</td>
            <td>353</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>76</td>
            <td>73</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>498</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>511</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>78</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>522</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/21/2023</td>
            <td>Open Banking API</td>
            <td>99.44%</td>
            <td>0.56%</td>
            <td>111</td>
            <td>86</td>
            <td>66</td>
            <td>0.039%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.88%</td>
            <td>0.12%</td>
            <td>154</td>
            <td>367</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>346</td>
            <td>274</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>57</td>
            <td>72</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>344</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>410</td>
            <td>326</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>162</td>
            <td>211</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>256</td>
            <td>592</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.09%</td>
            <td>0.91%</td>
            <td>620</td>
            <td>426</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>53</td>
            <td>72</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>477</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>538</td>
            <td>353</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>63</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>349</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>61</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>188</td>
            <td>474</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>516</td>
            <td>351</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>97</td>
            <td>90</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>489</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.76%</td>
            <td>0.24%</td>
            <td>525</td>
            <td>358</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>62</td>
            <td>67</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>372</td>
            <td>293</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>72</td>
            <td>72</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>362</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>405</td>
            <td>327</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>314</td>
            <td>75</td>
            <td>81</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>242</td>
            <td>609</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>587</td>
            <td>398</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>76</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>572</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>559</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>48</td>
            <td>77</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>187</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>728</td>
            <td>530</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>51</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>524</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>703</td>
            <td>527</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>57</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>522</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>662</td>
            <td>536</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>52</td>
            <td>66</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>335</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>395</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>64</td>
            <td>73</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>359</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>476</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>69</td>
            <td>82</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>242</td>
            <td>621</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>740</td>
            <td>588</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>67</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>504</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>676</td>
            <td>545</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>70</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>518</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>677</td>
            <td>541</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>71</td>
            <td>77</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>542</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>697</td>
            <td>555</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>376</td>
            <td>553</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1033</td>
            <td>1174</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>706</td>
            <td>595</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>71</td>
            <td>66</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>330</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>407</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>48</td>
            <td>70</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>471</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>450</td>
            <td>402</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>55</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>630</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>750</td>
            <td>578</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>59</td>
            <td>77</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>221</td>
            <td>551</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>710</td>
            <td>545</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>68</td>
            <td>80</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>205</td>
            <td>556</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>709</td>
            <td>570</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>103</td>
            <td>72</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>443</td>
            <td>406</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>94</td>
            <td>79</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>511</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>670</td>
            <td>532</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>79</td>
            <td>69</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>378</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>441</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>85</td>
            <td>70</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>457</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>125</td>
            <td>87</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>238</td>
            <td>655</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>779</td>
            <td>604</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>124</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>512</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>673</td>
            <td>514</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>102</td>
            <td>82</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>703</td>
            <td>534</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>64</td>
            <td>77</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>673</td>
            <td>512</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>72</td>
            <td>78</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>174</td>
            <td>486</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>687</td>
            <td>526</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>66</td>
            <td>68</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>342</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>407</td>
            <td>364</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>99</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>368</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>453</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>115</td>
            <td>114</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>236</td>
            <td>660</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>775</td>
            <td>591</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>67</td>
            <td>84</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>221</td>
            <td>562</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>772</td>
            <td>579</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>74</td>
            <td>83</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>217</td>
            <td>550</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>626</td>
            <td>363</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>77</td>
            <td>83</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>544</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>591</td>
            <td>356</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>81</td>
            <td>82</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>218</td>
            <td>533</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>366</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>75</td>
            <td>44</td>
            <td>69</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>314</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>392</td>
            <td>261</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>53</td>
            <td>74</td>
            <td>0.033%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>331</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>443</td>
            <td>300</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/6/2023</td>
            <td>Open Banking API</td>
            <td>99.97%</td>
            <td>0.03%</td>
            <td>100</td>
            <td>68</td>
            <td>92</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.97%</td>
            <td>0.03%</td>
            <td>252</td>
            <td>708</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>631</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>57</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>191</td>
            <td>465</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>557</td>
            <td>333</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>59</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>200</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>61</td>
            <td>80</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>513</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>587</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>66</td>
            <td>81</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>578</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>615</td>
            <td>374</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>61</td>
            <td>68</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>312</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>423</td>
            <td>283</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>72</td>
            <td>73</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>325</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>84</td>
            <td>84</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>254</td>
            <td>655</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>669</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>90</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>548</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>591</td>
            <td>341</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>87</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>704</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>585</td>
            <td>339</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>101</td>
            <td>85</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>693</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>590</td>
            <td>346</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>67</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>184</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>571</td>
            <td>332</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/18/2023</td>
            <td>Open Banking API</td>
            <td>79.55%</td>
            <td>20.45%</td>
            <td>84</td>
            <td>58</td>
            <td>67</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>79.55%</td>
            <td>20.45%</td>
            <td>143</td>
            <td>358</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>79.27%</td>
            <td>20.73%</td>
            <td>423</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>62</td>
            <td>67</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>137</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>435</td>
            <td>272</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>81</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>233</td>
            <td>629</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>619</td>
            <td>352</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>74</td>
            <td>75</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>170</td>
            <td>508</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>572</td>
            <td>322</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>77</td>
            <td>74</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>472</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>569</td>
            <td>323</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>77</td>
            <td>71</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>456</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>569</td>
            <td>320</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>85</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>542</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>72</td>
            <td>47</td>
            <td>64</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>312</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>384</td>
            <td>244</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>54</td>
            <td>66</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>421</td>
            <td>262</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>66</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>2786</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>763</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>68</td>
            <td>79</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>583</td>
            <td>313</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>193</td>
            <td>287</td>
            <td>121</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>765</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>572</td>
            <td>310</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>59</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>398</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>563</td>
            <td>313</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>63</td>
            <td>76</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>571</td>
            <td>324</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

